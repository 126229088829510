import React, { useState, useEffect } from 'react'
import {Link} from "react-router-dom"
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import UserInfoForQuoteService from './UserInfoForQuoteService';
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q");
Geocode.enableDebug();

const CelebrationVenue = ['Restaurant venue', 'Event venue', 'Not sure', 'Other']
const NeedOption = [
    'Make a Large Order',
    'Book An Event',
    'Make a reservation'
]
const SeatingOption = ['Standard', 'Bar', 'Outdoor', 'special room', 'Not sure', 'Other'];

const TypeOfEvent = ['Wedding', 'Birthday', 'Shower', 'Party', 'Anniversary', 'Get - together', 'Other'];
const Cuisine = ['American cuisine', 'Mexican cuisine', 'Italian cuisine', 'Africa cuisine', 'Chinese cuisine', 'Other']
const DrinkOption = ['Soft drinks', 'Fruit drinks', 'Coffee', 'Tea', 'Water', 'Non alcoholic drinks', 'Alcoholic drinks', 'Other']
const GuestNumber = ['2-4 guest', '5 - 9 guest', '10 - 20 guest', '21 - 30 guest', '31- 40 guest', '41- 50 guest', 'Not sure', 'Other']
const Spending = ['Under $15 per person ( typically basic food casual event)',
    '$15 - $25  per person ( typically basic food casual event)',
    '$26-$39  per person ( typically basic food casual event)',
    '$40-$59  per person( typically basic food casual event)',
    '$60 - $99  per person ( typically basic food casual event)',
    'Not sure',
    'Other'
];
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];


const RestaurantAndEvent = (props) => {
    //main contracting service 
    const [optionForNeed, setOptionForNeed] = useState('');
    const [seatingOption, setSeatingOption] = useState('');
    const [otherSeatingOption, setOtherSeatingOption] = useState(false);
    const [eventType, setEventType] = useState('');
    const [otherEventType, setOtherEventType] = useState(false);
    const [bringFoodStatus, setBringFoodStatus] = useState('')
    const [cuisineType, setCuisineType] = useState('');
    const [otherCuisineType, setOtherCuisineType] = useState(false);
    const [appetizer, setAppetizer] = useState('');
    const [entree, setEntree] = useState('');
    const [sideItem, setSideItem] = useState('');
    const [dessert, setDessert] = useState('');
    const [drinkType, setDrinkType] = useState('');
    const [otherDrinkType, setOtherDrinkType] = useState(false);
    const [deliveryOrPickup, setDeliveryOrPickup] = useState('')
    const [guestNo, setGuestNo] = useState('');
    const [otherGuestNo, setOtherGuestNo] = useState(false);
    const [decorationStatus, setDecorationStatus] = useState('');
    const [spendPerPerson, setSpendPerPerson] = useState('');
    const [otherSpendPerPerson, setOtherSpendPerPerson] = useState(false);
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [appointDate, setAppointDate] = useState(new Date());
    const [startingTime, setStartingTime] = useState('');
    const [closingTime, setClosingTime] = useState('');
    const [startingReservationTime, setStartingReservationTime] = useState('');
    const [eventVenueLocation, setEventVenueLocation] = useState('');
    const [otherInfo, setOtherInfo] = useState('');
    const [serviceData, setServiceData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    console.log(props.businessDetails, "props.businessDetails");
    useEffect(() => {
        //  scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);


    const handleSeatingOption = (value) => {
        if (value === 'Other') {
            setOtherSeatingOption(true);
            setSeatingOption('')
        } else {
            setOtherSeatingOption(false);
            setSeatingOption(value)
        }
    }

    const handleEventType = (value) => {
        if (value === 'Other') {
            setOtherEventType(true);
            setEventType('')
        } else {
            setOtherEventType(false);
            setEventType(value)
        }
    }

    const handleCuisineType = (value) => {
        if (value === 'Other') {
            setOtherCuisineType(true);
            setCuisineType('')
        } else {
            setOtherCuisineType(false);
            setCuisineType(value)
        }
    }

    const handleDrinkType = (value) => {
        if (value === 'Other') {
            setOtherDrinkType(true);
            setDrinkType('')
        } else {
            setOtherDrinkType(false);
            setDrinkType(value)
        }
    }
    const handleGuestNumber = (value) => {
        if (value === 'Other') {
            setOtherGuestNo(true);
            setGuestNo('')
        } else {
            setOtherGuestNo(false);
            setGuestNo(value)
        }
    }
    const handleSpending = (value) => {
        if (value === 'Other') {
            setOtherSpendPerPerson(true);
            setSpendPerPerson('')
        } else {
            setOtherSpendPerPerson(false);
            setSpendPerPerson(value)
        }
    }
    const handleContactMode = (value) => {
        if (value === 'Other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }

    const handleTimeChange = (event, type) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        if (type === 'start') {
            setStartingTime(selectedTime)
        } else if (type === 'close') {
            setClosingTime(selectedTime)
        } else {
            setStartingReservationTime(selectedTime)
        }
    }

    const handleLocationSelection = (place, type) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
            method: 'GET'
        }).then((response) => response.json())
            .then((data) => {
                if (data.results[1]) {
                    const address = data.results[0].formatted_address
                    setEventVenueLocation(address)
                }
            })
            .catch((error) => {
                toast.error(error, {
                    autoClose: 700,
                    hideProgressBar: true,
                })
            })
    };
    //  submit
    const handleSubmit = (e) => {
        e.preventDefault()
        if (optionForNeed && eventType && guestNo && contactMode && appointDate) {
            let UsDate = new Date(appointDate).toLocaleDateString("en-US")
            let quoteDetails = {
                "Requested Service": "Restaurant & Event Venue",
                // "Where do you want to celebrate your party?": venueForCelebration,
                "What type of service do you need?": optionForNeed,
                "What type of event are you planning?": eventType,
            }
            if (optionForNeed === 'Make a Large Order') {
                if (deliveryOrPickup &&
                    spendPerPerson && eventVenueLocation && startingTime && closingTime) {
                    // quoteDetails['Do you have appetizer(s) in mind?'] = appetizer
                    // quoteDetails['Do you have entree (s) in mind?'] = entree
                    // quoteDetails['Do you have side item(s) in mind?'] = sideItem
                    // quoteDetails['Do you have desert(s) in mind?'] = dessert
                    // quoteDetails['What type of drink(s) do you have in mind?'] = drinkType
                    quoteDetails['Delivery or pick up?'] = deliveryOrPickup
                    quoteDetails['How much are you open to spending per person?'] = spendPerPerson
                    quoteDetails['Where is the event venue location?'] = eventVenueLocation;
                    quoteDetails['starting time'] = startingTime;
                    quoteDetails['closing time'] = closingTime;
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                    return
                }
            } else if (optionForNeed === 'Book An Event') {
                if (seatingOption && bringFoodStatus && decorationStatus && eventVenueLocation && startingTime && closingTime) {
                    if (bringFoodStatus === 'No' && spendPerPerson === '') {
                        toast.warning('Please Select Spend per person', {
                            autoClose: 700,
                            hideProgressBar: true,
                        })
                        return
                    }
                    if (bringFoodStatus === 'No' && spendPerPerson) {
                        quoteDetails['How much are you open to spending per person?'] = spendPerPerson
                    }
                    quoteDetails['What is the seating option available?'] = seatingOption;
                    quoteDetails['Are you bringing your own food?'] = bringFoodStatus
                    quoteDetails['Do you plan to decorate?'] = decorationStatus
                    quoteDetails['Where is the event venue location?'] = eventVenueLocation;
                    quoteDetails['starting time'] = startingTime;
                    quoteDetails['closing time'] = closingTime;
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                    return
                }
            } else if (optionForNeed === 'Make a reservation') {
                if (seatingOption && spendPerPerson && startingReservationTime) {
                    quoteDetails['What is the seating option available?'] = seatingOption;
                    quoteDetails['How much are you open to spending per person?'] = spendPerPerson
                    quoteDetails['Reservation time'] = startingReservationTime;
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                    return
                }


            }
            quoteDetails['Numbers of guest attending'] = guestNo;
            quoteDetails['What is the best way to contact you?'] = contactMode;
            quoteDetails['when do you need the service?'] = UsDate;

            if (otherInfo) {
                quoteDetails["Any other details"] = otherInfo
            }

            let data = {
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            setServiceData(data)
            setIsSubmit(true)
        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }


    return (
        <>
            {
                !isSubmit ?
                    (<div id="restaurant_event" className='card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
                        <Helmet>
                            <title>Request Quote For Restaurant & Event</title>
                        </Helmet>
                        <ToastContainer />
                        {/* <div className="section-label fs-3 mb-4">{props.businessDetails.subCategoryName === "LOCAL RESTAURANT" ?  "Bakery & Caffe": "Restaurant & Event Venue"}</div> */}
                        <div className="section-label fs-3 mb-4">Restaurant & Event Venue</div>
                        
                        <div className="mb-3">
                            <label htmlFor="chooseNeed" className="form-label fs-6 mb-2">What type of service do you need?</label>
                            <div className="d-flex flex-wrap g-2">
                                {NeedOption.map((option) => (
                                    <div className="form-check me-3" key={option}>
                                        <input className="form-check-input" type="radio" name="radioGroupForOptionNeed" id={option} required
                                            onClick={() => setOptionForNeed(option)} />
                                        <label className="form-check-label" htmlFor={option}>{option}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {
                            (optionForNeed === 'Book An Event' || optionForNeed === 'Make a reservation') ?
                                <div className="mb-3">
                                    <label htmlFor="chooseseatingOption" className="form-label fs-6 mb-2">What is the seating option available?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        {SeatingOption.map((seatingOption) => (
                                            <div className="form-check me-3" key={seatingOption}>
                                                <input className="form-check-input" type="radio" name="radioGroupForEventseatingOption" id={seatingOption} required
                                                    onClick={() => handleSeatingOption(seatingOption)} />
                                                <label className="form-check-label" htmlFor={seatingOption}>{seatingOption}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {
                                        otherSeatingOption ?
                                            <input type="text" className="form-control mt-2" id="OtherForseatingOption" placeholder='Other event location...' required
                                                onChange={(e) => setSeatingOption(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                : ''
                        }

                        <div className="mb-3">
                            <label htmlFor="chooseEventType" className="form-label fs-6 mb-2">What type of event are you planning?</label>
                            <div className="d-flex flex-wrap g-2">
                                {TypeOfEvent.map((value) => (
                                    <div className="form-check me-3" key={value}>
                                        <input className="form-check-input" type="radio" name="radioGroupForEventType" id={value} required
                                            onClick={() => handleEventType(value)} />
                                        <label className="form-check-label" htmlFor={value}>{value}</label>
                                    </div>
                                ))}
                            </div>
                            {
                                otherEventType ?
                                    <input type="text" className="form-control mt-2" id="OtherForEventType" placeholder='Other event type...' required
                                        onChange={(e) => setEventType(e.target.value)} />
                                    : ''
                            }
                        </div>
                        {
                            optionForNeed === 'Book An Event' ?
                                <div className="mb-3">
                                    <label htmlFor="chooseBringingFood" className="form-label fs-6 mb-2">Are you bringing your own food?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForBringingFood" id='bringingFoodYes' required
                                                onClick={() => setBringFoodStatus('Yes')} />
                                            <label className="form-check-label" htmlFor='bringingFoodYes'>Yes</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForBringingFood" id='bringingFoodNo' required
                                                onClick={() => setBringFoodStatus('No')} />
                                            <label className="form-check-label" htmlFor='bringingFoodNo'>No</label>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }

                        {/* {
                            optionForNeed === 'Let the event planner provides both venue and food for the event.' ?
                                <div className="mb-3">
                                    <label htmlFor="chooseCuisine" className="form-label fs-6 mb-2">What type of cuisine do you need?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        {Cuisine.map((value) => (
                                            <div className="form-check me-3" key={value}>
                                                <input className="form-check-input" type="radio" name="radioGroupForCuisine" id={value} required
                                                    onClick={() => handleCuisineType(value)} />
                                                <label className="form-check-label" htmlFor={value}>{value}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {
                                        otherCuisineType ?
                                            <input type="text" className="form-control mt-2" id="OtherForCuisineType" placeholder='Other cuisine type...' required
                                                onChange={(e) => setCuisineType(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                : ''
                        } */}

                        {
                            optionForNeed === 'Make a Large Order' ?
                                <div>
                                    {/* <div className="mb-2">
                                        <label htmlFor="chooseAppetizer" className="form-label fs-6 mb-1">Do you have appetizer's in mind?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            <input type="text" className="form-control mt-2" id="AppetizerType" placeholder='appetizer in mind...' required
                                                value={appetizer}
                                                onChange={(e) => setAppetizer(e.target.value)} />
                                            <div className="form-check mt-2" >
                                                <input className="form-check-input" type="radio" name="radioGroupForAppetizer" id='appetizer' required
                                                    checked={appetizer === 'Not sure'}
                                                    onClick={() => setAppetizer('Not sure')} />
                                                <label className="form-check-label" htmlFor='appetizer'>Not sure</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="chooseEntree" className="form-label fs-6 mb-1">Do you have entree's in mind?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            <input type="text" className="form-control mt-2" id="EntreeType" placeholder='entree in mind...' required
                                                value={entree}
                                                onChange={(e) => setEntree(e.target.value)} />
                                            <div className="form-check mt-2" >
                                                <input className="form-check-input" type="radio" name="radioGroupForEntree" id='Entree' required
                                                    checked={entree === 'Not sure'}
                                                    onClick={() => setEntree('Not sure')} />
                                                <label className="form-check-label" htmlFor='Entree'>Not sure</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="chooseSideItem" className="form-label fs-6 mb-1">Do you have side item's in mind?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            <input type="text" className="form-control mt-2" id="sideItem" placeholder='side item in mind...' required
                                                value={sideItem}
                                                onChange={(e) => setSideItem(e.target.value)} />
                                            <div className="form-check mt-2" >
                                                <input className="form-check-input" type="radio" name="radioGroupForsideItem" id='sideItem' required
                                                    checked={sideItem === 'Not sure'}
                                                    onClick={() => setSideItem('Not sure')} />
                                                <label className="form-check-label" htmlFor='sideItem'>Not sure</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="chooseDesert" className="form-label fs-6 mb-1">Do you have desert's in mind?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            <input type="text" className="form-control mt-2" id="dessert" placeholder='desert in mind...' required
                                                value={dessert}
                                                onChange={(e) => setDessert(e.target.value)} />
                                            <div className="form-check mt-2" >
                                                <input className="form-check-input" type="radio" name="radioGroupFordessert" id='dessert' required
                                                    checked={dessert === 'Not sure'}
                                                    onClick={() => setDessert('Not sure')} />
                                                <label className="form-check-label" htmlFor='dessert'>Not sure</label>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="mb-3">
                                        <label htmlFor="chooseDrinkType" className="form-label fs-6 mb-2">What type of drink's do you have in mind?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {DrinkOption.map((drink) => (
                                                <div className="form-check me-3" key={drink}>
                                                    <input className="form-check-input" type="radio" name="radioGroupForDrinkType" id={drink} required
                                                        onClick={() => handleDrinkType(drink)} />
                                                    <label className="form-check-label" htmlFor={drink}>{drink}</label>
                                                </div>
                                            ))}
                                        </div>
                                        {
                                            otherDrinkType ?
                                                <input type="text" className="form-control mt-2" id="OtherForDrinkType" placeholder='Other drink type...' required
                                                    onChange={(e) => setDrinkType(e.target.value)} />
                                                : ''
                                        }
                                    </div> */}
                                </div> : ''}

                        {
                            optionForNeed === 'Make a Large Order' ?
                                <div className="mb-3">
                                    <label htmlFor="chooseDeliveryPickup" className="form-label fs-6 mb-2">Delivery or pick up?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForDeliveryPickup" id='delivery' required
                                                onClick={() => setDeliveryOrPickup('Delivery')} />
                                            <label className="form-check-label" htmlFor='delivery'>Delivery</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForDeliveryPickup" id='pickup' required
                                                onClick={() => setDeliveryOrPickup('Pick up')} />
                                            <label className="form-check-label" htmlFor='pickup'>Pick up</label>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }
                        <div className="mb-3">
                            <label htmlFor="chooseGuest" className="form-label fs-6 mb-2">Numbers of guest attending</label>
                            <div className="d-flex flex-wrap g-2">
                                {GuestNumber.map((guest) => (
                                    <div className="form-check me-3" key={guest}>
                                        <input className="form-check-input" type="radio" name="radioGroupForGuest" id={guest} required
                                            onClick={() => handleGuestNumber(guest)} />
                                        <label className="form-check-label" htmlFor={guest}>{guest}</label>
                                    </div>
                                ))}
                            </div>
                            {
                                otherGuestNo ?
                                    <input type="text" className="form-control mt-2" id="OtherForGuest" placeholder='Other guest number...' required
                                        onChange={(e) => setGuestNo(e.target.value)} />
                                    : ''
                            }
                        </div>
                        {
                            optionForNeed === 'Book An Event' ?
                                <div className="mb-3">
                                    <label htmlFor="chooseDecoration" className="form-label fs-6 mb-2"> Do you plan to decorate?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForDecoration" id='decoYes' required
                                                onClick={() => setDecorationStatus('Yes')} />
                                            <label className="form-check-label" htmlFor='decoYes'>Yes</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForDecoration" id='decoNo' required
                                                onClick={() => setDecorationStatus('No')} />
                                            <label className="form-check-label" htmlFor='decoNo'>No</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForDecoration" id='decoNotsure' required
                                                onClick={() => setDecorationStatus('Not sure')} />
                                            <label className="form-check-label" htmlFor='decoNotsure'>Not sure</label>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }



                        {
                            optionForNeed === 'Make a Large Order' || optionForNeed === 'Make a reservation' || (optionForNeed === 'Book An Event' && bringFoodStatus === 'No') ?
                                <div className="mb-3">
                                    <label htmlFor="chooseSpending" className="form-label fs-6 mb-2">How much are you open to spending per person?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        {Spending.map((spend) => (
                                            <div className="form-check me-3" key={spend}>
                                                <input className="form-check-input" type="radio" name="radioGroupForSpending" id={spend} required
                                                    onClick={() => handleSpending(spend)} />
                                                <label className="form-check-label" htmlFor={spend}>{spend}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {
                                        otherSpendPerPerson ?
                                            <input type="text" className="form-control mt-2" id="OtherForSpending" placeholder='Other spending per person...' required
                                                onChange={(e) => setSpendPerPerson(e.target.value)} />
                                            : ''
                                    }
                                </div> : ''}
                        <div className="mb-3">
                            <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    ContactWay.map((cntct) => (
                                        <div className="form-check me-3" key={cntct}>
                                            <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                                                onClick={() => handleContactMode(cntct)} />
                                            <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherContactMode ?
                                    <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                                        onChange={(e) => setContactMode(e.target.value)} />
                                    : ''
                            }
                        </div>
                        {
                            (optionForNeed === 'Make a Large Order' ||
                                optionForNeed === 'Book An Event') ?
                                <div className="mb-3">
                                    <label htmlFor="eventVenueLocation" className="form-label fs-6 mb-2">Where is the event venue location?</label>
                                    <Autocomplete
                                        className="form-control"
                                        placeholder='Please enter event venue location...'
                                        onPlaceSelected={(e) => handleLocationSelection(e)}
                                        options={{
                                            fields: ["formatted_address", "geometry", "name"],
                                            strictBounds: false,
                                            types: [],
                                        }}
                                    />
                                </div>
                                : ''
                        }
                        <div className="mb-3">
                            <label htmlFor="functionDate" className="form-label mb-2">When do you need the service?</label>
                            <div className='date-picker-wrapper'>
                                <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                                    onChange={(value) => setAppointDate(value)} minDate={new Date()}
                                />
                            </div>
                        </div>

                        {(optionForNeed === 'Make a Large Order' ||
                            optionForNeed === 'Book An Event') ?
                            <div className='row'>
                                <div className="col-6 mb-3">
                                    <label htmlFor="startingTime" className="form-label fs-6 mb-2">Select starting time</label>
                                    <input type="time" id="startingTime" className="form-control" min="09:00" max="18:00" required
                                        onChange={(e) => handleTimeChange(e, 'start')} />
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="closingTime" className="form-label fs-6 mb-2">Select Closing time</label>
                                    <input type="time" id="closingTime" className="form-control" min="09:00" max="18:00" required
                                        onChange={(e) => handleTimeChange(e, 'close')} />
                                </div>
                            </div>
                            : ''
                        }
                        {optionForNeed === 'Make a reservation' ?
                            <div className='row'>
                                <div className="col-6 mb-3">
                                    <label htmlFor="startingTime" className="form-label fs-6 mb-2">Select reservation time</label>
                                    <input type="time" id="startingTime" className="form-control" min="09:00" max="18:00" required
                                        onChange={(e) => handleTimeChange(e, 'reservation')} />
                                </div>
                            </div>
                            : ''
                        }
                        <div className="mb-4">
                            <label htmlFor="otherInfo" className="form-label mb-2">Any other information</label>
                            <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                                onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                        </div>

                        <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com </div>
                        <div className="btn-wrapper mt-2">
                            <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Next</button>
                        </div>
                        {
                            props.loading ?
                                <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                                    style={{ zIndex: 1030 }}>
                                    <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                                </div>
                                : ''
                        }
                    </div >)
                    :
                    (<UserInfoForQuoteService state={serviceData} />)
            }
        </>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(RestaurantAndEvent))