import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startPlanAction = () => {
  return {
    type: actionTypes.START_PLAN_ACTION
  };
};

export const planActionFail = message => {
  return {
    type: actionTypes.PLAN_ACTION_FAIL,
    message
  };
};

export const fetchPlanSuccess = (planList, message = '') => {
  return {
    type: actionTypes.FETCH_PLAN_LIST_SUCCESS,
    listing: planList,
    message: message ? message : ''
  };
};

export const getPlanList = () => {
  return dispatch => {
    dispatch(startPlanAction());
    axios
      .get("/couponranking/get-plan-list", {})
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPlanSuccess(response.data.data));
          } else {
            dispatch(planActionFail(response.data.message));
          }
        } else {
          dispatch(planActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(planActionFail(err.message));
      });
  };
};

export const fetchPlanDetailOfUserSuccess = (details, message = '') => {
  return {
    type: actionTypes.FETCH_PLAN_DETAILS_SUCCESS,
    details: details,
    message: message ? message : ''
  };
};

export const getPlanDetailOfUser = () => {
  return dispatch => {
    dispatch(startPlanAction());
    let authtoken = "Bearer " + localStorage.getItem("token");
    axios
      .get("/couponranking/get-plan-details", {
        headers: { Authorization: authtoken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPlanDetailOfUserSuccess(response.data.data));
          } else {
            dispatch(planActionFail(response.data.message));
          }
        } else {
          dispatch(planActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(planActionFail(err.message));
      });
  };
};

export const subscribePlanByUser = (data, navigate) => {
  console.log(data)
  return dispatch => {
    dispatch(startPlanAction());
    let authtoken = "Bearer " + data.token;
    axios
      .post("/couponranking/subscribe-plan", {planId:data.planId}, {
        headers: { Authorization: authtoken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchPlanSuccess(response.data.data, response.data.message));
            const load = () => { navigate('/add-business-details') }
            setTimeout(load, 2000);
          } else {
            dispatch(planActionFail(response.data.message));
          }
        } else {
          dispatch(planActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(planActionFail(err.message));
      });
  };
};

