import React from 'react'
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

const WhatWeDo = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);
    return (
        <div className="whoweare_page py-3 py-md-4">
            {/* title head */}
            <Helmet>
                <title>What we do | Couponranking</title>
            </Helmet>
            <div className="container">
                <div className='bg-light p-3 p-md-4'>
                    <div className="section-heading fs-4 fw-bold mb-3">What we do</div>
                    <p>
                        Couponranking aimed at enhancing the connection between customers and businesses.Through their provided tools—such as request a quote, book appointment, coupons, communication, and deals—they facilitate a more efficient, beneficial interaction for both parties.Let's delve into how each tool could potentially benefit users and businesses alike:
                    </p>
                    <p className='fw-semibold'>1.Request a Quote:</p>
                    <p>This tool enables users to ask for pricing or project estimates directly from businesses.It's particularly useful for services that are customized or vary significantly in scope, allowing customers to make informed decisions based on their budget and the specifics of what they need.</p>
                    <p className='fw-semibold'>2.Book Appointment:</p>
                    <p>An appointment booking feature simplifies the process of scheduling meetings, services, or consultations.It saves time for both customers and businesses by automating the scheduling process, reducing phone calls, and minimizing the risk of double bookings or scheduling conflicts.</p>
                    <p className='fw-semibold'>3.Coupons:</p>
                    <p>Offering coupons through this platform can help businesses attract new customers and retain existing ones by providing discounts or special offers.For customers, it means saving money on products or services they need, making it a win - win for both parties.</p>
                    <p className='fw-semibold'>4.Communication:</p>
                    <p>A dedicated communication tool allows for direct interaction between customers and businesses.This can improve customer service, allow for quick resolution of inquiries or issues, and help build stronger relationships between the business and its clients.</p>
                    <p className='fw-semibold'>5.Deals:</p>
                    <p>Similar to coupons, deals are special offers made available to users of the platform.These might include bundle offers, seasonal discounts, or limited - time promotions.Deals can drive sales and increase exposure for businesses, while customers benefit from cost savings and discovering new products or services.</p>

                    <p>
                        Couponranking has created a comprehensive ecosystem where customers can easily find, evaluate, and engage with businesses.For businesses, it offers a way to streamline operations, reach potential customers, and boost sales through targeted offers and improved service offerings.The company serves various industries, including retail, professional services, hospitality, and more, making it a versatile tool for enhancing business - customer interactions in the digital age.
                    </p>

                </div>
            </div>

        </div>
    )
}

export default WhatWeDo;














