import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import UserInfoForQuoteService from './UserInfoForQuoteService';
import Autocomplete from 'react-google-autocomplete';

const CateringServices = (props) => {
    const [occasion, setOccasion] = useState('');
    const [otherOcassion, setOtherOccasion] = useState(false);
    const [mealCatered, setMealCatered] = useState('');
    const [drinkAndDesert, setDrinkAndDesert] = useState([]);
    const [cuisine, setCuisine] = useState([]);
    const [appetizer, setAppetizer] = useState('');
    const [appetizerCheck, setAppetizerCheck] = useState(false);
    const [entree, setEntree] = useState('');
    const [entreeCheck, setEntreeCheck] = useState(false);
    const [desert, setDesert] = useState('');
    const [desertCheck, setDesertCheck] = useState(false);
    const [mealServerd, setMealServerd] = useState('');
    const [mealServerdOther, setMealServerdOther] = useState(false);
    const [serverStatus, setServerStatus] = useState('');
    const [kitchenStatus, setKitchenStatus] = useState('');
    const [supplyStatus, setSupplyStatus] = useState('');
    const [venue, setVenue] = useState('');
    const [expectedGuest, setExpectedGuest] = useState('');
    const [otherExpextedGuest, setOtherExpextedGuest] = useState(false);
    const [supply, setSupply] = useState([]);
    const [quality, setQuality] = useState('');
    const [otherQuality, setOtherQuality] = useState(false);
    const [spendingPerPerson, setSpendingPerPerson] = useState('')
    const [otherSpending, setOtherSpending] = useState(false);
    const [appointDate, setAppointDate] = useState(new Date());
    const [eventVenueLocation, setEventVenueLocation] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipcode] = useState('');
    const [contactMode, setContactMode] = useState('');
    const [foodReadyTime, setFoodReadyTime] = useState('');;
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [otherInfo, setOtherInfo] = useState('');
    const [serviceData, setServiceData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    useEffect(() => {
        //  scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    const handleOccasion = (value) => {
        if (value === 'Other') {
            setOtherOccasion(true)
        } else {
            setOccasion(value)
            setOtherOccasion(false)
        }
    }
    const handleDrinkAndDesert = (value) => {
        let updatedDrinkAndDesert = [...drinkAndDesert]
        if (drinkAndDesert.includes(value)) {
            let valueIndex = updatedDrinkAndDesert.indexOf(value)
            updatedDrinkAndDesert.splice(valueIndex, 1)
            setDrinkAndDesert(updatedDrinkAndDesert)
        } else {
            updatedDrinkAndDesert.push(value)
            setDrinkAndDesert(updatedDrinkAndDesert)
        }
    }
    const handleCuisine = (value) => {
        let updatedCuisine = [...cuisine]
        if (cuisine.includes(value)) {
            let valueIndex = updatedCuisine.indexOf(value)
            updatedCuisine.splice(valueIndex, 1)
            setCuisine(updatedCuisine)
        } else {
            updatedCuisine.push(value)
            setCuisine(updatedCuisine)
        }
    }

    const handleAppetizeCheck = (type, value) => {
        if (type === 'check') {
            if (appetizerCheck) {
                setAppetizerCheck(false)
                setAppetizer('')
            } else {
                setAppetizer('Not Sure')
                setAppetizerCheck(true)
            }
        } else {
            setAppetizer(value)
            setAppetizerCheck(false)
        }
    }
    const handleEntreeCheck = (type, value) => {
        if (type === 'check') {
            if (entreeCheck) {
                setEntreeCheck(false)
                setEntree('')
            } else {
                setEntree('Not Sure')
                setEntreeCheck(true)
            }
        } else {
            setEntree(value)
            setEntreeCheck(false)
        }
    }
    const handleDesertCheck = (type, value) => {
        if (type === 'check') {
            if (desertCheck) {
                setDesertCheck(false)
                setDesert('')
            } else {
                setDesert('Not Sure')
                setDesertCheck(true)
            }
        } else {
            setDesert(value)
            setDesertCheck(false)
        }
    }
    const handleMealServerd = (value) => {
        if (value === 'other') {
            setMealServerdOther(true)
        } else {
            setMealServerd(value)
            setMealServerdOther(false)
        }
    }
    const handleExpectedGuest = (value) => {
        if (value === 'other') {
            setOtherExpextedGuest(true)
        } else {
            setExpectedGuest(value)
            setOtherExpextedGuest(false)
        }
    }
    const handleSupply = (value) => {
        let updatedSupply = [...supply]
        if (cuisine.includes(value)) {
            let valueIndex = updatedSupply.indexOf(value)
            updatedSupply.splice(valueIndex, 1)
            setSupply(updatedSupply)
        } else {
            updatedSupply.push(value)
            setSupply(updatedSupply)
        }
    }

    const handleQuality = (value) => {
        if (value === 'other') {
            setOtherQuality(true)
        } else {
            setQuality(value)
            setOtherQuality(false)
        }
    }
    const handleContactMode = (value) => {
        if (value === 'other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }
    const handleSpendingPerPerson = (value) => {
        if (value === 'other') {
            setOtherSpending(true)
        } else {
            setSpendingPerPerson(value)
            setOtherSpending(false)
        }
    }
    //for pickup time in am/pm format
    const handleTimeChange = (event) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        setFoodReadyTime(selectedTime)
    }
    const handleDateChange = (value) => {
        setAppointDate(value)
    }
    const handleLocationSelection = (place, type) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
            method: 'GET'
        }).then((response) => response.json())
            .then((data) => {
                if (data.results[1]) {
                    const address = data.results[0].formatted_address
                    setEventVenueLocation(address)
                }
            })
            .catch((error) => {
                toast.error(error, {
                    autoClose: 700,
                    hideProgressBar: true,
                })
            })
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (occasion && mealCatered && cuisine.length > 0 && mealServerd && serverStatus && kitchenStatus && supplyStatus && venue && expectedGuest
            && supply.length > 0 && quality && spendingPerPerson && appointDate
            && contactMode) {
            // change date formate in USA format
            const date = new Date(appointDate);
            let UsDate = date.toLocaleDateString("en-US")
            let quoteDetails = {
                "Requested Service": "Catering",
                "What is the occasion": occasion,
                "Which meals would you like catered?": mealCatered,
                // "What would you like drink or dessert?": drinkAndDesert,
                "What type's of cuisine would you like?": cuisine,
                // "Do you have appetizer(s) in mind?": appetizer,
                // "Do you have entree(s) in mind?": entree,
                // "Do you have desert(s) in mind?": desert,
                "How would you like the meal served?": mealServerd,
                "Would you need a servers": serverStatus,
                "Will there be a kitchen on site?": kitchenStatus,
                "Whould you need any supplies?": supplyStatus,
                "What is the venue?": venue,
                "How many guest are you expectig?": expectedGuest,
                "Will you need any of these supplies?": supply,
                "Which level of service would you like?": quality,
                "How much are you open to spending per person?": spendingPerPerson,
                "What is the best way to contact you?": contactMode,
                "Which day do you need it?": UsDate,
                "What time do you want the food ready?": foodReadyTime,
                // "City": city,
                // "Country": country,
                // "Zipcode": zipCode,
                "Any other information": otherInfo
            }
            // let mobileNo = customerInfo.country_code + customerInfo.mobile_no
            // let customerName = customerInfo.name;
            // let customerEmail = customerInfo.email;
            let data = {
                // mobileNo: mobileNo,
                // customerName: customerName,
                // customerEmail: customerEmail,
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            // props.clickPerCount(data, toast,navigate)
            setServiceData(data);
            setIsSubmit(true)

        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }
    return (
        <>
            {!isSubmit ? (
                <div id="cateringService" className='catering-service-sec card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
                    <ToastContainer />
                    <div className="section-label fs-3 mb-4">Catering Service</div>
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">What is the occasion</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOccasion" id="occWedding"
                                    onClick={() => handleOccasion('Wedding')} />
                                <label className="form-check-label" htmlFor="occWedding">Wedding</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOccasion" id="occBirthday" required
                                    onClick={() => handleOccasion('Birthday')} />
                                <label className="form-check-label" htmlFor="occBirthday">Birthday</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOccasion" id="occParties"
                                    onClick={() => handleOccasion('Anniversary Parties')} />
                                <label className="form-check-label" htmlFor="occParties">Anniversary Parties</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOccasion" id="dinParties"
                                    onClick={() => handleOccasion('Dinner Parties')} />
                                <label className="form-check-label" htmlFor="dinParties">Dinner Parties</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOccasion" id="cEvent"
                                    onClick={() => handleOccasion('Corporate event')} />
                                <label className="form-check-label" htmlFor="cEvent">Corporate event</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOccasion" id="bShower"
                                    onClick={() => handleOccasion('Bridal shower')} />
                                <label className="form-check-label" htmlFor="bShower">Bridal shower</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOccasion" id="babyNewbaby"
                                    onClick={() => handleOccasion('BabyShower')} />
                                <label className="form-check-label" htmlFor="babyNewbaby">BabyShower</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOccasion" id="gParty"
                                    onClick={() => handleOccasion('Graduation party')} />
                                <label className="form-check-label" htmlFor="gParty">Graduation party</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOccasion" id="outEvent"
                                    onClick={() => handleOccasion('Outdoor event')} />
                                <label className="form-check-label" htmlFor="outEvent">Outdoor event</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOccasion" id="occOther"
                                    onClick={() => handleOccasion('Other')} />
                                <label className="form-check-label" htmlFor="occOther">Other</label>
                            </div>
                        </div>
                        {
                            otherOcassion ?
                                <input type="text" id="chooseOccasion" className="form-control mt-2" placeholder='Enter your occasion...' required
                                    onChange={(e) => setOccasion(e.target.value)} />
                                : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mealsCatered" className="form-label fs-6 mb-2">Which meals would you like catered?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCatered" id="breakfast" required
                                    onClick={() => setMealCatered('Breakfast')} />
                                <label className="form-check-label" htmlFor="breakfast">Breakfast</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCatered" id="brunch" required
                                    onClick={() => setMealCatered('Brunch')} />
                                <label className="form-check-label" htmlFor="brunch">Brunch</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCatered" id="lunch" required
                                    onClick={() => setMealCatered('Lunch')} />
                                <label className="form-check-label" htmlFor="lunch">Lunch</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCatered" id="cocktail" required
                                    onClick={() => setMealCatered('Cocktail')} />
                                <label className="form-check-label" htmlFor="cocktail">Cocktail</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCatered" id="hors" required
                                    onClick={() => setMealCatered('Hors d’oeuvres')} />
                                <label className="form-check-label" htmlFor="hors">Hors d’oeuvres</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCatered" id="dinner" required
                                    onClick={() => setMealCatered('Dinner')} />
                                <label className="form-check-label" htmlFor="dinner">Dinner</label>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mb-3">
                        <label htmlFor="chooseDrink" className="form-label fs-6 mb-2">What would you like drink or dessert?</label>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForDrink" id="dessert"
                                    onChange={() => handleDrinkAndDesert('Dessert')} />
                                <label className="form-check-label" htmlFor="dessert">Dessert</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForDrink" id="NonAlcoholicDrink"
                                    onChange={() => handleDrinkAndDesert('Non alcoholic drink')} />
                                <label className="form-check-label" htmlFor="NonAlcoholicDrink">Non alcoholic drink</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForDrink" id="alcoholicDrink"
                                    onChange={() => handleDrinkAndDesert('Alcoholic Drink')} />
                                <label className="form-check-label" htmlFor="alcoholicDrink">Alcoholic Drink</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForDrink" id="coffee"
                                    onChange={() => handleDrinkAndDesert('Coffee')} />
                                <label className="form-check-label" htmlFor="coffee">Coffee</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForDrink" id="tea"
                                    onChange={() => handleDrinkAndDesert('Tea')} />
                                <label className="form-check-label" htmlFor="tea">Tea</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForDrink" id="water" required
                                    onChange={() => handleDrinkAndDesert('Bottle Water')} />
                                <label className="form-check-label" htmlFor="water">Bottle Water</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForDrink" id="softDrink" required
                                    onChange={() => handleDrinkAndDesert('Soft drink')} />
                                <label className="form-check-label" htmlFor="softDrink">Soft drink</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForDrink" id="juice" required
                                    onChange={() => handleDrinkAndDesert('Juice')} />
                                <label className="form-check-label" htmlFor="juice">Juice</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForDrink" id="notsureDrinks"
                                    onChange={() => handleDrinkAndDesert('Not Sure')} />
                                <label className="form-check-label" htmlFor="notsureDrinks">Not Sure</label>
                            </div>
                        </div>
                    </div> */}
                    <div className="mb-3">
                        <label htmlFor="cuisineType" className="form-label fs-6 mb-2">What type's of cuisine would you like?</label>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForCusins" id="americaCasual" required
                                    onChange={() => handleCuisine('American casual')} />
                                <label className="form-check-label" htmlFor="americaCasual">American casual</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForCusins" id="americanFormal"
                                    onChange={() => handleCuisine('American formal')} />
                                <label className="form-check-label" htmlFor="americanFormal">American formal</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForCusins" id="Italian"
                                    onChange={() => handleCuisine('Italian')} />
                                <label className="form-check-label" htmlFor="Italian">Italian</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForCusins" id="barbecue"
                                    onChange={() => handleCuisine('Barbecue')} />
                                <label className="form-check-label" htmlFor="barbecue">Barbecue</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForCusins" id="maxican"
                                    onChange={() => handleCuisine('Mexican or Latin American')} />
                                <label className="form-check-label" htmlFor="maxican">Mexican or Latin American</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForCusins" id="americanSouthern"
                                    onChange={() => handleCuisine('American southern')} />
                                <label className="form-check-label" htmlFor="americanSouthern">American southern</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForCusins" id="asian"
                                    onChange={() => handleCuisine('Asian')} />
                                <label className="form-check-label" htmlFor="asian">Asian</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForCusins" id="cuisineNotSure"
                                    onChange={() => handleCuisine('Not sure')} />
                                <label className="form-check-label" htmlFor="cuisineNotSure">Not sure</label>
                            </div>
                        </div>
                        {/* <input type="text" className="form-control mt-2" id="cuisineType" placeholder='Enter cuisine type...' required /> */}
                    </div>
                    {/* <div className="mb-2">
                        <label htmlFor="addVenue" className="form-label fs-6 mb-2">Do you have appetizer(s) in mind?</label>
                        <input type="text" id="addVenue" className="form-control" placeholder='List the appetizers (s) desired' required
                            value={appetizer}
                            onChange={(e) => handleAppetizeCheck('text', e.target.value)} />
                        <div className="form-check me-3 mt-2">
                            <input className="form-check-input" type="radio" name="radioForAppetizer" id="appetizer"
                                checked={appetizerCheck}
                                onChange={() => handleAppetizeCheck('check')} />
                            <label className="form-check-label" htmlFor="appetizer">Not sure</label>
                        </div>
                    </div> */}
                    {/* <div className="mb-2">
                        <label htmlFor="addVenue" className="form-label fs-6 mb-2">Do you have entree(s) in mind?</label>
                        <input type="text" id="addentree" className="form-control" placeholder='list the entree(s) desired' required
                            value={entree}
                            onChange={(e) => handleEntreeCheck('text', e.target.value)} />
                        <div className="form-check me-3 mt-2">
                            <input className="form-check-input" type="radio" name="radioForentree" id="entree"
                                checked={entreeCheck}
                                onChange={() => handleEntreeCheck('check')} />
                            <label className="form-check-label" htmlFor="entree">Not sure</label>
                        </div>
                    </div> */}
                    {/* <div className="mb-2">
                        <label htmlFor="addVenue" className="form-label fs-6 mb-2">Do you have desert(s) in mind?</label>
                        <input type="text" id="addDesert" className="form-control" placeholder='list the desert(s) desired' required
                            value={desert}
                            onChange={(e) => handleDesertCheck('text', e.target.value)}
                        />
                        <div className="form-check me-3 mt-2">
                            <input className="form-check-input" type="radio" name="radioForDesert" id="desert"
                                checked={desertCheck}
                                onChange={() => handleDesertCheck('check')} />
                            <label className="form-check-label" htmlFor="desert">Not sure</label>
                        </div>
                    </div> */}
                    <div className="mb-3">
                        <label htmlFor="mealServed" className="form-label fs-6 mb-2">How would you like the meal served?</label>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForMealServed" id="platedMeal" required
                                    onClick={() => handleMealServerd('Plated Meal')} />
                                <label className="form-check-label" htmlFor="platedMeal">Plated Meal</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForMealServed" id="Buffet"
                                    onClick={() => handleMealServerd('Buffet')} />
                                <label className="form-check-label" htmlFor="Buffet">Buffet</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForMealServed" id="foodStations"
                                    onClick={() => handleMealServerd('Food Stations')} />
                                <label className="form-check-label" htmlFor="foodStations">Food Stations</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForMealServed" id="familyStyle"
                                    onClick={() => handleMealServerd('Family Style')} />
                                <label className="form-check-label" htmlFor="familyStyle">Family Style</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForMealServed" id="smallBites"
                                    onClick={() => handleMealServerd('Small Bites')} />
                                <label className="form-check-label" htmlFor="smallBites">Small Bites</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForMealServed" id="passedAppetizers"
                                    onClick={() => handleMealServerd('Passed Appetizers')} />
                                <label className="form-check-label" htmlFor="passedAppetizers">Passed Appetizers</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForMealServed" id="mealOther"
                                    onClick={(e) => handleMealServerd('other')} />
                                <label className="form-check-label" htmlFor="mealOther">Other</label>
                            </div>
                        </div>
                        {
                            mealServerdOther ?
                                <input type="text" className="form-control mt-2" id="mealServed" placeholder='Enter meal served type...' required
                                    onChange={(e) => setMealServerd(e.target.value)} />
                                : ''
                        }

                    </div>
                    <div className="d-flex flex-wrap align-items-center mb-3">
                        <label className="form-label fs-6 me-3">Would you need a servers</label>
                        <span className="d-flex flex-wrap">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="radioGroupForServer" id="serverYes" required
                                    onClick={() => setServerStatus('Yes, Full Service')} />
                                <label className="form-check-label" htmlFor="serverYes">Yes, Full Service</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="radioGroupForServer" id="serverNo"
                                    onClick={() => setServerStatus('No, Drop Off only')} />
                                <label className="form-check-label" htmlFor="serverNo">No, Drop Off only</label>
                            </div>
                        </span>
                    </div>
                    <div className="d-flex flex-wrap align-items-center mb-3">
                        <label className="form-label fs-6 me-3">Will there be a kitchen on site?</label>
                        <span className="d-flex flex-wrap">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="radioGroupForKitchen" id="kitchenYes" required
                                    onClick={() => setKitchenStatus('Yes, Full Kitchen')} />
                                <label className="form-check-label" htmlFor="kitchenYes">Yes, Full Kitchen</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="radioGroupForKitchen" id="partialkitchenYes" required
                                    onClick={() => setKitchenStatus('Yes,Partial kitchen(fridge and sink only)')} />
                                <label className="form-check-label" htmlFor="partialkitchenYes">Yes,Partial kitchen(fridge and sink only)</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="radioGroupForKitchen" id="kitchenNo"
                                    onClick={() => setKitchenStatus('No Kitchen')} />
                                <label className="form-check-label" htmlFor="kitchenNo">No Kitchen</label>
                            </div>
                        </span>
                    </div>
                    <div className="d-flex flex-wrap align-items-center mb-3">
                        <label className="form-label fs-6 me-3">Whould you need any supplies?</label>
                        <span className="d-flex flex-wrap">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="radioGroup" id="suppiesYes" required
                                    onClick={() => setSupplyStatus('Yes')} />
                                <label className="form-check-label" htmlFor="suppiesYes">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="radioGroup" id="suppiesNo"
                                    onClick={() => setSupplyStatus('No')} />
                                <label className="form-check-label" htmlFor="suppiesNo">No</label>
                            </div>
                        </span>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="addVenue" className="form-label fs-6 mb-2">What is the venue?</label>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVenue" id="banquetHall" required
                                    onClick={() => setVenue('Banquet hall')} />
                                <label className="form-check-label" htmlFor="banquetHall">Banquet hall</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVenue" id="ballroom"
                                    onClick={() => setVenue('Ballroom')} />
                                <label className="form-check-label" htmlFor="ballroom">Ballroom</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVenue" id="conferenceCenter"
                                    onClick={() => setVenue('Conference center')} />
                                <label className="form-check-label" htmlFor="conferenceCenter">Conference center</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVenue" id="conventionCenter"
                                    onClick={() => setVenue('Convention center')} />
                                <label className="form-check-label" htmlFor="conventionCenter">Convention center</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVenue" id="concertHall"
                                    onClick={() => setVenue('Concert hall')} />
                                <label className="form-check-label" htmlFor="concertHall">Concert hall</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVenue" id="outdoorVenue"
                                    onClick={() => setVenue('Outdoor venue')} />
                                <label className="form-check-label" htmlFor="outdoorVenue">Outdoor venue</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVenue" id="office"
                                    onClick={() => setVenue('Office')} />
                                <label className="form-check-label" htmlFor="office">Office</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVenue" id="school"
                                    onClick={() => setVenue('School')} />
                                <label className="form-check-label" htmlFor="school">School</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVenue" id="home"
                                    onClick={() => setVenue('Home')} />
                                <label className="form-check-label" htmlFor="home">Home</label>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="totalGuest" className="form-label mb-2">How many guest are you expectig?</label>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForGuest" id="fewerthan29" required
                                    onClick={() => handleExpectedGuest('Fewer than 29 guests')} />
                                <label className="form-check-label" htmlFor="fewerthan29">Fewer than 29 guests</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForGuest" id="20-29"
                                    onClick={() => handleExpectedGuest('20 - 29 guests')} />
                                <label className="form-check-label" htmlFor="20-29">20 - 29 guests</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForGuest" id="30-39"
                                    onClick={() => handleExpectedGuest('30 -39 guests')} />
                                <label className="form-check-label" htmlFor="30-39">30 - 39 guests</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForGuest" id="40-49"
                                    onClick={() => handleExpectedGuest('40 - 49 guests')} />
                                <label className="form-check-label" htmlFor="40-49">40 - 49 guests</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForGuest" id="50-59"
                                    onClick={() => handleExpectedGuest('50-59 guests')} />
                                <label className="form-check-label" htmlFor="50-59">50 - 59 guests</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForGuest" id="60-69"
                                    onClick={() => handleExpectedGuest('60-69 guests')} />
                                <label className="form-check-label" htmlFor="60-69">60 - 69 guests</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForGuest" id="70-79"
                                    onClick={() => handleExpectedGuest('70 - 79 guests')} />
                                <label className="form-check-label" htmlFor="70-79">70 - 79 guests</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForGuest" id="notSure"
                                    onClick={() => handleExpectedGuest('Not Sure')} />
                                <label className="form-check-label" htmlFor="notSure">Not sure</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForGuest" id="other"
                                    onClick={() => handleExpectedGuest('other')} />
                                <label className="form-check-label" htmlFor="other">Other</label>
                            </div>
                        </div>
                        {otherExpextedGuest ?
                            <input type="text" className="form-control mt-2" id="chooseExpectedGuest" placeholder='Enter expected guest...' required
                                onChange={(e) => setExpectedGuest(e.target.value)} />
                            : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="supplyType" className="form-label fs-6 mb-2">Will you need any of these supplies?</label>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForSupply" id="puag" required
                                    onChange={() => handleSupply('Plates, Utensils and Glasses')} />
                                <label className="form-check-label" htmlFor="puag">Plates, Utensils and Glasses</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForSupply" id="dpuac"
                                    onChange={() => handleSupply('Disposable plates, Utensils and Cups')} />
                                <label className="form-check-label" htmlFor="dpuac">Disposable plates, Utensils and Cups</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForSupply" id="paperNapkin"
                                    onChange={() => handleSupply('Paper napkins')} />
                                <label className="form-check-label" htmlFor="paperNapkin">Paper napkins</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForSupply" id="clothNapkin"
                                    onChange={() => handleSupply('Cloth napkins')} />
                                <label className="form-check-label" htmlFor="clothNapkin">Cloth napkins</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForSupply" id="sawEqipment"
                                    onChange={() => handleSupply('Serving and Weddings equipment')} />
                                <label className="form-check-label" htmlFor="sawEqipment">Serving and Weddings equipment</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForSupply" id="tableDecor"
                                    onChange={() => handleSupply('Table decor')} />
                                <label className="form-check-label" htmlFor="tableDecor">Table decor</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForSupply" id="supplyNotSure"
                                    onChange={() => handleSupply('Not sure')} />
                                <label className="form-check-label" htmlFor="supplyNotSure">Not sure</label>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="serviceQuality" className="form-label mb-2">Which level of service would you like?</label>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForQuality" id="standardQuality" required
                                    onClick={() => handleQuality('Standard (basic ingredients)')} />
                                <label className="form-check-label" htmlFor="standardQuality">Standard (basic ingredients)</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForQuality" id="premiumQuality"
                                    onClick={() => handleQuality('Premium (high end ingredients)')} />
                                <label className="form-check-label" htmlFor="premiumQuality">Premium (high end ingredients)</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForQuality" id="notSureQuality"
                                    onClick={() => handleQuality('Not Sure')} />
                                <label className="form-check-label" htmlFor="notSureQuality">Not Sure</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForQuality" id="otherQuality"
                                    onClick={() => handleQuality('other')} />
                                <label className="form-check-label" htmlFor="otherQuality">Other</label>
                            </div>
                        </div>
                        {otherQuality ?
                            <input type="text" className="form-control mt-2" id="chooseExpectedGuest" placeholder='Enter expected guest...' required
                                onChange={(e) => setQuality(e.target.value)} />
                            : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="totalGuest" className="form-label mb-2">How much are you open to spending per person?</label>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForSpending" id="moneySpend1" required
                                    onClick={() => handleSpendingPerPerson('Under $15 (typically basic food for casual events)')} />
                                <label className="form-check-label" htmlFor="moneySpend1">Under $15 (typically basic food for casual events)</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForSpending" id="moneySpend2"
                                    onClick={() => handleSpendingPerPerson('$15 - $25 ( typically basic food for casual events)')} />
                                <label className="form-check-label" htmlFor="moneySpend2">$15 - $25 ( typically basic food for casual events)</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForSpending" id="moneySpend3"
                                    onClick={() => handleSpendingPerPerson('$25 -$35 (typically basic food for casual events)')} />
                                <label className="form-check-label" htmlFor="moneySpend3">$25 -$35 (typically basic food for casual events)</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForSpending" id="moneySpend4"
                                    onClick={() => handleSpendingPerPerson('moneySpend4 guests')} />
                                <label className="form-check-label" htmlFor="30-39">$35 -$50</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForSpending" id="moneySpend5"
                                    onClick={() => handleSpendingPerPerson('$50 -$75 (wedding average)')} />
                                <label className="form-check-label" htmlFor="moneySpend5">$50 -$75 (wedding average)</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForSpending" id="moneySpend6"
                                    onClick={() => handleSpendingPerPerson('$75-$100 (wedding average)')} />
                                <label className="form-check-label" htmlFor="moneySpend6">$75-$100 (wedding average)</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForSpending" id="moneySpendnotSure"
                                    onClick={() => handleSpendingPerPerson('Not Sure')} />
                                <label className="form-check-label" htmlFor="moneySpendnotSure">Not sure</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForSpending" id="moneySpendOther"
                                    onClick={() => handleSpendingPerPerson('other')} />
                                <label className="form-check-label" htmlFor="moneySpendOther">Other</label>
                            </div>
                        </div>
                        {otherSpending ?
                            <input type="text" className="form-control mt-2" id="choosemoneySpend" placeholder='Enter spending per person in $...' required
                                onChange={(e) => setSpendingPerPerson(e.target.value)} />
                            : ''
                        }
                    </div>

                    <div className="mb-3">
                        <label htmlFor="choosePassenger" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_call" required
                                    onClick={() => handleContactMode('Phone call')} />
                                <label className="form-check-label" htmlFor="contact_call">Phone call</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupContact" id="video_call"
                                    onClick={() => handleContactMode('Video call')} />
                                <label className="form-check-label" htmlFor="video_call">Video Call</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_email"
                                    onClick={() => handleContactMode('Email')} />
                                <label className="form-check-label" htmlFor="contact_email">Email</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_text"
                                    onClick={() => handleContactMode('Text Message')} />
                                <label className="form-check-label" htmlFor="contact_text">Text Message</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupContact" id="chat"
                                    onClick={() => handleContactMode('Chat')} />
                                <label className="form-check-label" htmlFor="chat">Chat</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_other"
                                    onClick={() => handleContactMode('other')} />
                                <label className="form-check-label" htmlFor="contact_other">Other</label>
                            </div>
                        </div>
                        {
                            otherContactMode ?
                                <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                                    onChange={(e) => setContactMode(e.target.value)} />
                                : ''
                        }
                    </div>

                    <div className="mb-3">
                        <label htmlFor="functionDate" className="form-label mb-2">Which day do you need it?</label>
                        <div className='date-picker-wrapper'>
                            <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                                onChange={(value) => handleDateChange(value)} minDate={new Date()}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="pickupTime" className="form-label fs-6 mb-2">What time do you want the food ready?</label>
                        <input type="time" id="pickupTime" className="form-control" min="09:00" max="18:00" required
                            onChange={(e) => handleTimeChange(e)} />
                    </div>
                    {/* <div className="mb-3">
                        <label htmlFor="functionDate" className="form-label mb-2">Please confirm where you need the catering service</label>
                        <div className="d-flex flex-column flex-md-row gap-2">
                            <input type="text" className="form-control" id="city" placeholder='Enter your city' required
                                onChange={(e) => setCity(e.target.value)} />
                            <input type="text" className="form-control" id="country" placeholder='Enter your country' required
                                onChange={(e) => setCountry(e.target.value)} />
                            <input type="text" className="form-control" id="zip" placeholder='zip code' required style={{ maxWidth: '150px' }}
                                onChange={(e) => setZipcode(e.target.value)} />
                        </div>
                    </div> */}
                    <div className="mb-3">
                                    <label htmlFor="eventVenueLocation" className="form-label fs-6 mb-2">Where is the event venue location?</label>
                                    <Autocomplete
                                        className="form-control"
                                        placeholder='Please enter event venue location...'
                                        onPlaceSelected={(e) => handleLocationSelection(e)}
                                        options={{
                                            fields: ["formatted_address", "geometry", "name"],
                                            strictBounds: false,
                                            types: [],
                                        }}
                                    />
                                </div>
                    <div className="mb-4">
                        <label htmlFor="otherInfo" className="form-label mb-2">Any other information</label>
                        <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                            onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                    </div>
                    <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com </div>
                    <div className="btn-wrapper">
                        <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Next</button>
                    </div>
                    {props.loading ?
                        <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                            style={{ zIndex: 1030 }}>
                            <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                        </div>
                        : ''}
                </div>
            ) : (
                <UserInfoForQuoteService state={serviceData} />
            )}
        </>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(CateringServices))