import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './pages/ErrorPage';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { combineReducers, compose, applyMiddleware } from 'redux'
import { legacy_createStore as createStore } from 'redux'
import thunk from "redux-thunk";

//import all reducers
import businessReducer from './store/reducer/business'
import auth from './store/reducer/auth'
import subscriptionPlan from './store/reducer/subscriptionPlan'
import rating from './store/reducer/rating'
import rewardAndCoupon from './store/reducer/rewardAndCoupon'
import searchedBusiness from './store/reducer/searchedBusiness'


// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION__?.() || compose;
const rootReducer = combineReducers({
  business: businessReducer,
  auth: auth,
  subscriptionPlan: subscriptionPlan,
  rating: rating,
  rewardAndCoupon: rewardAndCoupon,
  searchedBusiness: searchedBusiness
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <Provider store={store}>
  //     <App />
  //   </Provider>
  // </React.StrictMode>
  <Provider store={store}>
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onReset={() => (window.location.href = '/')}
    >
      <App />
    </ErrorBoundary>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
