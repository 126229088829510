import React from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player'
import { Helmet } from "react-helmet";
import ReactLoading from 'react-loading';


const BlogDetails = (props) => {
  const { getBlogDetails, blogDetails } = props;
  const { state } = useLocation();
  const { blogId } = state

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    getBlogDetails({ blogId: blogId })
  }, [getBlogDetails, blogId])

  // console.log(blogDetails,'hello blog',useLocation())
  return (
    <div className='blog-details pb-3'>
      {/* title head */}
      <Helmet>
        <title>{blogDetails.blog_title}</title>
      </Helmet>
      <section className='bg-secondary bg-opacity-10 py-4 py-lg-5'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <div className="blog-label text-primary fw-medium mb-2">Blog Post</div>
              <div className="blog-title fs-2 fw-semibold mb-2">{blogDetails.blog_title}</div>
              <div className="blog-date text-muted">{blogDetails.createdAt ? new Date(blogDetails.createdAt).toDateString() : ''}</div>
            </div>
            <div className='col-md-6 mt-3 mt-md-0'>
              {blogDetails.blog_video && blogDetails.blog_picture ?
                <div className='row'>
                  <div className='col-md-6 col-sm-12 p-0'>
                    {blogDetails.blog_video ?
                      <div className="img-wrapper m-0">
                        <a className="player-wrapper" target="_blank" rel="noreferrer" href={blogDetails.blog_video_website_url ? 'https://' + blogDetails.blog_video_website_url : '#'} onClick={(event) => !blogDetails.blog_video_website_url ? event.preventDefault() : true}>
                          <ReactPlayer className='react-player' style={{ backgroundColor: 'black' }} width='100%' url={blogDetails.blog_video} playing={true} loop={true} volume={0.5} muted={true} controls={true} ></ReactPlayer>

                        </a>
                      </div> : ''
                    }
                  </div>

                  <div className='col-md-6 col-sm-12 p-0'>
                    {blogDetails.blog_picture ?
                      <div className="img-wrapper m-0">
                        <a target="_blank" rel="noreferrer" className='ratio ratio-16x9' href={blogDetails.blog_picture_website_url ? 'https://' + blogDetails.blog_picture_website_url : '#'} onClick={(event) => !blogDetails.blog_picture_website_url ? event.preventDefault() : true}>
                          <img className="img-fluid" src={blogDetails.blog_picture} alt="Blog Banner" />
                        </a>
                      </div> : ''
                    }
                  </div>
                </div> :
                <div className="img-wrapper only-one m-0">
                  {
                    blogDetails.blog_video ?

                      <a className="player-wrapper vv" target="_blank" rel="noreferrer" href={blogDetails.blog_video_website_url ? 'https://' + blogDetails.blog_video_website_url : '#'} onClick={(event) => !blogDetails.blog_video_website_url ? event.preventDefault() : true}>
                        <ReactPlayer className='react-player' width='100%' url={blogDetails.blog_video} playing={true} volume={0.5} loop={true} muted={true} controls={true} ></ReactPlayer>
                      </a>
                      :
                      <div>
                        {
                          blogDetails.blog_picture ?
                            <a target="_blank" rel="noreferrer" className='ratio ratio-16x9' href={blogDetails.blog_picture_website_url ? 'https://' + blogDetails.blog_picture_website_url : '#'} onClick={(event) => !blogDetails.blog_picture_website_url ? event.preventDefault() : true}>
                              <img className="img-fluid object-fit-cover" src={blogDetails.blog_picture} alt="Blog Banner" />
                            </a>
                            : ''
                        }
                      </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </section>
      <section className='story-details mt-4 mt-md-5'>
        <div className='container' style={{ maxWidth: '900px' }}>
          <div className='blog-details-content mt-5'>
            <div className='blog-description fs-18'>
              {blogDetails.blog_description}
            </div>
            {/* Blog story */}
            {
              blogDetails.blog_story ?
                blogDetails.blog_story.map((item) => (
                  <div className='mt-4' key={item._id}>
                    <div className='fs-4 fw-semibold'>{item.story_title}</div>
                    {
                      item.story_picture ?
                        <div className='img-wrapper text-center mt-4 mt-md-5'>
                          <a target="_blank" rel="noreferrer" href={item.story_picture_website_url ? 'https://' + item.story_picture_website_url : '#'} onClick={(event) => !item.story_picture_website_url ? event.preventDefault() : true}>
                            <img className="img-fluid" src={item.story_picture} alt="blog_story" style={{ maxWidth: '400px' }} />
                          </a>
                        </div>
                        : ''
                    }
                    <div className='story-description fs-18 mt-3'>
                      <div dangerouslySetInnerHTML={{
                        __html:
                          (item.story_content !== null) ? item.story_content : "waiting"
                      }}></div>
                    </div>
                    {item.story_video ?
                      <div className='video-wrapper text-center mt-4 mt-md-5'>
                        <a target="_blank" rel="noreferrer" className='text-center' href={item.story_video_website_url ? 'https://' + item.story_video_website_url : '#'} onClick={(event) => !item.story_video_website_url ? event.preventDefault() : true}>
                          <ReactPlayer className='react-player ratio ratio-16x9 mx-auto' url={item.story_video} playing={true} muted={true} volume={0.5} controls={true} ></ReactPlayer>
                        </a>
                      </div> : ''
                    }
                  </div>
                ))
                : ''
            }
          </div>
        </div>
      </section>
      {/* loading section */}
      {props.loading ?
        <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
          style={{ zIndex: 1030 }}>
          <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
        </div>
        : ''}
    </div>
  )
}

const mapPropsToState = (state) => {
  return {
    loading: state.business.loading,
    error: state.business.error,
    success: state.business.success,
    blogDetails: state.business.blogDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getBlogDetails: (data) => dispatch(actions.getBlogDetails(data)),
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(BlogDetails))