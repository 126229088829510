import React from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect } from 'react';
import ReactLoading from 'react-loading';

const AboutUs = (props) => {
    const { termAndConditionData, getPolicyTermConditionDetails } = props
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        getPolicyTermConditionDetails()
    }, [getPolicyTermConditionDetails]);
    return (
        <div className='couponranking-privacy-policy section-bg-image pt-4 pb-5'>
            {/* title head */}
            <Helmet>
                <title>About Us - Couponranking</title>
            </Helmet>
            <div className="container position-relative">
                <div className='privacy-policy bg-light p-3 p-md-5'
                    dangerouslySetInnerHTML={{
                        __html: termAndConditionData.about_us
                    }}
                >
                </div>
            </div>
            {props.loading ?
                <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                    style={{ zIndex: 1030 }}>
                    <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                </div>
                : ''}
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        termAndConditionData: state.business.policyTermConditionDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPolicyTermConditionDetails: () => dispatch(actions.getPolicyTermConditionDetails()),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(AboutUs))
