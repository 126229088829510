import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.scss';
import { Layout } from "./pages/Layout";
import Login from './pages/Login';
import Register from './pages/Register'
import VerifyOtp from './pages/VerifyOtp';
import BusinessSignUp from './pages/BusinessSignUp';
import ForgotPassword from "./pages/ForgotPassword";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/user-register" element={<Register />} />
        <Route exact path="/business-register" element={<BusinessSignUp />} />
        <Route exact path="/verify-user" element={<VerifyOtp />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<Layout />}>
          {/* <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
