// Auth action
export const START_AUTH = 'START_AUTH'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const GET_CARD_DETAILS_SUCCESS = 'GET_CARD_DETAILS_SUCCESS'

//bussiness
export const START_BUSSINESS_ACTION = "START_BUSSINESS_ACTION";
export const BUSSINESS_ACTION_FAIL = "BUSSINESS_ACTION_FAIL";
export const FETCH_BUSSINESS_LIST_SUCCESS = "FETCH_BUSSINESS_LIST_SUCCESS";
export const GET_BUSINESS_LIST_BY_SUBCATEGORY = "GET_BUSINESS_LIST_BY_SUBCATEGORY";
export const GET_ECOMMERCE_BUSINESSLIST = "GET_ECOMMERCE_BUSINESSLIST";
export const ADD_BUSINESS_DETAIL_SUCCESS = "ADD_BUSINESS_DETAIL_SUCCESS"
export const FETCH_CATEGORY_LIST_SUCCESS = 'FETCH_CATEGORY_LIST_SUCCESS';
export const GET_BLOG_LIST_SUCCESS = "GET_BLOG_LIST_SUCCESS";
export const GET_BLOG_DETAILS_SUCCESS = "GET_BLOG_DETAILS_SUCCESS";
export const GET_PICTURE_LIST_SUCCESS = "GET_PICTURE_LIST_SUCCESS"
export const GET_BUSINESS_DETAILS_SUCCESS = "GET_BUSINESS_DETAILS_SUCCESS"
export const COUPON_RANKING_MAIL_SUCCESS = 'COUPON_RANKING_MAIL_SUCCESS'
export const GET_ECOMMERCE_PAGE_BUSINESSLIST = 'GET_ECOMMERCE_PAGE_BUSINESSLIST';
export const GET_DEAL_BUSINESSLIST='GET_DEAL_BUSINESSLIST'
export const GET_COUPON_PAGE_BUSINESSLIST ='GET_COUPON_PAGE_BUSINESSLIST'
export const GET_STOREFRONT_BUSINESSLIST ='GET_STOREFRONT_BUSINESSLIST'
export const GET_POLICY_TERM_CONDITION ='GET_POLICY_TERM_CONDITION'
export const GET_COUPONRANKIG_RATING_LIST = 'GET_COUPONRANKIG_RATING_LIST';


//search business list
export const SEARCH_START_BUSSINESS_ACTION = "SEARCH_START_BUSSINESS_ACTION";
export const SEARCH_BUSSINESS_ACTION_FAIL = "SEARCH_BUSSINESS_ACTION_FAIL";
export const SEARCH_SUGGESTION = 'SEARCH_SUGGESTION'
export const SEARCH_BUSINESS_LIST = 'SEARCH_BUSINESS_LIST'

// plan list
export const START_PLAN_ACTION = "START_PLAN_ACTION";
export const PLAN_ACTION_FAIL = "PLAN_ACTION_FAIL";
export const FETCH_PLAN_LIST_SUCCESS = "FETCH_PLAN_LIST_SUCCESS";
export const FETCH_PLAN_DETAILS_SUCCESS = "FETCH_PLAN_DETAILS_SUCCESS"

// rating list
export const START_RATING_ACTION = "START_RATING_ACTION"
export const RATING_ACTION_FAIL = "RATING_ACTION_FAIL"
export const CREATE_RATING_SUCCESS = "CREATE_RATING_SUCCESS"
export const FETCH_RATING_LIST_SUCCESS = "FETCH_RATING_LIST_SUCCESS"

// reward and coupon
export const START_REWARD_ACTION ="START_REWARD_ACTION"
export const REWARD_ACTION_FAIL ="REWARD_ACTION_FAIL"
export const CREATE_COUPON_REWARD_SUCCESS ="CREATE_COUPON_REWARD_SUCCESS"
export const FETCH_REWARD_USER_LIST_SUCCESS ="FETCH_REWARD_USER_LIST_SUCCESS"
export const UPDATE_REWARD_SUCCESS = "UPDATE_REWARD_SUCCESS"