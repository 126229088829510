import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

const UserInfoForBookAppointment = ({ state }) => {
    useEffect(() => {
        //  scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);
    const [data, setData] = useState({
        ...state,
        mobileNo: '',
        customerName: '',
        customerEmail: ''
    });
    const [step, setStep] = useState(1);
    const [otp, setOtp] = useState('');
    const [userOtp, setUserOtp] = useState('')
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const business = useSelector(state => state.business);
    const { loading } = business;

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        if (data.customerName && data.customerEmail && data.mobileNo) {
            let otp = Math.floor(1000 + Math.random() * 9000);
            let mailData = {
                otp:otp,
                email:data.customerEmail
            }
            setOtp(`${otp}`);
            dispatch(actions.sendOtpMail(mailData,toast));
            setStep(2);
        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    };

    const handleOtpSubmit = (e) => {
        e.preventDefault();
        if(otp === userOtp){
            dispatch(actions.clickPerCount(data, toast,navigate));
        }else{
            toast.error('Incorrect Otp.Please enter correct otp.', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    };
    const handleOnChange = (value) => {
        if (!value.startsWith('+')) {
            value = '+' + value;
        }
        setData({
            ...data,
            'mobileNo': value
        });
    }
    return (
        <div className='userinfo-quote-service py-4'>
            <div className='container'>
            <ToastContainer />
                <div className="card bg-secondary bg-opacity-10 rounded-3 w-100 mx-auto px-3 py-4 p-md-5" style={{ maxWidth: '550px' }}>
                    {step === 1 && (
                        <Form>
                            <div className="heading-wrapper mb-4">
                                <div className="fs-3 fw-bold lh-sm mb-1">User Info</div>
                                <div className="text-muted">Please input all field.We send you a OTP on given email to verify</div>
                            </div>
                            <Form.Group className="mb-3" controlId="customerName">
                                <Form.Label>Full Name<span className="text-danger">*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter name"
                                    name="customerName"
                                    value={data.customerName}
                                    onChange={handleChange}
                                    autoComplete='off' required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="customerEmail">
                                <Form.Label>Email address<span className="text-danger">*</span></Form.Label>
                                <Form.Control type="email" placeholder="Enter your email"
                                    name="customerEmail"
                                    value={data.customerEmail}
                                    onChange={handleChange}
                                    autoComplete="off" required
                                />
                            </Form.Group>
                            <Form.Group className='mb-4'>
                                <Form.Label>Contact Number<span className="text-danger">*</span></Form.Label>
                                <PhoneInput
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: false
                                    }}
                                    country={"us"}
                                    value={data.mobileNo}
                                    onChange={handleOnChange}
                                    enableSearch={true}
                                    countryCodeEditable={false}
                                    inputClass="w-100 py-2"
                                    dropdownClass="text-start"
                                    inputStyle={{ height: 'auto', minHeight: '45px', borderColor: 'var(--bs-border-color)' }}
                                    placeholder='Enter your number'
                                />
                            </Form.Group>
                            <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com</div>
                            <div className="btn-wrapper">
                                <Button variant="primary" className="btn-custom w-100" onClick={(e)=>handleEmailSubmit(e)}>Send OTP</Button>
                            </div>
                        </Form>
                    )}
                    {step === 2 && (
                        <Form>
                            <div className="heading-wrapper mb-4">
                                <div className="fs-3 fw-bold lh-sm mb-1">Enter OTP</div>
                                <div className="text-muted">An 4 digit code has been sent to your email<span className="text-primary fw-semibold"> {data.customerEmail} </span></div>
                            </div>
                            <Form.Group className="mb-4" controlId="formOtp">
                                <Form.Label>Enter OTP<span className="text-danger">*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter OTP" maxLength={4}
                                    value={userOtp}
                                    onChange={(e) => setUserOtp(e.target.value)}
                                    autoComplete="off" required
                                />
                            </Form.Group>
                            <Button variant="primary" className="btn-custom w-100" onClick={(e)=>handleOtpSubmit(e)}>Verify OTP</Button>
                        </Form>
                    )}
                </div>
            </div>
            {/* {loading ?
                <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                    style={{ zIndex: 1030 }}>
                    <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                </div>
                : ''} */}
        </div>
    )
}

export default UserInfoForBookAppointment