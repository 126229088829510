import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
import UserInfoForQuoteService from './UserInfoForQuoteService';
Geocode.setApiKey("AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q");
Geocode.enableDebug();


const needServiceType = ['Wedding/Event Planning', 'Travel Agent', 'Event Rental and Photo Booths', 'Jewelry', 'Wedding/Event Dresses & Attire', 'Wedding Officiant', 'Hair & Makeup', 'Ceremony Music', 'DJ, Bands and Dance', 'Wedding Invitation', 'Transportation', 'Other'];
const weddingServiceBudget = ['Under $200', '$200 - $499', '$500 - $749', '$750 - $1499', '$1500 - $2499', '$2500 - $3499', 'Not Sure', 'Other'];
const weddingServicePlanning = ['Day-of coordination', 'Full planning', 'Partial planning', 'Not Sure', 'Other'];
const travelService = ['Cruises', 'Destination Wedding', 'Family Trips', 'Group Travel/Tours', 'Honeymoon', 'Luxury Trip', 'Other'];
const travelDestination = ['Africa', 'Alaska', ' Asia', 'Australia/New Zealand', 'Caribbean', 'Central America', 'East Europe', 'North America', 'South America', 'Not Sure', 'Other'];
const rentalVenue = ['Conference Hall', 'Bar & Nightclub', 'Banquet Hall', 'Brewery', 'Hotel', 'Sport Club', 'Museum', 'Boat', 'Classrooms', 'Home', 'Not Sure', 'Other'];
const rentalEquipment = [
    'Arch/Arbor', 'Plates', 'Chairs', 'Tents', 'Drape',
    'Lighting', 'Limousine', 'Linens', 'Lounge Furniture Collections',
    'Monograms', 'Photo Booths', 'Tables', 'Trash Can',
    'Tableware', 'Glassware', 'Barbecue Equipment', 'Silverware',
    'Kneeling Bench', 'Fans', 'Heater', 'Gaming Equipment',
    'Oven', 'Not Sure', 'Other'
];
// Jewelry
const jewelryTypes = [
    'Antique Accessories', 'Antique Engagement Rings', 'Antique Wedding Bands',
    'Custom Accessories', 'Custom Engagement Rings', 'Custom Wedding Bands',
    'Designer Accessories', 'Designer Engagement Rings', 'Designer Wedding Bands',
    'Other'
];

// Wedding/Event Dresses & Attire
const clienteleForDresses = [
    'Bride', 'Bridesmaids', 'Father of the Bride', 'Father of the Groom',
    'Flower Girls', 'Groomsmen', 'Mother of the Bride', 'Mother of the Groom',
    'Ring Bearers', 'Not Sure'
];

const accessoriesForDresses = [
    'Bridal Belts', 'Garters', 'Hair Accessories', 'Jewelry', 'Plus Size Gown',
    'Simple Gown', 'Shoes', 'Undergarments', 'Veils', 'Not Sure'
];

const dressAndAttireServices = [
    'Alterations', 'Cleaning', 'Full Customization', 'Preservation',
    'Rental', 'Sample Sales', 'Trunk Shows', 'Not Sure', 'Other'
];

// Wedding Officiants
const weddingOfficiantBudget = [
    'Under $200', '$200 - $499', '$500 - $749', '$750 - $1499', '$1500 - $2499', '$2500 - $3499', 'Not Sure', 'Other'
];

const premaritalCounseling = ['Yes', 'No'];

const officiantServiceTypes = [
    'Non-Religious', 'Interfaith', 'Ceremony Rehearsal', 'Vow Renewal',
    'Civil Union', 'Not Sure', 'Other'
];

const weddingReligions = [
    'Evangelicals', 'Jewish', 'Islam', 'Hindu', 'Agnostic',
    'Methodists', 'Catholic', 'Not Sure', 'Other'
];

// Hair & Makeup
const hairServices = [
    'Blowout', 'Coloring', 'Extension', 'Relaxer', 'Haircut', 'Keratin',
    'Perm', 'Hair Trial', 'Textured Hair Styling', 'Updos/Styling',
    'On-Site Hair', 'Not Sure', 'Other'
];

const makeupServices = [
    'Airbrush Makeup', 'Contour Makeup', 'Eye Makeup', 'Natural Makeup',
    'On-Site Makeup', 'Wedding Party Makeup', 'Makeup Trial',
    'Fake Lashes', 'Brow/Lash Tinting', 'Tattoo Coverage', 'Not Sure', 'Other'
];

const beautyServices = [
    'Body Art', 'Consultation', 'Facial', 'Fitness/Health', 'Manicure/Pedicure',
    'Massages', 'Men’s Grooming', 'Spa', 'Skincare', 'Not Sure', 'Other'
];

// Ceremony Music
const ceremonyMusicBudget = ['Under $200', '$200 - $499', '$500 - $749', '$750 - $1499', '$1500 - $2499', '$2500 - $3499', 'Not Sure', 'Other'];
const musicalServiceArrangements = [
    'Choir', 'Brass', 'Instrumental', 'Orchestra', 'String',
    'Soloist', 'Not Sure', 'Other'
];

const musicalInstruments = [
    'Cello', 'Drums', 'Flute', 'Guitar', 'Harp', 'Organ',
    'Piano', 'Trumpet', 'Saxophone', 'Viola', 'Not Sure', 'Other'
];

// DJ, Bands, or Dancers
const djBudget = ['Under $200', '$200 - $499', '$500 - $749', '$750 - $1499', '$1500 - $2499', '$2500 - $3499', 'Not Sure', 'Other'];
const musicGenres = [
    'Christian', 'Club', 'Country', 'Electronic', 'Hip Hop', 'Indie',
    'International', 'Jazz', 'Oldies', 'Pop', 'R&B/Soul', 'Rock',
    'Top 40', 'South Asian', 'Dance', 'Big Band', 'Acoustic',
    'Blues', 'Strings', 'Swing', 'Motown', 'Classical', 'Classic Rock'
];

// Wedding Invitation
const invitationServices = [
    'Assembly', 'Calligraphy', 'Design', 'Embossed',
    'Engraving', 'Letterpress', 'Printing', 'Not Sure'
];

// Transportation
const vehicleTypes = [
    'Classic Car', 'Horse and Carriage', 'SUV Limo', 'Standard Limo',
    'Stretch Limo', 'Motor Coach', 'Sedan', 'Shuttle',
    'Trolley', 'Van', 'Not Sure', 'Other'
];

const PartyType = ['Wedding', 'Birthday', 'Shower', 'Party', 'Anniversary', 'Get-together', 'Other'];
const LocationForEvent = ['Conference hall', 'Bar and nightclub', 'Banquet hall', 'Breweries and wineries', 'Hotel', 'sport clubs', 'Other'];
// const ServiceType = ['Videography', 'DJ', 'Hair & makeup artist', 'Aerial drone video', 'Wedding officiant', 'Floral arrangement', 'Wedding planning',
//     'Photography', 'Dancer', 'Comedy', 'Slot machine'];
// const EquipmentNeeded = ['Arch/Arbor', 'Plates', 'Chairs', 'Tents', 'Drape', 'Lighting', 'Limousine', 'Linens', 'Lounge furniture collections',
//     'Monograms', 'Photo booths', 'Tables', 'Trash can', 'Tableware', 'Glassware', 'Barbecue equipment', 'Silverware', 'Kneeling bench', 'Fans', 'Heater',
//     'Gaming equipment', 'Oven', 'Not sure'];
const GuestNumber = ['Less than 150', 'Between 151 to 300', 'Above 301', 'Other']
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];

export const Entertainment = (props) => {
    const [serviceData, setServiceData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [typeOfNeedService, setTypeOfNeedService] = useState('');
    const [otherTypeOfNeedService, setOtherTypeOfNeedService] = useState(false);
    const [otherTypeOfNeedServiceValue, setOtherTypeOfNeedServiceValue] = useState(''); // New state for the input value

    const [typeOfWeddingServiceBudget, setTypeOfWeddingServiceBudget] = useState('');
    const [otherTypeOfWeddingServiceBudget, setOtherTypeOfWeddingServiceBudget] = useState(false);
    const [otherTypeOfWeddingServiceBudgetValue, setOtherTypeOfWeddingServiceBudgetValue] = useState(''); // New state for the input value
    const [typeOfWeddingServicePlanning, setTypeOfWeddingServicePlanning] = useState('');
    const [otherTypeOfWeddingServicePlanning, setOtherTypeOfWeddingServicePlanning] = useState(false);
    const [otherTypeOfWeddingServicePlanningValue, setOtherTypeOfWeddingServicePlanningValue] = useState(''); // New state for the input value

    const [typeOfTravelService, setTypeOfTravelService] = useState('');
    const [otherTypeOfTravelService, setOtherTypeOfTravelService] = useState(false);
    // const [otherTypeOfTravelServiceValue, setOtherTypeOfTravelServiceValue] = useState(''); // New state for the input value

    const [typeOfTravelDestination, setTypeOfTravelDestination] = useState('');
    const [otherTypeOfTravelDestination, setOtherTypeOfTravelDestination] = useState(false);
    // const [otherTypeOfTravelDestinationValue, setOtherTypeOfTravelDestinationValue] = useState(''); // New state for the input value


    const [typeOfRentalVenue, setTypeOfRentalVenue] = useState('');
    const [otherTypeOfRentalVenue, setOtherTypeOfRentalVenue] = useState(false);
    // const [otherTypeOfRentalVenueValue, setOtherTypeOfRentalVenueValue] = useState(''); // New state for the input value

    const [typeOfRentalEquipment, setTypeOfRentalEquipment] = useState('');
    const [otherTypeOfRentalEquipment, setOtherTypeOfRentalEquipment] = useState(false);
    // const [otherTypeOfRentalEquipmentValue, setOtherTypeOfRentalEquipmentValue] = useState(''); // New state for the input value

    // Jewelry
    const [typeOfJewelry, setTypeOfJewelry] = useState('');
    const [otherTypeOfJewelry, setOtherTypeOfJewelry] = useState(false);
    // const [otherTypeOfJewelryValue, setOtherTypeOfJewelryValue] = useState(''); // New state for the input value

    // Wedding/Event Dresses & Attire
    const [typeOfClienteleForDresses, setTypeOfClienteleForDresses] = useState('');
    const [otherTypeOfClienteleForDresses, setOtherTypeOfClienteleForDresses] = useState(false);

    const [typeOfAccessoriesForDresses, setTypeOfAccessoriesForDresses] = useState('');
    const [otherTypeOfAccessoriesForDresses, setOtherTypeOfAccessoriesForDresses] = useState(false);

    const [typeOfDressAndAttireServices, setTypeOfDressAndAttireServices] = useState('');
    const [otherTypeOfDressAndAttireServices, setOtherTypeOfDressAndAttireServices] = useState(false);
    // const [otherTypeOfDressAndAttireServicesValue, setOtherTypeOfDressAndAttireServicesValue] = useState(''); // New state for the input value

    // Wedding Officiants
    const [typeOfWeddingOfficiantBudget, setTypeOfWeddingOfficiantBudget] = useState('');
    const [otherTypeOfWeddingOfficiantBudget, setOtherTypeOfWeddingOfficiantBudget] = useState(false);
    // const [otherTypeOfWeddingOfficiantBudgetValue, setOtherTypeOfWeddingOfficiantBudgetValue] = useState(''); // New state for the input value

    const [typeOfPremaritalCounseling, setTypeOfPremaritalCounseling] = useState('');
    const [otherTypeOfPremaritalCounseling, setOtherTypeOfPremaritalCounseling] = useState(false);

    const [typeOfOfficiantService, setTypeOfOfficiantService] = useState('');
    const [otherTypeOfOfficiantService, setOtherTypeOfOfficiantService] = useState(false);
    // const [otherTypeOfOfficiantServiceValue, setOtherTypeOfOfficiantServiceValue] = useState(''); // New state for the input value

    const [typeOfWeddingReligion, setTypeOfWeddingReligion] = useState('');
    const [otherTypeOfWeddingReligion, setOtherTypeOfWeddingReligion] = useState(false);
    // const [otherTypeOfWeddingReligionValue, setOtherTypeOfWeddingReligionValue] = useState(''); // New state for the input value

    // Hair & Makeup
    const [typeOfHairService, setTypeOfHairService] = useState('');
    const [otherTypeOfHairService, setOtherTypeOfHairService] = useState(false);
    // const [otherTypeOfHairServiceValue, setOtherTypeOfHairServiceValue] = useState(''); // New state for the input value

    const [typeOfMakeupService, setTypeOfMakeupService] = useState('');
    const [otherTypeOfMakeupService, setOtherTypeOfMakeupService] = useState(false);
    // const [otherTypeOfMakeupServiceValue, setOtherTypeOfMakeupServiceValue] = useState(''); // New state for the input value

    const [typeOfBeautyService, setTypeOfBeautyService] = useState('');
    const [otherTypeOfBeautyService, setOtherTypeOfBeautyService] = useState(false);
    // const [otherTypeOfBeautyServiceValue, setOtherTypeOfBeautyServiceValue] = useState(''); // New state for the input value

    // Ceremony Music setTypeOfMusicalServiceBudget
    const [typeOfMusicalServiceBudget, setTypeOfMusicalServiceBudget] = useState('');
    const [otherTypeOfMusicalServiceBudget, setOtherTypeOfMusicalServiceBudget] = useState(false);
    // const [otherTypeOfMusicalServiceBudgetValue, setOtherTypeOfMusicalServiceBudgetValue] = useState(''); // New state for the input value

    const [typeOfMusicalServiceArrangement, setTypeOfMusicalServiceArrangement] = useState('');
    const [otherTypeOfMusicalServiceArrangement, setOtherTypeOfMusicalServiceArrangement] = useState(false);
    // const [otherTypeOfMusicalServiceArrangementValue, setOtherTypeOfMusicalServiceArrangementValue] = useState(''); // New state for the input value

    const [typeOfMusicalInstrument, setTypeOfMusicalInstrument] = useState('');
    const [otherTypeOfMusicalInstrument, setOtherTypeOfMusicalInstrument] = useState(false);
    // const [otherTypeOfMusicalInstrumentValue, setOtherTypeOfMusicalInstrumentValue] = useState(''); // New state for the input value

    // DJ, Bands, or Dancers
    const [typeOfMusicBudget, setTypeOfMusicBudget] = useState('');
    const [otherTypeOfMusicBudget, setOtherTypeOfMusicBudget] = useState(false);
    // const [otherTypeOfMusicBudgetValue, setOtherTypeOfMusicBudgetValue] = useState(''); // New state for the input value

    const [typeOfMusicGenre, setTypeOfMusicGenre] = useState('');
    const [otherTypeOfMusicGenre, setOtherTypeOfMusicGenre] = useState(false);
    // const [otherTypeOfMusicGenreValue, setOtherTypeOfMusicGenreValue] = useState(''); // New state for the input value

    // Wedding Invitation
    const [typeOfInvitationService, setTypeOfInvitationService] = useState('');
    const [otherTypeOfInvitationService, setOtherTypeOfInvitationService] = useState(false);
    // const [otherTypeOfInvitationServiceValue, setOtherTypeOfInvitationServiceValue] = useState(''); // New state for the input value

    // Transportation
    const [typeOfVehicle, setTypeOfVehicle] = useState('');
    const [otherTypeOfVehicle, setOtherTypeOfVehicle] = useState(false);
    // const [otherTypeOfVehicleValue, setOtherTypeOfVehicleValue] = useState(''); // New state for the input value

    const [typeOfParty, setTypeOfParty] = useState('');
    const [otherTypeOfParty, setOtherTypeOfParty] = useState(false);
    // const [otherTypeOfPartyValue, setOtherTypeOfPartyValue] = useState(''); // New state for the input value

    const [eventLocation, setEventLocation] = useState('');
    const [otherEventLocation, setOtherEventLocation] = useState(false);
    // const [otherEventLocationValue, setOtherEventLocationValue] = useState(''); // New state for the input value

    const [bringFoodStatus, setBringFoodStatus] = useState('');
    const [service, setService] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [guest, setGuest] = useState('');
    const [otherGuest, setOtherGuest] = useState(false);
    // const [otherGuestValue, setOtherGuestValue] = useState(''); // New state for the input value

    const [appointDate, setAppointDate] = useState(new Date());
    const [startingTime, setStartingTime] = useState('');
    const [closingTime, setClosingTime] = useState('');
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    // const [otherContactModeValue, setOtherContactModeValue] = useState(''); // New state for the input value

    const [otherInfo, setOtherInfo] = useState('');
    const [eventVenueLocation, setEventVenueLocation] = useState('');

    useEffect(() => {
        //  scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    const handleNeedServiceType = (value) => {
        if (value === 'Other') {
            setOtherTypeOfNeedService(true);
            setTypeOfNeedService('')
        } else {
            setOtherTypeOfNeedService(false);
            setTypeOfNeedService(value)
            // setOtherTypeOfNeedServiceValue(''); // Reset other input value if not "Other"

        }
    }

    const handleWeddingServiceBudget = (value) => {
        if (value === 'Other') {
            setOtherTypeOfWeddingServiceBudget(true);
            setTypeOfWeddingServiceBudget('')
        } else {
            setOtherTypeOfWeddingServiceBudget(false);
            setTypeOfWeddingServiceBudget(value)
            // setOtherTypeOfWeddingServiceBudgetValue(''); // Reset other input value if not "Other"

        }
    }

    const handleWeddingServicePlanning = (value) => {
        if (value === 'Other') {
            setOtherTypeOfWeddingServicePlanning(true);
            setTypeOfWeddingServicePlanning('')
        } else {
            setOtherTypeOfWeddingServicePlanning(false);
            setTypeOfWeddingServicePlanning(value)
            // setOtherTypeOfWeddingServicePlanningValue(''); // Reset other input value if not "Other"

        }
    }

    const handleTravelService = (value) => {
        if (value === 'Other') {
            setOtherTypeOfTravelService(true);
            setTypeOfTravelService('')
        } else {
            setOtherTypeOfTravelService(false);
            setTypeOfTravelService(value)
            // setOtherTypeOfTravelServiceValue(''); // Reset other input value if not "Other"

        }
    }

    const handleTravelDestination = (value) => {
        if (value === 'Other') {
            setOtherTypeOfTravelDestination(true);
            setTypeOfTravelDestination('')
        } else {
            setOtherTypeOfTravelDestination(false);
            setTypeOfTravelDestination(value)
            // setOtherTypeOfTravelDestinationValue(''); // Reset other input value if not "Other"
        }
    }

    const handleRentalVenue = (value) => {
        if (value === 'Other') {
            setOtherTypeOfRentalVenue(true);
            setTypeOfRentalVenue('')
        } else {
            setOtherTypeOfRentalVenue(false);
            setTypeOfRentalVenue(value)
            // setOtherTypeOfRentalVenueValue(''); // Reset other input value if not "Other"

        }
    }

    const handleRentalEquipment = (value) => {
        if (value === 'Other') {
            setOtherTypeOfRentalEquipment(true);
            setTypeOfRentalEquipment('')
        } else {
            setOtherTypeOfRentalEquipment(false);
            setTypeOfRentalEquipment(value)
            // setOtherTypeOfRentalEquipmentValue(''); // Reset other input value if not "Other"

        }
    }

    // Jewelry
    const handleJewelryType = (value) => {
        if (value === 'Other') {
            setOtherTypeOfJewelry(true);
            setTypeOfJewelry('');
        } else {
            setOtherTypeOfJewelry(false);
            setTypeOfJewelry(value);
            // setOtherTypeOfJewelryValue(''); // Reset other input value if not "Other"

        }
    };

    // Wedding/Event Dresses & Attire
    const handleClienteleForDresses = (value) => {
        if (value === 'Other') {
            setOtherTypeOfClienteleForDresses(true);
            setTypeOfClienteleForDresses('');
        } else {
            setOtherTypeOfClienteleForDresses(false);
            setTypeOfClienteleForDresses(value);
            // setOtherTypeOfClienteleForDressesValue(''); // Reset other input value if not "Other"

        }
    };

    const handleAccessoriesForDresses = (value) => {
        if (value === 'Other') {
            setOtherTypeOfAccessoriesForDresses(true);
            setTypeOfAccessoriesForDresses('');
        } else {
            setOtherTypeOfAccessoriesForDresses(false);
            setTypeOfAccessoriesForDresses(value);
            // setOtherTypeOfAccessoriesForDressesValue(''); // Reset other input value if not "Other"

        }
    };

    const handleDressAndAttireServices = (value) => {
        if (value === 'Other') {
            setOtherTypeOfDressAndAttireServices(true);
            setTypeOfDressAndAttireServices('');
        } else {
            setOtherTypeOfDressAndAttireServices(false);
            setTypeOfDressAndAttireServices(value);
            // setOtherTypeOfDressAndAttireServicesValue(''); // Reset other input value if not "Other"

        }
    };

    // Wedding Officiants
    const handleWeddingOfficiantBudget = (value) => {
        if (value === 'Other') {
            setOtherTypeOfWeddingOfficiantBudget(true);
            setTypeOfWeddingOfficiantBudget('');
        } else {
            setOtherTypeOfWeddingOfficiantBudget(false);
            setTypeOfWeddingOfficiantBudget(value);
            // setOtherTypeOfWeddingOfficiantBudgetValue(''); // Reset other input value if not "Other"

        }
    };

    const handlePremaritalCounseling = (value) => {
        if (value === 'Other') {
            setOtherTypeOfPremaritalCounseling(true);
            setTypeOfPremaritalCounseling('');
        } else {
            setOtherTypeOfPremaritalCounseling(false);
            setTypeOfPremaritalCounseling(value);
            // setOtherTypeOfPremaritalCounselingValue(''); // Reset other input value if not "Other"

        }
    };

    const handleOfficiantService = (value) => {
        if (value === 'Other') {
            setOtherTypeOfOfficiantService(true);
            setTypeOfOfficiantService('');
        } else {
            setOtherTypeOfOfficiantService(false);
            setTypeOfOfficiantService(value);
            // setOtherTypeOfOfficiantServiceValue(''); // Reset other input value if not "Other"

        }
    };

    const handleWeddingReligion = (value) => {
        if (value === 'Other') {
            setOtherTypeOfWeddingReligion(true);
            setTypeOfWeddingReligion('');
        } else {
            setOtherTypeOfWeddingReligion(false);
            setTypeOfWeddingReligion(value);
            // setOtherTypeOfWeddingReligionValue(''); // Reset other input value if not "Other"

        }
    };

    // Hair & Makeup
    const handleHairService = (value) => {
        if (value === 'Other') {
            setOtherTypeOfHairService(true);
            setTypeOfHairService('');
        } else {
            setOtherTypeOfHairService(false);
            setTypeOfHairService(value);
            // setOtherTypeOfHairServiceValue(''); // Reset other input value if not "Other"

        }
    };

    const handleMakeupService = (value) => {
        if (value === 'Other') {
            setOtherTypeOfMakeupService(true);
            setTypeOfMakeupService('');
        } else {
            setOtherTypeOfMakeupService(false);
            setTypeOfMakeupService(value);
            // setOtherTypeOfMakeupServiceValue(''); // Reset other input value if not "Other"

        }
    };

    const handleBeautyService = (value) => {
        if (value === 'Other') {
            setOtherTypeOfBeautyService(true);
            setTypeOfBeautyService('');
        } else {
            setOtherTypeOfBeautyService(false);
            setTypeOfBeautyService(value);
            // setOtherTypeOfBeautyServiceValue(''); // Reset other input value if not "Other"

        }
    };

    // Ceremony Music

    const handleCeremonyMusicBudget = (value) => {
        if (value === 'Other') {
            setOtherTypeOfMusicalServiceBudget(true);
            setTypeOfMusicalServiceBudget('');
        } else {
            setOtherTypeOfMusicalServiceBudget(false);
            setTypeOfMusicalServiceBudget(value);
            // setOtherTypeOfMusicalServiceBudgetValue(''); // Reset other input value if not "Other"

        }
    };

    const handleMusicalServiceArrangement = (value) => {
        if (value === 'Other') {
            setOtherTypeOfMusicalServiceArrangement(true);
            setTypeOfMusicalServiceArrangement('');
        } else {
            setOtherTypeOfMusicalServiceArrangement(false);
            setTypeOfMusicalServiceArrangement(value);
            // setOtherTypeOfMusicalServiceArrangementValue(''); // Reset other input value if not "Other"

        }
    };

    const handleMusicalInstrument = (value) => {
        if (value === 'Other') {
            setOtherTypeOfMusicalInstrument(true);
            setTypeOfMusicalInstrument('');
        } else {
            setOtherTypeOfMusicalInstrument(false);
            setTypeOfMusicalInstrument(value);
            // setOtherTypeOfMusicalInstrumentValue(''); // Reset other input value if not "Other"

        }
    };

    // DJ, Bands, or Dancers
    const handleMusicBudget = (value) => {
        if (value === 'Other') {
            setOtherTypeOfMusicBudget(true);
            setTypeOfMusicBudget('');
        } else {
            setOtherTypeOfMusicBudget(false);
            setTypeOfMusicBudget(value);
            // setOtherTypeOfMusicBudgetValue(''); // Reset other input value if not "Other"

        }
    };

    const handleMusicGenre = (value) => {
        if (value === 'Other') {
            setOtherTypeOfMusicGenre(true);
            setTypeOfMusicGenre('');
        } else {
            setOtherTypeOfMusicGenre(false);
            setTypeOfMusicGenre(value);
            // setOtherTypeOfMusicGenreValue(''); // Reset other input value if not "Other"

        }
    };

    // Wedding Invitation
    const handleInvitationService = (value) => {
        if (value === 'Other') {
            setOtherTypeOfInvitationService(true);
            setTypeOfInvitationService('');
        } else {
            setOtherTypeOfInvitationService(false);
            setTypeOfInvitationService(value);
            // setOtherTypeOfInvitationServiceValue(''); // Reset other input value if not "Other"

        }
    };

    // Transportation
    const handleVehicleType = (value) => {
        if (value === 'Other') {
            setOtherTypeOfVehicle(true);
            setTypeOfVehicle('');
        } else {
            setOtherTypeOfVehicle(false);
            setTypeOfVehicle(value);
            // setOtherTypeOfVehicleValue(''); // Reset other input value if not "Other"

        }
    };


    const handlePartType = (value) => {
        if (value === 'Other') {
            setOtherTypeOfParty(true);
            setTypeOfParty('')
        } else {
            setOtherTypeOfParty(false);
            setTypeOfParty(value)
            // setOtherTypeOfPartyValue(''); // Reset other input value if not "Other"

        }
    }
    const handleEventLocation = (value) => {
        if (value === 'Other') {
            setOtherEventLocation(true);
            setEventLocation('')
        } else {
            setOtherEventLocation(false);
            setEventLocation(value)
            // setOtherEventLocationValue(''); // Reset other input value if not "Other"

        }
    }
    const handleService = (value) => {
        let updatedService = [...service]
        let IndexValue = updatedService.indexOf(value)
        if (updatedService.includes(value)) {
            updatedService.splice(IndexValue, 1)
            setService(updatedService)
        } else {
            updatedService.push(value)
            setService(updatedService)
        }
    }
    const handleEquipment = (value) => {
        let updatedEquipment = [...equipment]
        let IndexValue = updatedEquipment.indexOf(value)
        if (updatedEquipment.includes(value)) {
            updatedEquipment.splice(IndexValue, 1)
            setEquipment(updatedEquipment)
        } else {
            updatedEquipment.push(value)
            setEquipment(updatedEquipment)
        }
    }
    const handleGuest = (value) => {
        if (value === 'Other') {
            setOtherGuest(true);
            setGuest('')
        } else {
            setOtherGuest(false);
            setGuest(value)
            // setOtherGuestValue(''); // Reset other input value if not "Other"

        }
    }

    const handleTimeChange = (event, type) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        if (type === 'start') {
            setStartingTime(selectedTime)
        } else {
            setClosingTime(selectedTime)
        }
    }
    const handleLocationSelection = (place, type) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
            method: 'GET'
        }).then((response) => response.json())
            .then((data) => {
                if (data.results[1]) {
                    const address = data.results[0].formatted_address
                    setEventVenueLocation(address)
                }
            })
            .catch((error) => {
                toast.error(error, {
                    autoClose: 700,
                    hideProgressBar: true,
                })
            })
    };
    const handleContactMode = (value) => {
        if (value === 'Other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
            // setOtherContactModeValue(''); // Reset other input value if not "Other"

        }
    }
    
    //  submit
    const handleSubmit = (e) => {
        e.preventDefault()

        if ((typeOfNeedService || otherTypeOfNeedService) && (typeOfParty || otherTypeOfParty) && bringFoodStatus && (guest || otherGuest) &&
            startingTime && closingTime && appointDate && eventVenueLocation && (contactMode || otherContactMode)) {

            let UsDate = new Date(appointDate).toLocaleDateString("en-US")
            let quoteDetails = {
                "Requested Service": "Entertainment And Events Planning",
                // "What service do you need?": finalTypeOfNeedService,
                "What type of event are you planning?": typeOfParty,
                // "Select the event location?": eventLocation,
                "Will you be bringing outside food?": bringFoodStatus,
                // "Select Your service": service,
                "Numbers of guest attending": guest,
                "Where is the event venue Address?": eventVenueLocation,
                'What is the best way to contact you?': contactMode,
                "When do you need the service?": UsDate,
                "Starting time": startingTime,
                "Closing time": closingTime
            }
            // If Wedding/Event Planning is Other
            const finalTypeOfNeedService = 
            typeOfNeedService && typeOfNeedService.trim() !== "" 
                ? typeOfNeedService 
                : otherTypeOfNeedServiceValue.trim() !== "" 
                    ? otherTypeOfNeedServiceValue 
                    : null;
            if (finalTypeOfNeedService) {
                // Use `finalTypeOfNeedService`
                quoteDetails["What service do you need?"] = finalTypeOfNeedService;
            }

            // If Wedding/Event Planning -> Service Budget is Other
            const finalTypeOfWeddingServiceBudget = 
            typeOfWeddingServiceBudget && typeOfWeddingServiceBudget.trim() !== "" 
                ? typeOfWeddingServiceBudget 
                : otherTypeOfWeddingServiceBudgetValue.trim() !== "" 
                    ? otherTypeOfWeddingServiceBudgetValue 
                    : null;
            if (typeOfNeedService === "Wedding/Event Planning" && finalTypeOfWeddingServiceBudget) {
                // Use `finalTypeOfWeddingServiceBudget`
                quoteDetails["What is your budget for this service?"] = finalTypeOfWeddingServiceBudget;
            } else if(typeOfNeedService === "Wedding/Event Planning" && typeOfWeddingServiceBudget) {
                quoteDetails["What is your budget for this service?"] = typeOfWeddingServiceBudget;
            }

            // If Wedding/Event Planning -> Service Planning is Other
            const finalTypeOfWeddingServicePlanning = 
            typeOfWeddingServicePlanning && typeOfWeddingServicePlanning.trim() !== "" 
                ? typeOfWeddingServicePlanning 
                : otherTypeOfWeddingServicePlanningValue.trim() !== "" 
                    ? otherTypeOfWeddingServicePlanningValue 
                    : null;

            if (typeOfNeedService === "Wedding/Event Planning" && finalTypeOfWeddingServicePlanning) {
                // Use `finalTypeOfWeddingServicePlanning`
                quoteDetails["What planning service do you need?"] = finalTypeOfWeddingServicePlanning;
            } else if(typeOfNeedService === "Wedding/Event Planning" && typeOfWeddingServicePlanning) {
                quoteDetails["What planning service do you need?"] = typeOfWeddingServicePlanning;
            }

            // Add additional questions based on typeOfNeedService
           if (typeOfNeedService === "Travel Agent") {
                quoteDetails["What type of travel service do you need?"] = typeOfTravelService;
                quoteDetails["What is your travel destination?"] = typeOfTravelDestination;
            } else if (typeOfNeedService === "Event Rental and Photo Booths") {
                quoteDetails["What type of venue do you need?"] = typeOfRentalVenue;
                quoteDetails["What type of rental equipment do you need for the event?"] = typeOfRentalEquipment;
            } else if (typeOfNeedService === "Jewelry") {
                quoteDetails["What jewelry type do you need?"] = typeOfJewelry;
            } else if (typeOfNeedService === "Wedding/Event Dresses & Attire") {
                quoteDetails["Who are the clientele that need this product?"] = typeOfClienteleForDresses;
                quoteDetails["What accessories do you need?"] = typeOfAccessoriesForDresses;
                quoteDetails["What dress and attire service do you need?"] = typeOfDressAndAttireServices;
            } else if (typeOfNeedService === "Wedding Officiant") {
                quoteDetails["What is your budget for this service?"] = typeOfWeddingOfficiantBudget;
                quoteDetails["Do you need premarital counseling for your wedding?"] = typeOfPremaritalCounseling;
                quoteDetails["What type of wedding officiant services do you need?"] = typeOfOfficiantService;
                quoteDetails["What religion do you need for your wedding?"] = typeOfWeddingReligion;
            } else if (typeOfNeedService === "Hair & Makeup") {
                quoteDetails["What hair services do you need?"] = typeOfHairService;
                quoteDetails["What makeup services do you need?"] = typeOfMakeupService;
                quoteDetails["What beauty services do you need?"] = typeOfBeautyService;
            } else if (typeOfNeedService === "Ceremony Music") {
                quoteDetails["What is your budget for this service?"] = typeOfMusicalServiceBudget;
                quoteDetails["What is your musical service arrangement?"] = typeOfMusicalServiceArrangement;
                quoteDetails["What musical instrument do you need for this service?"] = typeOfMusicalInstrument;
            } else if (typeOfNeedService === "DJ, Bands and Dance") {
                quoteDetails["What is your budget for this service?"] = typeOfMusicBudget;
                quoteDetails["What music genre do you need?"] = typeOfMusicGenre;
            } else if (typeOfNeedService === "Wedding Invitation") {
                quoteDetails["What invitation service do you need?"] = typeOfInvitationService;
            } else if (typeOfNeedService === "Transportation") {
                quoteDetails["What type of vehicle do you need?"] = typeOfVehicle;
            }

            if (equipment.length > 0) {
                quoteDetails['Equipment needed '] = equipment
            }
            if (otherInfo) {
                quoteDetails["Any other details"] = otherInfo
            }
            let data = {
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            setServiceData(data)
            setIsSubmit(true)

        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }
    return (
        <>
            {
                !isSubmit ?
                    (
                        <div id="Entertainment" className='card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
                            <Helmet>
                                <title>Request Quote For Entertainment And Events Planning</title>
                            </Helmet>
                            <ToastContainer />
                            <div className="section-label fs-3 mb-4">Entertainment and Events Planning</div>
                            <div className="mb-3">
                                <label className="form-label fs-6 mb-2">What service do you need?</label>
                                <div className="d-flex flex-wrap g-2">
                                    {
                                        needServiceType.map((needServiceType) => (
                                            <div className="form-check me-3" key={needServiceType}>
                                                <input className="form-check-input" type="radio" name="radioGroupForneedServiceType" id={needServiceType} required
                                                    onClick={() => handleNeedServiceType(needServiceType)} />
                                                <label className="form-check-label" htmlFor={needServiceType}>{needServiceType}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    otherTypeOfNeedService ?
                                        <input type="text" className="form-control mt-2" id="otherNeedService" placeholder='Other type of service...'
                                        onChange={(e) => setTypeOfNeedService(e.target.value)} // Update state on change 
                                        required />
                                        : ''
                                }

                                {/* If choose Wedding/Event planning */}
                                {typeOfNeedService === 'Wedding/Event Planning' && (
                                    <div className="mt-3">
                                        {/* ===================== */}
                                        <label className="form-label fs-6 mb-2">What is your budget for this service?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                weddingServiceBudget.map((weddingServiceBudget) => (
                                                    <div className="form-check me-3" key={weddingServiceBudget}>
                                                        <input className="form-check-input" type="radio" name="radioGroupForweddingServiceBudget" id={weddingServiceBudget} required
                                                            onClick={() => handleWeddingServiceBudget(weddingServiceBudget)} />
                                                        <label className="form-check-label" htmlFor={weddingServiceBudget}>{weddingServiceBudget}</label>
                                                    </div>
                                                ))
                                            }
                                            {
                                                otherTypeOfWeddingServiceBudget ?
                                                    <input type="text" className="form-control mt-2" id="otherWeddingServiceBudget" placeholder='Other type of service budget...'
                                                    onChange={(e) => setTypeOfWeddingServiceBudget(e.target.value)} // Update state on change 
                                                    required />
                                                    : ''
                                            }
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What planning service do you need?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    weddingServicePlanning.map((weddingServicePlanning) => (
                                                        <div className="form-check me-3" key={weddingServicePlanning}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForweddingServicePlanning" id={weddingServicePlanning} required
                                                                onClick={() => handleWeddingServicePlanning(weddingServicePlanning)} />
                                                            <label className="form-check-label" htmlFor={weddingServicePlanning}>{weddingServicePlanning}</label>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    otherTypeOfWeddingServicePlanning ?
                                                        <input type="text" className="form-control mt-2" id="otherWeddingServicePlanning" placeholder='Other type of service planning...'
                                                    onChange={(e) => setTypeOfWeddingServicePlanning(e.target.value)} // Update state on change 
                                                        required />
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* If choose Travel Agent*/}
                                {typeOfNeedService === 'Travel Agent' && (
                                    <div className="mt-3">
                                        {/* ===================== */}
                                        <label className="form-label fs-6 mb-2">What type travel service do you need?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                travelService.map((travelService) => (
                                                    <div className="form-check me-3" key={travelService}>
                                                        <input className="form-check-input" type="radio" name="radioGroupForTravelService" id={travelService} required
                                                            onClick={() => handleTravelService(travelService)} />
                                                        <label className="form-check-label" htmlFor={travelService}>{travelService}</label>
                                                    </div>
                                                ))
                                            }
                                            {
                                                otherTypeOfTravelService ?
                                                    <input type="text" className="form-control mt-2" id="otherTravelService" placeholder='Other type of travel service...'
                                                    onChange={(e) => setTypeOfTravelService(e.target.value)} // Update state on change     
                                                    required />
                                                    : ''
                                            }
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What is your travel destination? </label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    travelDestination.map((travelDestination) => (
                                                        <div className="form-check me-3" key={travelDestination}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForTravelDestination" id={travelDestination} required
                                                                onClick={() => handleTravelDestination(travelDestination)} />
                                                            <label className="form-check-label" htmlFor={travelDestination}>{travelDestination}</label>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    otherTypeOfTravelDestination ?
                                                        <input type="text" className="form-control mt-2" id="otherTravelDestination" placeholder='Other type of travel destination...'
                                                        onChange={(e) => setTypeOfTravelDestination(e.target.value)} // Update state on change                 
                                                        required />
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* If choose Event Rental and Photo Booths */}
                                {typeOfNeedService === 'Event Rental and Photo Booths' && (
                                    <div className="mt-3">
                                        <label className="form-label fs-6 mb-2">What type of venue do you need?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                rentalVenue.map((venue) => (
                                                    <div className="form-check me-3" key={venue}>
                                                        <input className="form-check-input" type="radio" name="radioGroupForVenue" id={venue} required
                                                            onClick={() => handleRentalVenue(venue)} />
                                                        <label className="form-check-label" htmlFor={venue}>{venue}</label>
                                                    </div>
                                                ))
                                            }
                                            {otherTypeOfRentalVenue ?
                                                <input type="text" className="form-control mt-2" id="otherRentalVenue" placeholder='Other type of venue...'
                                                onChange={(e) => setTypeOfRentalVenue(e.target.value)} // Update state on change 
                                                required />
                                                : ''
                                            }
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What type of rental equipment do you need for the event?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    rentalEquipment.map((equipment) => (
                                                        <div className="form-check me-3" key={equipment}>
                                                            <input className="form-check-input" type="checkbox" name="checkboxGroupForEquipment" id={equipment} required
                                                                onClick={() => handleRentalEquipment(equipment)} />
                                                            <label className="form-check-label" htmlFor={equipment}>{equipment}</label>
                                                        </div>
                                                    ))
                                                }
                                                {otherTypeOfRentalEquipment ?
                                                    <input type="text" className="form-control mt-2" id="otherRentalEquipment" placeholder='Other type of equipment...'
                                                    onChange={(e) => setTypeOfRentalEquipment(e.target.value)} // Update state on change 
                                                    required />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* If choose Jewelry */}
                                {typeOfNeedService === 'Jewelry' && (
                                    <div className="mt-3">
                                        <label className="form-label fs-6 mb-2">What jewelry type do you need?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                jewelryTypes.map((jewelryType) => (
                                                    <div className="form-check me-3" key={jewelryType}>
                                                        <input className="form-check-input" type="radio" name="radioGroupForJewelryType" id={jewelryType} required
                                                            onClick={() => handleJewelryType(jewelryType)} />
                                                        <label className="form-check-label" htmlFor={jewelryType}>{jewelryType}</label>
                                                    </div>
                                                ))
                                            }
                                            {otherTypeOfJewelry ?
                                                <input type="text" className="form-control mt-2" id="otherJewelry" placeholder='Other type of jewelry...'
                                                onChange={(e) => setTypeOfJewelry(e.target.value)} // Update state on change 
                                                required />
                                                : ''
                                            }
                                        </div>
                                    </div>
                                )}

                                {/* If choose Wedding/Event Dresses & Attire */}
                                {typeOfNeedService === 'Wedding/Event Dresses & Attire' && (
                                    <div className="mt-3">
                                        <label className="form-label fs-6 mb-2">Who are the clientele that need this product?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                clienteleForDresses.map((clientele) => (
                                                    <div className="form-check me-3" key={clientele}>
                                                        <input className="form-check-input" type="checkbox" name="checkboxGroupForClientele" id={clientele} required
                                                            onClick={() => handleClienteleForDresses(clientele)} />
                                                        <label className="form-check-label" htmlFor={clientele}>{clientele}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What accessories do you need?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    accessoriesForDresses.map((accessory) => (
                                                        <div className="form-check me-3" key={accessory}>
                                                            <input className="form-check-input" type="checkbox" name="checkboxGroupForAccessories" id={accessory} required
                                                                onClick={() => handleAccessoriesForDresses(accessory)} />
                                                            <label className="form-check-label" htmlFor={accessory}>{accessory}</label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What dress and attire service do you need?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    dressAndAttireServices.map((service) => (
                                                        <div className="form-check me-3" key={service}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForDressService" id={service} required
                                                                onClick={() => handleDressAndAttireServices(service)} />
                                                            <label className="form-check-label" htmlFor={service}>{service}</label>
                                                        </div>
                                                    ))
                                                }
                                                {otherTypeOfDressAndAttireServices ?
                                                    <input type="text" className="form-control mt-2" id="otherDressService" placeholder='Other dress and attire service...'
                                                    onChange={(e) => setTypeOfDressAndAttireServices(e.target.value)} // Update state on change 
                                                    required />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* If choose Wedding Officiants */}
                                {typeOfNeedService === 'Wedding Officiant' && (
                                    <div className="mt-3">
                                        <label className="form-label fs-6 mb-2">What is your budget for this service?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                weddingOfficiantBudget.map((budget) => (
                                                    <div className="form-check me-3" key={budget}>
                                                        <input className="form-check-input" type="radio" name="radioGroupForOfficiantBudget" id={budget} required
                                                            onClick={() => handleWeddingOfficiantBudget(budget)} />
                                                        <label className="form-check-label" htmlFor={budget}>{budget}</label>
                                                    </div>
                                                ))
                                            }
                                            {otherTypeOfWeddingOfficiantBudget ?
                                                <input type="text" className="form-control mt-2" id="otherOfficiantService" placeholder='Other officiant budget...'
                                                onChange={(e) => setTypeOfWeddingOfficiantBudget(e.target.value)} // Update state on change
                                                required />
                                                : ''
                                            }
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">Do you need premarital counseling for your wedding?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    premaritalCounseling.map((option) => (
                                                        <div className="form-check me-3" key={option}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForPremaritalCounseling" id={option} required
                                                                onClick={() => handlePremaritalCounseling(option)} />
                                                            <label className="form-check-label" htmlFor={option}>{option}</label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What type of wedding officiant service do you need?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    officiantServiceTypes.map((service) => (
                                                        <div className="form-check me-3" key={service}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForOfficiantService" id={service} required
                                                                onClick={() => handleOfficiantService(service)} />
                                                            <label className="form-check-label" htmlFor={service}>{service}</label>
                                                        </div>
                                                    ))
                                                }
                                                {otherTypeOfOfficiantService ?
                                                    <input type="text" className="form-control mt-2" id="otherOfficiantService" placeholder='Other officiant service...'
                                                    onChange={(e) => setTypeOfOfficiantService(e.target.value)} // Update state on change 
                                                    required />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What religion do you need for your wedding?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    weddingReligions.map((religion) => (
                                                        <div className="form-check me-3" key={religion}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForReligion" id={religion} required
                                                                onClick={() => handleWeddingReligion(religion)} />
                                                            <label className="form-check-label" htmlFor={religion}>{religion}</label>
                                                        </div>
                                                    ))
                                                }
                                                {otherTypeOfWeddingReligion ?
                                                    <input type="text" className="form-control mt-2" id="otherWeddingReligion" placeholder='Other religion...'
                                                    onChange={(e) => setTypeOfWeddingReligion(e.target.value)} // Update state on change 
                                                    required />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* If choose Hair & Makeup */}
                                {typeOfNeedService === 'Hair & Makeup' && (
                                    <div className="mt-3">
                                        <label className="form-label fs-6 mb-2">What hair services do you need?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                hairServices.map((service) => (
                                                    <div className="form-check me-3" key={service}>
                                                        <input className="form-check-input" type="radio" name="radioGroupForHairService" id={service} required
                                                            onClick={() => handleHairService(service)} />
                                                        <label className="form-check-label" htmlFor={service}>{service}</label>
                                                    </div>
                                                ))
                                            }
                                            {otherTypeOfHairService ?
                                                <input type="text" className="form-control mt-2" id="otherHairService" placeholder='Other hair service...'
                                                onChange={(e) => setTypeOfHairService(e.target.value)} // Update state on change 
                                                required />
                                                : ''
                                            }
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What makeup services do you need?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    makeupServices.map((service) => (
                                                        <div className="form-check me-3" key={service}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForMakeupService" id={service} required
                                                                onClick={() => handleMakeupService(service)} />
                                                            <label className="form-check-label" htmlFor={service}>{service}</label>
                                                        </div>
                                                    ))
                                                }
                                                {otherTypeOfMakeupService ?
                                                    <input type="text" className="form-control mt-2" id="otherMakeupService" placeholder='Other makeup service...'
                                                    onChange={(e) => setTypeOfMakeupService(e.target.value)} // Update state on change 
                                                    required />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What beauty services do you need?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    beautyServices.map((service) => (
                                                        <div className="form-check me-3" key={service}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForBeautyService" id={service} required
                                                                onClick={() => handleBeautyService(service)} />
                                                            <label className="form-check-label" htmlFor={service}>{service}</label>
                                                        </div>
                                                    ))
                                                }
                                                {otherTypeOfBeautyService ?
                                                    <input type="text" className="form-control mt-2" id="otherBeautyService" placeholder='Other beauty service...'
                                                    onChange={(e) => setTypeOfBeautyService(e.target.value)} // Update state on change 
                                                    required />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* If choose Ceremony Music */}
                                {typeOfNeedService === 'Ceremony Music' && (
                                    <div className="mt-3">
                                        <label className="form-label fs-6 mb-2">What is your budget for this service?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                ceremonyMusicBudget.map((budget) => (
                                                    <div className="form-check me-3" key={budget}>
                                                        <input className="form-check-input" type="radio" name="radioGroupForCeremonyMusicBudget" id={budget} required
                                                            onClick={() => handleCeremonyMusicBudget(budget)} />
                                                        <label className="form-check-label" htmlFor={budget}>{budget}</label>
                                                    </div>
                                                ))
                                            }
                                            {otherTypeOfMusicalServiceBudget ?
                                                <input type="text" className="form-control mt-2" id="otherMusicalBudget" placeholder='Other musical budget...'
                                                onChange={(e) => setTypeOfMusicalServiceBudget(e.target.value)} // Update state on change 
                                                required />
                                                : ''
                                            }
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What is your musical service arrangement?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    musicalServiceArrangements.map((arrangement) => (
                                                        <div className="form-check me-3" key={arrangement}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForMusicalArrangement" id={arrangement} required
                                                                onClick={() => handleMusicalServiceArrangement(arrangement)} />
                                                            <label className="form-check-label" htmlFor={arrangement}>{arrangement}</label>
                                                        </div>
                                                    ))
                                                }
                                                {otherTypeOfMusicalServiceArrangement ?
                                                    <input type="text" className="form-control mt-2" id="otherMusicalArrangement" placeholder='Other musical arrangement...'
                                                    onChange={(e) => setTypeOfMusicalServiceArrangement(e.target.value)} // Update state on change 
                                                    required />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What musical instrument do you need for this service?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    musicalInstruments.map((instrument) => (
                                                        <div className="form-check me-3" key={instrument}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForMusicalInstrument" id={instrument} required
                                                                onClick={() => handleMusicalInstrument(instrument)} />
                                                            <label className="form-check-label" htmlFor={instrument}>{instrument}</label>
                                                        </div>
                                                    ))
                                                }
                                                {otherTypeOfMusicalInstrument ?
                                                    <input type="text" className="form-control mt-2" id="otherMusicalInstrument" placeholder='Other musical instrument...'
                                                    onChange={(e) => setTypeOfMusicalInstrument(e.target.value)} // Update state on change 
                                                    required />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* If choose DJ, Bands and Dance */}
                                {typeOfNeedService === 'DJ, Bands and Dance' && (
                                    <div className="mt-3">
                                        <label className="form-label fs-6 mb-2">What is your budget for this service?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                djBudget.map((budget) => (
                                                    <div className="form-check me-3" key={budget}>
                                                        <input className="form-check-input" type="radio" name="radioGroupForDJBudget" id={budget} required
                                                            onClick={() => handleMusicBudget(budget)} />
                                                        <label className="form-check-label" htmlFor={budget}>{budget}</label>
                                                    </div>
                                                ))
                                            }
                                            {otherTypeOfMusicBudget ?
                                                <input type="text" className="form-control mt-2" id="otherMusicBudget" placeholder='Other music budget...'
                                                onChange={(e) => setTypeOfMusicBudget(e.target.value)} // Update state on change 
                                                required />
                                                : ''
                                            }
                                        </div>
                                        <div className="mt-3">
                                            <label className="form-label fs-6 mb-2">What music genre do you need?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    musicGenres.map((genre) => (
                                                        <div className="form-check me-3" key={genre}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForMusicGenre" id={genre} required
                                                                onClick={() => handleMusicGenre(genre)} />
                                                            <label className="form-check-label" htmlFor={genre}>{genre}</label>
                                                        </div>
                                                    ))
                                                }
                                                {otherTypeOfMusicGenre ?
                                                    <input type="text" className="form-control mt-2" id="otherMusicGenre" placeholder='Other music genre...'
                                                    onChange={(e) => setTypeOfMusicGenre(e.target.value)} // Update state on change 
                                                    required />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* If choose Wedding Invitation */}
                                {typeOfNeedService === 'Wedding Invitation' && (
                                    <div className="mt-3">
                                        <label className="form-label fs-6 mb-2">What invitations service do you need?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                invitationServices.map((service) => (
                                                    <div className="form-check me-3" key={service}>
                                                        <input className="form-check-input" type="checkbox" name="checkboxGroupForInvitationService" id={service} required
                                                            onClick={() => handleInvitationService(service)} />
                                                        <label className="form-check-label" htmlFor={service}>{service}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {otherTypeOfInvitationService ?
                                            <input type="text" className="form-control mt-2" id="otherInvitationService" placeholder='Other invitations service...'
                                            onChange={(e) => setTypeOfInvitationService(e.target.value)} // Update state on change 
                                            required />
                                            : ''
                                        }
                                    </div>
                                )}

                                {/* If choose Transportation */}
                                {typeOfNeedService === 'Transportation' && (
                                    <div className="mt-3">
                                        <label className="form-label fs-6 mb-2">What vehicle do you need?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            {
                                                vehicleTypes.map((vehicle) => (
                                                    <div className="form-check me-3" key={vehicle}>
                                                        <input className="form-check-input" type="radio" name="radioGroupForVehicle" id={vehicle} required
                                                            onClick={() => handleVehicleType(vehicle)} />
                                                        <label className="form-check-label" htmlFor={vehicle}>{vehicle}</label>
                                                    </div>
                                                ))
                                            }
                                            {otherTypeOfVehicle ?
                                                <input type="text" className="form-control mt-2" id="otherTransportationVehicle" placeholder='Other type of vehicle...'
                                                onChange={(e) => setTypeOfVehicle(e.target.value)} // Update state on change 
                                                required />
                                                : ''
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label className="form-label fs-6 mb-2">What type of event are you planning?</label>
                                <div className="d-flex flex-wrap g-2">
                                    {
                                        PartyType.map((partyType) => (
                                            <div className="form-check me-3" key={partyType}>
                                                <input className="form-check-input" type="radio" name="radioGroupForPartyType" id={partyType} required
                                                    onClick={() => handlePartType(partyType)} />
                                                <label className="form-check-label" htmlFor={partyType}>{partyType}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    otherTypeOfParty ?
                                        <input type="text" className="form-control mt-2" id="otherParty" placeholder='Other type of party...'
                                        onChange={(e) => setTypeOfParty(e.target.value)} // Update state on change 
                                        required />
                                        : ''
                                }
                            </div>
                            {/* <div className="mb-3">
                                <label className="form-label fs-6 mb-2">Select the event location?</label>
                                <div className="d-flex flex-wrap g-2">
                                    {
                                        LocationForEvent.map((event) => (
                                            <div className="form-check me-3" key={event}>
                                                <input className="form-check-input" type="radio" name="radioGroupForEventLocation" id={event} required
                                                    onClick={() => handleEventLocation(event)} />
                                                <label className="form-check-label" htmlFor={event}>{event}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    otherEventLocation ?
                                        <input type="text" className="form-control mt-2" id="otherEventLocation" placeholder='Other event location...' value={otherEventLocationValue} // Bind the state variable
                                        onChange={(e) => setOtherEventLocationValue(e.target.value)} // Update state on change 
                                        required />
                                        : ''
                                }
                            </div> */}
                            <div className="mb-3">
                                <label htmlFor="chooseBringingFood" className="form-label fs-6 mb-2">Will you be bringing outside food?</label>
                                <div className="d-flex flex-wrap g-2">
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForBringingFood" id='bringingFoodYes' required
                                            onClick={() => setBringFoodStatus('Yes')} />
                                        <label className="form-check-label" htmlFor='bringingFoodYes'>Yes</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForBringingFood" id='bringingFoodNo' required
                                            onClick={() => setBringFoodStatus('No')} />
                                        <label className="form-check-label" htmlFor='bringingFoodNo'>No</label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mb-3">
                                <label className="form-label fs-6 mb-2">Select your service</label>
                                <div className="d-flex flex-wrap g-2">
                                    {
                                        ServiceType.map((service) => (
                                            <div className="form-check me-3" key={service}>
                                                <input className="form-check-input" type="checkbox" name="checkGroupService" id={service} required
                                                    onChange={() => handleService(service)} />
                                                <label className="form-check-label" htmlFor={service}>{service}</label>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div> */}
                            {/* <div className="mb-3">
                                <label className="form-label fs-6 mb-2">Select your equipment needed (optional)</label>
                                <div className="d-flex flex-wrap g-2">
                                    {
                                        EquipmentNeeded.map((equipment) => (
                                            <div className="form-check me-3" key={equipment}>
                                                <input className="form-check-input" type="checkbox" name="checkGroupEquipment" id={equipment} required
                                                    onChange={() => handleEquipment(equipment)} />
                                                <label className="form-check-label" htmlFor={equipment}>{equipment}</label>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div> */}
                            <div className="mb-3">
                                <div className="form-label fs-6 mb-2">Numbers of guest attending</div>
                                <div className="d-flex flex-wrap g-2">
                                    {
                                        GuestNumber.map((guest) => (
                                            <div className="form-check me-3" key={guest}>
                                                <input className="form-check-input" type="radio" name="radioGroupFoeGuest" id={guest} required
                                                    onClick={() => handleGuest(guest)} />
                                                <label className="form-check-label" htmlFor={guest}>{guest}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    otherGuest ?
                                        <input type="text" className="form-control mt-2" id="otherGuest" placeholder='Other...' required
                                            onChange={(e) => setGuest(e.target.value)} /> : ''
                                }

                            </div>
                            <div className="mb-3">
                                <label htmlFor="eventVenueLocation" className="form-label fs-6 mb-2">Where is the event venue location?</label>
                                <Autocomplete
                                    className="form-control"
                                    placeholder='Please enter event venue address...'
                                    onPlaceSelected={(e) => handleLocationSelection(e)}
                                    options={{
                                        fields: ["formatted_address", "geometry", "name"],
                                        strictBounds: false,
                                        types: [],
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                                <div className="d-flex flex-wrap g-2">
                                    {
                                        ContactWay.map((cntct) => (
                                            <div className="form-check me-3" key={cntct}>
                                                <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                                                    onClick={() => handleContactMode(cntct)} />
                                                <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    otherContactMode ?
                                        <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                                            onChange={(e) => setContactMode(e.target.value)} />
                                        : ''
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="functionDate" className="form-label mb-2">When do you need the service?</label>
                                <div className='date-picker-wrapper'>
                                    <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                                        onChange={(value) => setAppointDate(value)} minDate={new Date()}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 mb-3">
                                    <label htmlFor="startingTime" className="form-label fs-6 mb-2">Select starting time</label>
                                    <input type="time" id="startingTime" className="form-control" min="09:00" max="18:00" required
                                        onChange={(e) => handleTimeChange(e, 'start')} />
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="closingTime" className="form-label fs-6 mb-2">Select Closing time</label>
                                    <input type="time" id="closingTime" className="form-control" min="09:00" max="18:00" required
                                        onChange={(e) => handleTimeChange(e, 'close')} />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="otherInfo" className="form-label mb-2">Any other information</label>
                                <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your other info...' required
                                    onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                            </div>
                            <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com </div>
                            <div className="btn-wrapper mt-2">
                                <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Next</button>
                            </div>
                            {
                                props.loading ?
                                    <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                                        style={{ zIndex: 1030 }}>
                                        <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                                    </div>
                                    : ''
                            }
                        </div>
                    ) :
                    (<UserInfoForQuoteService state={serviceData} />)
            }
        </>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(Entertainment))
