import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'

export const CouponrankingForBusinesses = () => {
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [])
    return (
        <div className="cr_for_businesses mt-4 mb-5">
            {/* title head */}
            <Helmet>
                <title>Couponranking for Business</title>
            </Helmet>
            <div className='container'>
                <div className='section-header text-center mb-4 pb-lg-2' style={{ maxWidth: '1000px' }}>
                    <div className='section-label fs-3 mb-2'>The Best Platform To Connect Businesses With Customers</div>
                    <div className='section-sublabel text-dark text-opacity-75'>Welcome to Couponranking, where connecting businesses to customers is our specialty! With a mission to make business easier for both small and large companies. Couponranking is here to provide seamless solutions that streamline customer acquisition. We believe that businesses of all sizes deserve the chance to thrive and we’re committed to making that happen.</div>
                </div>

                {/* Handyman section */}
                <div className="handyman_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/handyman.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Handyman' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">Request A Quote</div>
                                <ul className='m-0 ps-3'>
                                    <li>Hassle free process</li>
                                    <li>Increase conversion</li>
                                    <li>Tailored solutions</li>
                                    <li>Personalized quote</li>
                                    <li>Improve communication</li>
                                    <li>Customized pricing</li>
                                    <li>Customized process</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Beauty Selon section */}
                <div className="beauty_selon_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row g-0 flex-column-reverse flex-md-row">
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">Book Appointment</div>
                                <ul className='m-0 ps-3'>
                                    <li>Boost your efficiency</li>
                                    <li>Enhanced customer experience</li>
                                    <li>Customizable options</li>
                                    <li>More repeat customers</li>
                                    <li>Convenient Scheduling</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/beauty-salon.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Beauty Salon' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Taxi Picture section */}
                <div className="taxi_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/taxi.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Taxi' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="heading-wrapper mb-2">
                                    <div className="fs-5 fw-semibold">Free Page</div>
                                    <div className='section-sublabel text-dark text-opacity-75'>It’s free to be on Couponranking</div>
                                </div>
                                <ul className='m-0 ps-3'>
                                    <li>Update your business information</li>
                                    <li>Hassle free and cost effective</li>
                                    <li>Boost your business</li>
                                    <li>Easy to use</li>
                                    <li>Receive and respond to messages</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Two Picture section */}
                <div className="two_picture_sec my-md-3">
                    <div className="row gx-3">
                        <div className="col-md-6 mb-3 mb-md-0">
                            <div className="details-cover bg-primary bg-opacity-10 h-100">
                                <div className="img-wrapper ratio ratio-16x9">
                                    <img src={require('../assets/restaurants.jpg')} className='w-100 img-fluid object-fit-cover' alt='Restaurants' />
                                </div>
                                <div className="info-wrapper p-4 p-md-5">
                                    <div className="fs-5 fw-semibold">Coupon Program</div>
                                    <ul className='m-0 ps-3'>
                                        <li>Increase customer base</li>
                                        <li>Increase customer traffic</li>
                                        <li>Encourage customer loyalty</li>
                                        <li>Attract more customers</li>
                                        <li>Better brand awareness</li>
                                        <li>Boost sales</li>
                                        <li>Build brand loyalty</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3 mb-md-0">
                            <div className="details-cover bg-primary bg-opacity-10 h-100">
                                <div className="img-wrapper ratio ratio-16x9">
                                    <img src={require('../assets/coffee-shop.jpg')} className='w-100 img-fluid object-fit-cover' alt='Coffee Shop' />
                                </div>
                                <div className="info-wrapper p-4 p-md-5">
                                    <div className="fs-5 fw-semibold lh-sm mb-2">Reward Program</div>
                                    <ul className='m-0 ps-3'>
                                        <li>Attract new customers</li>
                                        <li>Analyze customer loyalty</li>
                                        <li>Boost customer loyalty</li>
                                        <li>Increase revenue</li>
                                        <li>Customizable rewards</li>
                                        <li>Cost effective</li>
                                        <li>Easy to implement</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Redeem Coupon section */}
                <div className="redeem_coupon_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/auto-detailing.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Auto Detailing' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">How to redeem the coupon code</div>
                                <ul className='m-0 ps-3'>
                                    <li>Customer will present the promo code</li>
                                    <li>Login & click admin on couponranking website</li>
                                    <li>Select coupon & rewards program</li>
                                    <li>Look for customer information</li>
                                    <li>Click on redeem button</li>
                                    <li>Give the customer applicable discount</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Redeem Reward section */}
                <div className="redeem_reward_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row flex-column-reverse flex-md-row g-0">
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">How to redeem reward</div>
                                <ul className='m-0 ps-3'>
                                    <li>Cistomrt will present the reward program to cashier at your business location</li>
                                    <li>Login and click admin on Couponranking website</li>
                                    <li>Select coupon and reward program</li>
                                    <li>Look for customer information</li>
                                    <li>Click on earned button for customer to earn a point or star</li>
                                    <li>Each visit earn you a star or point</li>
                                    <li>5 customer visits earn you 5 stars or points</li>
                                    <li>The sixth visit, customer redeem is rewards.</li>
                                    <li>Give the customer applicable discount</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/shoe-boutique.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Shoe Boutique' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* E-Commerce section */}
                <div className="ecommerce_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/clothing-boutique.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Clothing Boutique' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">E-commerce (online services)</div>
                                <ul className='m-0 ps-3'>
                                    <li>Increase your reach</li>
                                    <li>Simplify the sales process</li>
                                    <li>Cost effective</li>
                                    <li>Boost your sales</li>
                                    <li>Analytics and insights</li>
                                    <li>Increased revenue</li>
                                    <li>Cost savings</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Comment & Rating section */}
                <div className="cmnt_rating_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row flex-column-reverse flex-md-row g-0">
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">Comment & Rating</div>
                                <ul className='m-0 ps-3'>
                                    <li>Encourage customer feedback</li>
                                    <li>Increase brand credibility</li>
                                    <li>Drive sales</li>
                                    <li>Gain valuable insights</li>
                                    <li>Boost credibility and trust</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/wedding.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Wedding Picture' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Video Business section */}
                <div className="ecommerce_sec bg-primary bg-opacity-10">
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="video-wrapper ratio ratio-16x9">
                                <video autoPlay controls id="video1" className='w-100 object-fit-cover' muted>
                                    <source src="https://advertisement-videos.s3.us-west-2.amazonaws.com/1706633390103-02.mp4" type="video/mp4" />
                                    <source src="https://advertisement-videos.s3.us-west-2.amazonaws.com/1706633390103-02.mp4" type="video/ogg" />
                                    Your browser does not support HTML video.
                                </video>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <p className='m-0'>Couponranking makes it easier to connect with new customers and grow your business</p>
                                <div className="btn-wrapper text-start mt-4">
                                    <Link to={'/business-register'} className="btn btn-primary d-inline-flex align-items-center justify-content-center">Join couponranking today <i className="bi bi-arrow-right ms-1"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
