import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';

const Login = (props) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const handleChange = (type, e) => {
        if (type === 'email') {
            setEmail(e.target.value)
        } else if (type === 'password') {
            setPassword(e.target.value)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (email !== '' && password !== '') {
            let data = { email, password }
            props.login(data,navigate)
        }

    }

    const passwordVisibity = () => {
        if (showPassword) {
            setShowPassword(false)
        } else {
            setShowPassword(true)
        }
    }
    const changePage = (type) => {
        if(type==='user'){
            navigate('/user-register')
        }else{
            navigate('/business-register')
        }
        
    }


    return (
        <div className='auth-page d-flex align-items-center py-5'>
            {/* title head */}
            <Helmet>
                <title>CouponRanking | login</title>
            </Helmet>
            <div className='container'>
                <div className='border border-1 rounded-3 overflow-hidden shadow mx-auto' style={{ maxWidth: '1000px' }}>
                    <div className='row g-0'>
                        <div className='col-md-5'>
                            <div className='left-section section-bg-overlay d-flex flex-column justify-content-center bg-primary bg-opacity-50 text-white h-100 px-4 py-5'>
                                <div className='cntnt-wrapper position-relative z-1'>
                                    {/* <Link to={'/'} className='d-block logo-title text-uppercase small fw-bold mb-3'>
                                        <img className='img-fluid rounded-circle' src={require('../assets/logo.png')} alt='Logo' style={{ width: '100px' }} />
                                    </Link> */}
                                    <div className='heading-title fs-2 fw-bold lh-sm mb-3'>Start Your<br /> Journey With Us.</div>
                                    <div className='heading-subtitle mb-3'>Make Your Money Go Further with the Latest Deals and Coupons</div>
                                    <div className='img-wrapper text-center d-none d-md-block'>
                                        <img className='img-fluid rounded-circle' src={require('../assets/hero-banner.jpg')} alt='Logo' style={{ width: '300px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className="right-section d-flex align-items-center bg-white h-100 px-3 px-sm-5 py-5">
                                <form className='w-100'>
                                    <div className='user-img text-center mb-4 pb-2'>
                                        <img src={require('../assets/Avatar.png')} alt='User Pic' className='img-fluid border border-2 border-primary rounded-circle' style={{ width: '120px', height: '120px' }} />
                                    </div>

                                    {/* Start: Alert message */}
                                    {props.error ?
                                        <div className="d-flex align-items-center border border-danger text-danger rounded-1 shadow-sm px-3 py-2 mb-3" role="alert">
                                            <i className="bi bi-exclamation-triangle-fill fs-18"></i>
                                            <div className='ms-2 lh-sm'>
                                                {props.error}
                                            </div>
                                        </div> : ''
                                    }
                                    {/* End: Alert message */}

                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Your Email</label>
                                        <input type="email" className="form-control" id="email" placeholder='Enter email' required
                                            name='email'
                                            value={email}
                                            onChange={(e) => handleChange('email', e)}
                                            autoComplete="new-password"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <div className="input-group mb-3">
                                            <input type={showPassword ? "text" : "password"} className="form-control" id="password" placeholder='Enter password' required
                                                name='password'
                                                value={password}
                                                onChange={(e) => handleChange('password', e)}
                                                style={{ borderRight: 0 }}
                                                autoComplete="new-password"
                                            />
                                            <span className="input-group-text bg-transparent" id="basic-addon2" onClick={() => passwordVisibity()}>{showPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}</span>
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-between gap-2 mb-4">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label" htmlFor="exampleCheck1">Remember Me</label>
                                        </div>

                                        <Link to={'/forgot-password'} className="btn-link text-decoration-none" htmlFor="exampleCheck1">Forgot Password?</Link>
                                    </div>
                                    <div className="w-100 mb-3">
                                        <button type="submit" className="w-100 btn btn-primary" onClick={(event) => handleSubmit(event)}>Login</button>
                                    </div>
                                    <div className='text-center'>Not registered?
                                        <Link className='ms-2 btn-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#registerModal">Create an account</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Registration Model Popup */}

            <div className="modal fade" id="registerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header px-sm-4">
                            <h1 className="modal-title fs-5" id="registerModalLabel">User & Business Registration</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 px-sm-4 py-4">
                            <div className='dropdown-wrapper'>
                                <div className='user-info text-center mb-4 pb-2'>
                                    <h6 className='fw-semibold mb-1'>Hello <span>User</span></h6>
                                    <p className='small text-muted m-0'>Register yourself as per your need!</p>
                                </div>
                                <div className='w-100 mb-3'>
                                    <button className="w-100 btn btn-success d-flex align-items-center justify-content-center" data-bs-dismiss="modal" onClick={() => changePage('user')}>User (customers) sign up</button>
                                </div>
                                <div className='mb-3'>
                                    <button className="w-100 btn btn-danger d-flex align-items-center justify-content-center" data-bs-dismiss="modal" onClick={() => changePage('business')}>Business owner sign up</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (data,navigate) => dispatch(actions.login(data,navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(Login))