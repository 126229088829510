import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import 'swiper/css/pagination';
import ReactPaginate from 'react-paginate';
import ReactLoading from 'react-loading';

const BlogList = (props) => {
    const { blogList, getBlogList } = props;
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        getBlogList()
    }, [getBlogList])

    const navigate = useNavigate()
    // pagination functionality start
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + 9;  //number here represent how many item you want to show on page
    const currentItems = blogList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(blogList.length / 9);
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * 9) % blogList.length;
        setItemOffset(newOffset);
    };
    //pagination functionality end
    const handleBlogDetails = (blog) => {
        let blogTitle = blog.blog_title.replaceAll(/ /g, "-").toLowerCase();
        let blogDetailsUrl = `/blog-details/${blogTitle}`
        navigate(blogDetailsUrl, { state: { blogId: blog._id } })
    }

    return (
        <div>
            <Helmet>
                <title>Couporanking | Blogs</title>
            </Helmet>
            <div className='container mt-2'>
                <div className='row g-3 mb-2'>
                    {currentItems.map((blog, index) => (
                        <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6' key={index}>
                            <div className='business-details border rounded-3 overflow-hidden h-100' key={index}>
                                <div className='blog-details border rounded-3 overflow-hidden' key={blog._id}>
                                    <div className='blog-img'>
                                        <img src={blog.blog_picture} className='img-fluid w-100' alt='Cleaning' />
                                    </div>
                                    <div className="blog-info bg-white p-3 pb-4">
                                        <div className='title fs-18 fw-semibold text-capitalize mb-1'>{blog.name}</div>
                                        <div className='description text-dark text-opacity-75 mb-1'>{blog.blog_title}</div>
                                        <div className='date text-muted'>{new Date(blog.createdAt).toDateString()}</div>
                                        <button className='btn btn-primary mt-2' onClick={() => handleBlogDetails(blog)}>View Details</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* pagination section */}
                    <div className='pagination-wrapper pt-3'>
                        <ReactPaginate
                            className="pagination justify-content-center m-1"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            nextLabel="&raquo;"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="&laquo;"
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            activeClassName="active"
                        />
                    </div>
                </div>
                {/* loading section */}
                {props.loading ?
                    <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                        style={{ zIndex: 1030 }}>
                        <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                    </div>
                    : ''}
            </div>
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        blogList: state.business.bloglist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBlogList: () => dispatch(actions.getBlogList()),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(BlogList))