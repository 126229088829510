import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startCouponRatingAction = () => {
    return {
        type: actionTypes.START_REWARD_ACTION
    };
};

export const couponRewardActionFail = message => {
    return {
        type: actionTypes.REWARD_ACTION_FAIL,
        message
    };
};
export const couponCreationSuccess = (details, message) => {
    return {
        type: actionTypes.CREATE_COUPON_REWARD_SUCCESS,
        details: details,
        message: message
    };
};

export const createAndGetCoupon = (data) => {
    return dispatch => {
        dispatch(startCouponRatingAction());
        const authCode = "Bearer " + localStorage.getItem("token");
        axios
            .post("/couponranking/create-coupon", data, {
                headers: { Authorization: authCode }
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        dispatch(couponCreationSuccess(response.data.data, response.data.message));
                    } else {
                        dispatch(couponRewardActionFail(response.data.message));
                    }
                } else {
                    dispatch(couponRewardActionFail(response.message));
                }
            })
            .catch(err => {
                dispatch(couponRewardActionFail(err.message));
            });
    };
};

export const getRewardUserlistSuccess = (listing, message) => {
    return {
        type: actionTypes.FETCH_REWARD_USER_LIST_SUCCESS,
        listing: listing,
        message: message
    };
};

export const getRewardAndCouponUserList = (data) => {
    return dispatch => {
        dispatch(startCouponRatingAction());
        const authCode = "Bearer " + localStorage.getItem("token");
        axios
            .post("/couponranking/get-reward-customer-list", data, {
                headers: { Authorization: authCode }
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        dispatch(getRewardUserlistSuccess(response.data.data, null));
                    } else {
                        dispatch(couponRewardActionFail(response.data.message));
                    }
                } else {
                    dispatch(couponRewardActionFail(response.message));
                }
            })
            .catch(err => {
                dispatch(couponRewardActionFail(err.message));
            });
    };
};

export const updateEarnedStar = (data) => {
    return dispatch => {
        dispatch(startCouponRatingAction());
        const authCode = "Bearer " + localStorage.getItem("token");
        axios
            .post("/couponranking/update-reward-star", data, {
                headers: { Authorization: authCode }
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        dispatch(getRewardUserlistSuccess(response.data.data, response.data.message));
                    } else {
                        dispatch(couponRewardActionFail(response.data.message));
                    }
                } else {
                    dispatch(couponRewardActionFail(response.message));
                }
            })
            .catch(err => {
                dispatch(couponRewardActionFail(err.message));
            });
    };
};

export const redeemRewardAndCoupon = (data) => {
    return dispatch => {
        dispatch(startCouponRatingAction());
        const authCode = "Bearer " + localStorage.getItem("token");
        axios
            .post("/couponranking/redeem-rewards-and-coupon", data, {
                headers: { Authorization: authCode }
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        dispatch(getRewardUserlistSuccess(response.data.data, response.data.message));
                    } else {
                        dispatch(couponRewardActionFail(response.data.message));
                    }
                } else {
                    dispatch(couponRewardActionFail(response.message));
                }
            })
            .catch(err => {
                dispatch(couponRewardActionFail(err.message));
            });
    };
};





