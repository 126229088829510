import React from 'react'
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

const WhoWeAre = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);
    return (
        <div className="whoweare_page py-3 py-md-4">
            {/* title head */}
            <Helmet>
                <title>Who we are | Couponranking</title>
            </Helmet>
            <div className="container">
                <div className='bg-light p-3 p-md-4'>
                    <div className="section-heading fs-4 fw-bold mb-3">Who we are</div>
                    <p>
                        CouponRanking is an intermediary platform designed to streamline the process for customers who are seeking specific services or products from businesses. By offering features such as "request a quote" and "book appointment" the company facilitates a direct connection between consumers and companies. The company not only aids consumers in obtaining the best deals or understanding service costs in advance but also helps businesses by generating potential leads and appointments.
                        CouponRanking is utilizing a business model where the company earns revenue through commission fees and advertising fees paid by the businesses listed on their site.
                    </p>
                    <p className='fw-semibold'>For Customers, the platform would provide:</p>
                    <ul className='ps-3'>
                        <li>Convenience in finding service providers for specific needs without having to search through multiple websites or directories.</li>
                        <li>The ability to compare prices and services from various providers, enhancing their decision-making process.</li>
                        <li>A streamlined scheduling experience to reduce the hassle of back-and-forth communication when setting appointments.</li>
                    </ul>
                    <p className='fw-semibold'>For Businesses, the benefits might include:</p>
                    <ul className='ps-3'>
                        <li>Increased visibility to potential customers actively seeking the services or goods they offer.</li>
                        <li>A more efficient way of receiving and managing quote requests and appointments, potentially leading to higher conversion rates.</li>
                        <li>Insights into customer needs and preferences based on the data gathered from quote requests and appointment bookings.</li>
                    </ul>
                    <p>
                        CouponRanking significantly contribute to market efficiency by enabling customers to compare services, prices, and availability all in one place. This not only empowers consumers in their decision-making process but also stimulates competition among businesses, leading to better services and more competitive pricing.
                        It’s also worth noting that the effectiveness of such a platform hinges on a broad and varied selection of businesses across multiple sectors, a user-friendly interface, and reliable customer service. Trustworthiness and credibility of the listed businesses and the reviews or ratings provided on the platform can further enhance its value to users.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default WhoWeAre