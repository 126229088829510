import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
// import searchIcon from '../assets/search.svg';
import  SearchAutocomplete  from './SearchAutocomplete';

export const Header = () => {
    // Start: Mobile sidebar offcanvas code
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    const openOffcanvas = () => {
        setIsOffcanvasOpen(true);
    };

    const closeOffcanvas = () => {
        setIsOffcanvasOpen(false);
    };

    const handleDocumentClick = (e) => {
        // Check if the click event target is not the Offcanvas or its trigger button
        if (
            isOffcanvasOpen &&
            !document.getElementById("offcanvasElement").contains(e.target) &&
            !document.getElementById("offcanvasButton").contains(e.target)
        ) {
            closeOffcanvas();
        }
    };

    // Attach the click event listener to the document
    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [isOffcanvasOpen]);

    // End: Mobile sidebar offcanvas code

    const token = localStorage.getItem('token');
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        window.location.replace('/')
    }
    let city = localStorage.getItem('city');
    let state = localStorage.getItem('state');

    let nerabyUrl = `/nearby-business/top-10-best-nearby-businesses-${city && state ? `${city}-${state}` : 'near me'}`
    let couponRewardUrl = `/coupon-and-reward-program/top-20-best-coupons-and-rewards_${city && state ? `in-${city}-${state}` : 'near me'}`
    let storeFrontUrl = `/store-front-marketplace/top-10-best-store-front-businesses-${city && state ? `in-${city}-${state}` : 'near me'}`
    return (
        <header className='main-header bg-white sticky-top border-bottom'>
            <div className="bg-primary topbar text-white py-2 d-none d-lg-block">
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='col-lg-8'>
                            {token && userInfo.user_type === 'business_user' ?
                                <div className='info-sec d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start gap-2 gap-sm-4 fw-medium lh-1 mb-2 mb-lg-0'>
                                    <div className='contact lh-sm'><i className="bi bi-phone"></i> (217) 419-0067</div>
                                    <div className='email lh-sm'><i className="bi bi-envelope"></i> admin@couponranking.com</div>
                                </div>
                                : ''
                            }
                            <div className="link-wrapper d-flex flex-wrap gap-3">
                                <Link onClick={closeOffcanvas} className="nav-link fw-medium" to={couponRewardUrl}>Coupon</Link>
                                <Link onClick={closeOffcanvas} className="nav-link fw-medium" to={'/deal-business'}>Deal</Link>
                                <Link onClick={closeOffcanvas} className="nav-link fw-medium" to={'/couponranking-for-business'}>Couponranking for Business</Link>
                                <Link onClick={closeOffcanvas} className="nav-link fw-medium" to={'/couponranking-for-customers'}>Couponranking for Customers</Link>
                                <Link onClick={closeOffcanvas} className="nav-link fw-medium" to={'/blogs'}>Blog</Link>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='social-sec d-flex align-items-center justify-content-center justify-content-lg-end gap-3'>
                                <Link to={"https://www.facebook.com/Couponranking"} target='_blank'><img src={require('../assets/facebook.png')} className="img-fluid" alt="Facebook" /></Link>
                                <Link to={"https://Twitter.com/Couponranking"} target='_blank'><img src={require('../assets/twitter.png')} className="img-fluid" alt="Twitter" /></Link>
                                <Link to={"https://www.instagram.com/Couponranking"} target='_blank'><img src={require('../assets/instagram.png')} className="img-fluid" alt="Instagram" /></Link>
                                <Link to={"https://www.youtube.com/channel/UC-laicUBBca5_4oQbWgVp2A"} target='_blank'><img src={require('../assets/youtube.png')} className="img-fluid" alt="Youtube" /></Link>
                                <Link to={"https://www.tiktok.com/couponrankong"} target='_blank'><img src={require('../assets/tiktok.png')} className="img-fluid" alt="Snapchat" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-lightt">
                <div className="container">
                    <div className='d-lg-none d-flex align-items-center justify-content-between gap-1 w-100'>
                        <div className='left' style={{ width: '33.333%' }}>
                            <button className="navbar-toggler" type="button" id="offcanvasButton" onClick={openOffcanvas}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>

                        <div className='middle text-center' style={{ width: '33.333%' }}>
                            <Link className="navbar-brand m-0" to={'/'}>
                                <img src={require('../assets/logo.png')} className="img-fluid rounded-circle" alt="Logo" style={{ width: 60 }} />
                            </Link>
                        </div>

                        <div className='right text-end' style={{ width: '33.333%' }}>
                            {token ?
                                <div className="nav-item dropdown py-2">
                                    <a className="nav-link px-xl-3 fs-5 fw-bold" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className='user-img text-end'>
                                            <img src={require('../assets/Avatar.png')} alt='User Pic' className='img-fluid border border-1 border-primary rounded-circle' style={{ width: '45px', minWidth: '45px', height: '45px' }} />
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end p-0" style={{ minWidth: '300px' }}>
                                        <div className='dropdown-wrapper px-3 py-4'>
                                            <div className='user-info'>
                                                <h6 className='fw-semibold mb-1'>Hello, <span>{userInfo ? userInfo.name : ""}</span></h6>
                                            </div>
                                            <li className='w-100 mb-2'>Email: {userInfo.email}</li>
                                            <li className='mb-3'>Phone: {userInfo.country_code + userInfo.mobile_no}</li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li className='mt-3'><button className="w-100 btn btn-primary d-flex align-items-center justify-content-center" onClick={() => handleLogout()}>Logout<i class="bi bi-box-arrow-right ms-2"></i></button></li>
                                        </div>
                                    </ul>
                                </div>
                                :
                                <div className="nav-item dropdown py-2">
                                    <Link className="nav-link px-xl-3 fs-5 fw-medium" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Login
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end p-0" style={{ minWidth: '300px' }}>
                                        <div className='dropdown-wrapper px-3 py-4'>
                                            <div className='user-info'>
                                                <h6 className='fw-semibold mb-1'>Hello <span>User</span></h6>
                                                <p className='small text-muted'>Don't have account yet?</p>
                                            </div>
                                            <li className='w-100 mb-2'><Link to={'/user-register'} className="w-100 btn btn-success d-flex align-items-center justify-content-center">User (customers) sign up</Link></li>
                                            <li className='mb-3'><Link to={'/business-register'} className="w-100 btn btn-danger d-flex align-items-center justify-content-center">Business owner sign up</Link></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li className='mt-3'><Link to={'/login'} className="w-100 btn btn-primary d-flex align-items-center justify-content-center">Login</Link></li>
                                        </div>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>

                    <Link className="navbar-brand d-none d-lg-block p-0" to={'/'}>
                        <img src={require('../assets/logo.png')} className="img-fluid" alt="Logo" style={{ width: 90 }} />
                    </Link>

                    {/* Start: Mobile Sidebar Drawer */}
                    <div className={`offcanvas offcanvas-start flex-grow-1 ${isOffcanvasOpen ? 'show' : ''}`} tabIndex="-1" id="offcanvasElement">
                        <div className="offcanvas-header bg-primary py-2">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                                <Link className="navbar-brand" to="#">
                                    <img src={require('../assets/logo.png')} className="img-fluid" alt="Logo" style={{ width: 60 }} />
                                </Link>
                            </h5>
                            <button type="button" className="btn-close btn-close-white" onClick={closeOffcanvas}></button>
                        </div>

                        <div className="offcanvas-body p-lg-0">
                            <div className="d-flex flex-column flex-lg-row align-items-lg-center ms-auto">
                                <ul className="navbar-nav justify-content-end align-items-lg-center flex-grow-1 order-2 order-lg-1">
                                    <li className="nav-item">
                                        <NavLink onClick={closeOffcanvas} className="nav-link active" aria-current="page" to={'/'}>Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink onClick={closeOffcanvas} className="nav-link" to={nerabyUrl}>Nearby businesses</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink onClick={closeOffcanvas} className="nav-link" to={'#'}>Latest Report</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink onClick={closeOffcanvas} className="nav-link" to={'/get-all-categories'}>Category</NavLink>
                                    </li>
                                    <li className="nav-item d-lg-none">
                                        <Link onClick={closeOffcanvas} className="nav-link" to={couponRewardUrl}>Coupon</Link>
                                    </li>
                                    <li className="nav-item d-lg-none">
                                        <Link onClick={closeOffcanvas} className="nav-link" to={'/deal-business'}>Deal</Link>
                                    </li>
                                    <li className="nav-item d-lg-none">
                                        <Link onClick={closeOffcanvas} className="nav-link" to={'/couponranking-for-business'}>Couponranking for Business</Link>
                                    </li>
                                    <li className="nav-item d-lg-none">
                                        <Link onClick={closeOffcanvas} className="nav-link" to={'/couponranking-for-customers'}>Couponranking for Customers</Link>
                                    </li>
                                    <li className="nav-item d-lg-none">
                                        <Link onClick={closeOffcanvas} className="nav-link" to={'/blogs'}>Blog</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Services
                                        </Link>
                                        <ul className="dropdown-menu" style={{ minWidth: '200px' }}>
                                            <li><Link to={storeFrontUrl} onClick={closeOffcanvas} className="dropdown-item">Store Front Marketplace</Link></li>
                                            <li><Link to={couponRewardUrl} onClick={closeOffcanvas} className="dropdown-item">Coupon & Reward Program</Link></li>
                                            <li><Link to={'/ecommerce-business'} onClick={closeOffcanvas} className="dropdown-item">E-commerce & Online services Marketplace</Link></li>
                                            <li><Link to={'/deal-business'} onClick={closeOffcanvas} className="dropdown-item">Deals</Link></li>
                                        </ul>
                                    </li>


                                    {token && userInfo.user_type === 'business_user' ?
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Admin
                                            </Link>
                                            <ul className="dropdown-menu" style={{ minWidth: '200px' }}>
                                                <li><Link to={'/pricing-plan'} onClick={closeOffcanvas} className="dropdown-item">Pricing</Link></li>
                                                <li><Link to={'/royalty-rewards'} onClick={closeOffcanvas} className="dropdown-item">Coupon & Reward Program</Link></li>
                                                <li><Link to={'/get-card-info'} onClick={closeOffcanvas} className="dropdown-item">Card Edit</Link></li>
                                                <li><Link to={'#'} onClick={closeOffcanvas} className="dropdown-item">Invoice</Link></li>
                                                <li><Link to={'#'} onClick={closeOffcanvas} className="dropdown-item">Business Details</Link></li>
                                                <li><Link to={'/rate-couponranking-by-business'} onClick={closeOffcanvas} className="dropdown-item">Couponranking comment & rating</Link></li>
                                            </ul>
                                        </li>
                                        : ''
                                    }

                                    <li className="nav-item d-lg-none d-xxl-block">
                                        <NavLink onClick={closeOffcanvas} className="nav-link" to={'#'}>Company</NavLink>
                                    </li>

                                    {token ?
                                        <li className="nav-item dropdown d-none d-lg-block">
                                            <Link className="nav-link px-xl-3 fs-5 fw-bold" to="/#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className='user-img text-center'>
                                                    <img src={require('../assets/Avatar.png')} alt='User Pic' className='img-fluid border border-1 border-primary rounded-circle' style={{ width: '45px', minWidth: '45px', height: '45px' }} />
                                                </div>
                                            </Link>
                                            <ul className="dropdown-menu dropdown-menu-end p-0" style={{ minWidth: '300px' }}>
                                                <div className='dropdown-wrapper px-3 py-4'>
                                                    <div className='user-info'>
                                                        <h6 className='fw-semibold mb-1'>Hello, <span>{userInfo ? userInfo.name : ""}</span></h6>
                                                    </div>
                                                    <li className='w-100 mb-2'>Email: {userInfo.email}</li>
                                                    <li className='mb-3'>Phone: {userInfo.country_code + userInfo.mobile_no}</li>
                                                    <li><hr className="dropdown-divider" /></li>
                                                    <li className='mt-3'><button className="w-100 btn btn-primary d-flex align-items-center justify-content-center" onClick={() => handleLogout()}>Logout<i class="bi bi-box-arrow-right ms-2"></i></button></li>
                                                </div>
                                            </ul>
                                        </li>
                                        :
                                        <li className="nav-item dropdown d-none d-lg-block">
                                            <Link className="nav-link px-xl-3" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Login
                                            </Link>
                                            <ul className="dropdown-menu dropdown-menu-end p-0" style={{ minWidth: '300px' }}>
                                                <div className='dropdown-wrapper px-3 py-4'>
                                                    <div className='user-info'>
                                                        <h6 className='fw-semibold mb-1'>Hello <span>User</span></h6>
                                                        <p className='small text-muted'>Don't have account yet?</p>
                                                    </div>
                                                    <li className='w-100 mb-2'><Link to={'/user-register'} onClick={closeOffcanvas} className="w-100 btn btn-success d-flex align-items-center justify-content-center">User (customers) sign up</Link></li>
                                                    <li className='mb-3'><Link to={'/business-register'} onClick={closeOffcanvas} className="w-100 btn btn-danger d-flex align-items-center justify-content-center">Business owner sign up</Link></li>
                                                    <li><hr className="dropdown-divider" /></li>
                                                    <li className='mt-3'><Link to={'/login'} onClick={closeOffcanvas} className="w-100 btn btn-primary d-flex align-items-center justify-content-center">Login</Link></li>
                                                </div>
                                            </ul>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    {isOffcanvasOpen && (<div className="offcanvas-backdrop show"></div>)}
                    {/* End: Mobile Sidebar Drawer */}
                </div>
            </nav>

            {/* Search Autocomplete */}
            <SearchAutocomplete />
        </header>
    )
}
