import React from 'react'
import {useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from "react-helmet";

export const ProductMenuDetails = () => {
    const { state } = useLocation()
    useEffect(() => {
        // for loading page from top after re-render from any section
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    })
    // let productDetails = [
    //     {
    //         categoryName: 'category name',
    //         productList: [
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //         ]
    //     },
    //     {
    //         categoryName: 'SRI lala',
    //         productList: [
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'gulab jamin',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'laddu',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'Peda',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'kalakand',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //         ]
    //     },
    //     {
    //         categoryName: 'Chai HUkka',
    //         productList: [
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'chai',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'biscute',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //         ]
    //     }, {
    //         categoryName: 'hello category test',
    //         productList: [
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //             {
    //                 name: 'samosa',
    //                 price: 5,
    //                 status: 'show Price'
    //             },
    //         ]
    //     }
    // ]
    let { productDetails,businessName } = state
    return (
        <div className='prdc-menu-details py-4'>
            {/* title head */}
            <Helmet>
                <title>{businessName} | Menu | Product | Service</title>
            </Helmet>
            <div className='container'>
                <div className='product-list-desc mx-auto' style={{ maxWidth: '800px' }}>
                    <h3 className='section-label mb-3'>Menu or Product List</h3>
                    {/* <div className='border bg-light'>
                        {productDetails.map((ele, index) => (
                            <div className='menu-sections p-3 p-md-4' key={ele._id}>
                                {ele.categoryName ? <h5 className='menu-category text-capitalize bg-secondary p-3 bg-opacity-25 fs-18 mb-2'>{ele.categoryName}</h5> : ''}

                                {ele.productList.slice(0, 3).map((products, i) => (
                                    <div className='list-inline menu-list' key={products._id}>
                                        {products.name !== '' ?
                                            <div className='d-flex justify-content-between gap-2 border-bottom py-2'>
                                                <div className='item-name text-capitalize'>{products.name}</div>
                                                {
                                                    products.status === "none" || products.status === 'no_price' || products.status === 'call_for_quotes' ?
                                                        ''
                                                        : <div className='item-price'>${products.price}</div>
                                                }
                                            </div> :
                                            ''
                                        }
                                    </div>
                                ))
                                }
                            </div>

                        ))}
                    </div> */}
                    <Accordion defaultActiveKey={0} alwaysOpen>
                        {productDetails.map((ele, index) => (
                            <Accordion.Item eventKey={index} key={ele._id}>
                                <Accordion.Header>{ele.categoryName ? <div className='text-capitalize fw-medium'> {ele.categoryName}</div> : ''}</Accordion.Header>
                                <Accordion.Body className='bg-light py-2'>
                                    <div className='list-group list-group-flush'>
                                        {ele.productList.map((products, i) => (
                                            <div className='list-group-item bg-transparent px-0' key={products._id}>
                                                {products.name !== '' ?
                                                    <div className='d-flex justify-content-between gap-2 text-muted'>
                                                        <div className='item-name text-capitalize'>{products.name}</div>
                                                        {
                                                            products.status === "none" || products.status === 'no_price' || products.status === 'call_for_quotes' ?
                                                                ''
                                                                : <div className='item-price'>${products.price}</div>
                                                        }
                                                    </div> :
                                                    ''
                                                }
                                            </div>
                                        ))
                                        }
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                        }
                    </Accordion>
                </div>
            </div>
        </div>
    )
}




