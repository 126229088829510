import React from 'react';
import ErrorImage from '../assets/writer.svg'

const ErrorPage = ({ error, resetErrorBoundary }) => {
  console.log('Error occured', error);
  return (
    <div className='container py-5'>
      <div className='error-page text-center mx-auto' style={{ maxWidth: '700px' }}>
        <img className='img-fluid mb-2' src={ErrorImage} alt='Page not found' style={{ width: '400px' }} />
        <div className='error-msg fs-2 fw-semibold text-dark'>Something went wrong.</div>
        <p>We are unable to process you request please try again later.</p>
        <button className='btn btn-danger' onClick={resetErrorBoundary}>
          Go Home
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;