import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startRatingAction = () => {
    return {
        type: actionTypes.START_RATING_ACTION
    };
};

export const ratingActionFail = message => {
    return {
        type: actionTypes.RATING_ACTION_FAIL,
        message
    };
};
export const ratingCreationSuccess = message => {
    return {
        type: actionTypes.CREATE_RATING_SUCCESS,
        message
    };
};

export const makeRating = (data,toast) => {
    return dispatch => {
        dispatch(startRatingAction());
        const authCode = "Bearer " + localStorage.getItem("token");
        axios
            .post("/couponranking/create-rating-comment", data, {
                headers: { Authorization: authCode }
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        dispatch(ratingCreationSuccess(response.data.message));
                        if (data.ratingFor === 'couponranking') {
                                toast.success("Thank you for rate and review couponranking", { autoClose: 2000, hideProgressBar: true})
                            const load = () => { window.location.replace('/') }
                            setTimeout(load, 2000);
                        } else {
                            const load = () => { window.location.reload() }
                            setTimeout(load, 2000);
                        }
                    } else {
                        dispatch(ratingActionFail(response.data.message));
                    }
                } else {
                    dispatch(ratingActionFail(response.message));
                }
            })
            .catch(err => {
                dispatch(ratingActionFail(err.message));
            });
    };
};

export const fetchRatingSuccess = listing => {
    return {
        type: actionTypes.FETCH_RATING_LIST_SUCCESS,
        listing: listing
    };
};

export const getRatingList = (data) => {
    return dispatch => {
        dispatch(startRatingAction());
        const authCode = "Bearer " + localStorage.getItem("token");
        axios
            .post("/couponranking/get-rating-list", data, {
                headers: { Authorization: authCode }
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        dispatch(fetchRatingSuccess(response.data.data));
                    } else {
                        dispatch(ratingActionFail(response.data.message));
                    }
                } else {
                    dispatch(ratingActionFail(response.message));
                }
            })
            .catch(err => {
                dispatch(ratingActionFail(err.message));
            });
    };
};



