import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import EditCard from './EditCard';
import ReactLoading from 'react-loading';
import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CardInfo = (props) => {
    const { getCardDetails, cardDetails, loading, success, error } = props;
    useEffect(() => {
        getCardDetails();
        if (success) {
            toast.success(success, { autoClose: 500, hideProgressBar: true, })
        } else if (error) {
            toast.error(error, { autoClose: 500, hideProgressBar: true, })
        }
    }, [getCardDetails, success, error])
    var cardBrand = cardDetails.brand ? cardDetails.brand.toLowerCase() : '';
    // console.log(cardDetails, 'hello how are you')
    return (
        <div className='card-details-page section-bg-image py-4 py-md-5' style={{ backgroundImage: `url(${require("../assets/bg-banner3.jpg")})` }}>
            <ToastContainer />
            <Helmet>
                <title>Card Info | Update Card | Add new card</title> 
            </Helmet>
            <div className="container">
                <div className="wrapper-width mx-auto" style={{ maxWidth: '700px' }}>
                    <div className='text-center mb-4'>
                        <div className='section-label fs-4 '>Your Card Details</div>
                        <p className='text-muted m-0'>For edit or Add new card please click on edit</p>
                    </div>
                    <div className="form-check d-flex justify-content-center align-items-center mb-4">
                        <label className="form-check-label" for="exampleRadios1" style={{ minWidth: '300px', marginLeft: '20px' }}>
                            <div className={`credit-card ${cardBrand}`} >
                                <div className="credit-card-last4 mb-5 pb-4">
                                    {cardDetails.last4}
                                </div>
                                <div className="credit-card-expiry">
                                    {cardDetails.exp_month < 10 ? "0" + cardDetails.exp_month : cardDetails.exp_month}/{`${cardDetails.exp_year}`.slice(-2)}
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="Edit-wrapper text-center">
                        <button className='btn btn-danger' data-bs-toggle="modal" data-bs-target="#editCard">Update Card</button>
                    </div>
                </div>
                {/* <input type='radio' /> */}



            </div>

            {/* edit card Modal */}
            <div className="modal fade" id="editCard" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="editCardLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white px-sm-4">
                            <h1 className="modal-title fs-18 text-capitalize">Update Card</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 px-sm-4 py-4">
                            <div className="link-item mb-3">
                                <EditCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* loading section */}
            {loading ?
                <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                    style={{ zIndex: 1030 }}>
                    <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                </div>
                : ''}
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
        cardDetails: state.auth.cardDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCardDetails: () => dispatch(actions.getCardDetails()),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(CardInfo))