import React from 'react'
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect } from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BusinessPicture = (props) => {
    const { getPictureList, uploadBusinessPicture, success, error, deleteBusinessPicture } = props
    useEffect(() => {
        getPictureList()
        if (success) {
            toast.success(success, { autoClose: 1000 })
        } else if (error) {
            toast.error(error, { autoClose: 1000 })
        }
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [getPictureList, success, error])

    const [picture, setPicture] = useState('');
    const [pictureFile, setPictureFile] = useState('')
    const [pictureUrl, setPictureUrl] = useState('');

    const handleUploadFile = async (event) => {
        await setPictureFile(URL.createObjectURL(event.target.files[0]));
        await setPicture(event.target.files[0])

    }
    const handleSubmit = () => {
        if (picture) {
            const data = new FormData();
            data.append('picture', picture)
            uploadBusinessPicture(data)
        }
    }
    const handleDelete = (value) => {
        setPictureUrl(value)
    }

    const handleDeletePermanent = () => {
        if (pictureUrl !== '') {
            deleteBusinessPicture({ pictureUrl: pictureUrl })
        }

    }

    return (
        <div className='business-picture pt-4 pb-5'>
            {/* title head */}
            <Helmet>
                <title>CouponRanking | Add Business Pictures</title>
            </Helmet>
            <ToastContainer />
            <div className='container'>
                <div className='section-label fs-3 mb-4'>Upload Pictures</div>
                <div className="upload-wrapper w-100 border-primary rounded text-center text-primary mx-auto mt-4 p-3" style={{ border: '1px dashed', maxWidth: '450px' }}>
                    <img className='img-fluid rounded' src={pictureFile ? pictureFile : require('../assets/bg-banner4.jpg')} alt="" />
                    <div className='d-flex gap-3'>
                        <div className='w-50'>
                            <label htmlFor="uploadFile" className='btn btn-primary d-flex align-items-center justify-content-center mt-3' type='button'>
                                <i class="bi bi-paperclip me-1" style={{ transform: "rotate(45deg)" }}></i>Choose file
                            </label>
                            <input id="uploadFile" accept="image/*" multiple="" type="file" style={{ display: 'none' }}
                                onChange={(event) => handleUploadFile(event)}
                            />
                        </div>
                        <button className='w-50 btn btn-success mt-3 px-4' onClick={() => handleSubmit()}>
                            <i className="bi bi-upload me-2"></i> Upload
                        </button>
                    </div>
                </div>
                <div className='picture-list-wrapper mt-5'>
                    <div className='fs-4 fw-medium mb-3'>Business Picture list :</div>
                    {
                        props.pictureList.length > 0 ?
                            <div className="row g-3">
                                {
                                    props.pictureList.map((ele, index) => (
                                        <div className="col-sm-6 col-md-3" key={ele._id}>
                                            <div className="uploaded-img-wrapper position-relative overflow-hidden">
                                                <img className='border rounded img-fluid' src={ele.pictureUrl} alt="" />
                                                <div className='icon position-absolute end-0 bottom-0 z-1 rounded-2 px-3 py-2 bg-black bg-opacity-50'
                                                    data-bs-toggle="modal" data-bs-target="#deletePicture" onClick={() => handleDelete(ele.pictureUrl)}
                                                ><i className='bi bi-trash3 text-white fs-18 lh-1'></i></div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            <div className='no-picture border rounded p-5 text-center'>
                                <div>
                                    <i className='bi bi-image display-4 text-dark'></i>
                                </div>
                                <div className='text-danger fs-18 mt-2'>No Pictures available in your business.
                                    <br />
                                    Please upload...</div>
                            </div>
                    }
                </div>
            </div>
            {/* delete picture confirmation Model Popup */}

            <div className="modal fade" id="deletePicture" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="deletePictureLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center px-3 py-4">
                            <div className='mb-4 lh-sm fs-18'>Are you sure <br />you want to delete?</div>
                            <div className='d-flex gap-2 justify-content-center'>
                                <button className="btn btn-sm btn-primary d-flex align-items-center justify-content-center" data-bs-dismiss="modal" >No</button>
                                <button className="btn btn-sm btn-danger d-flex align-items-center justify-content-center" data-bs-dismiss="modal" onClick={() => handleDeletePermanent()}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        pictureList: state.business.pictureList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPictureList: () => dispatch(actions.getPictureList()),
        uploadBusinessPicture: (data) => dispatch(actions.uploadBusinessPicture(data)),
        deleteBusinessPicture: (data) => dispatch(actions.deleteBusinessPicture(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(BusinessPicture))
