import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withSnackbar } from 'react-simple-snackbar';
import * as actions from '../store/actions/index';
import { useState } from 'react';
import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TextMessage = (props) => {
    const { state } = useLocation();
    const [message, setMessage] = useState('')
    const [errMessage, setErrMessage] = useState('')
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [])

    let customerInfo = JSON.parse(localStorage.getItem('userInfo'));
    let mobileNo = customerInfo.country_code + customerInfo.mobile_no;
    let customerName = customerInfo.name;
    let customerEmail = customerInfo.email;

    const handleChange = (value) => {
        setMessage(value);
        setErrMessage('');
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        let data = {
            mobileNo: mobileNo,
            customerName: customerName,
            customerEmail: customerEmail,
            textMessage: message,
            businessEmail: state.email,
            businessId: state.businessId,
            clickType: 'text'
        }
        if (message !== '') {
            props.clickPerCount(data,toast)
        } else {
            setErrMessage('Please fill your message!')
        }

    }
    return (
        <div className='container my-5 text-center'>
            <ToastContainer />
            {/* title head */}
            <Helmet>
                <title>Send Text message  to {state.businessName}</title>
            </Helmet>
            <h1 className="section-label fs-3 mb-3 pb-2">Send your Text message to {state.businessName} contact number</h1>
            {/* {props.couponrankingMailSuccess ?
                <div className="d-flex align-items-center border border-success text-success rounded-1 shadow-sm px-3 py-2 mb-3" role="alert">
                    <i className="bi bi-hand-thumbs-up-fill fs-18"></i>
                    <div className='ms-2 lh-sm'>
                        {props.couponrankingMailSuccess}
                    </div>
                </div> : ''
            } */}
            <form className='bg-light px-1 px-md-3 py-2 py-md-3'>
                <div>
                    <textarea className='form-control' rows={6} id='businessDescription' placeholder='Write your message...'
                        required={true}
                        value={message}
                        onChange={(event) => handleChange(event.target.value)}
                    >
                    </textarea>
                </div>
                {errMessage ? <span className='text-danger mt-2'>{errMessage}</span> : ''}
                <div className='btn-wrapper mt-3'>
                    <button type='submit' className='btn btn-primary px-5' onClick={(event) => handleSubmit(event)}>Send</button>
                </div>
            </form>
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        couponrankingMailSuccess: state.business.couponrankingMailSuccess
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data,toast) => dispatch(actions.clickPerCount(data,toast)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(TextMessage))