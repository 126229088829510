import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Thankyou = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, [])

    return (
        <div>
            <Helmet>
                <title>Couponranking | Thank you</title>
            </Helmet>
            <div className='thankyou-page py-4 py-xl-4' style={{ minHeight: "50vh" }}>
                <div className='container'>
                    <div className='thankyou-wrapper bg-light p-5 border border-3 border-success rounded-4 text-center mx-auto' style={{ maxWidth: '700px' }}>
                        <div className="icon-wrapper mb-3">
                            <i className="bi bi-check-circle-fill display-4 text-success mb-3 pb-2"></i>
                            <h2 className="text-primary fw-bold">Thank You !</h2>
                        </div>

                        <div class="info-wrapper text-start ">
                            <div className='text-capitalize mb-2'>Hi {state && state.customerName ? state.customerName : 'Customer'},</div>
                            <div className="mb-2">
                                Thank you for submitting your request to {state && state.businessName ? state.businessName : 'business'}.
                                {state && state.businessName} management will contact you as soon as possible to make your sure your request are met.
                            </div>
                            <div >Thank you.</div>
                        </div>
                        <div className='btn-wrapper text-center mt-3'>
                            <button className="btn btn-primary text-nowrap d-inline-flex align-items-center justify-content-center fs-18" onClick={() => navigate('/', { replace: true })}>Home</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Thankyou