import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import UserInfoForQuoteService from './UserInfoForQuoteService';

const Remodeling = (props) => {
    const [serviceData, setServiceData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    //main contracting service 
    const [contractingService, setContractingService] = useState('');
    const [otherContractingService, setOtherContractingService] = useState(false);
    // Additions to an existing structure
    const [additionalNeed, setAdditionalNeed] = useState('');
    const [otherAdditionalNeed, setOtherAdditionalNeed] = useState(false);
    const [buildingStory, setBuildingStory] = useState('');
    const [otherBuildingStory, setOtherBuildingStory] = useState(false);
    const [roomIncludeInNewStory, setRoomIncludeInNewStory] = useState([]);
    const [contractingServiceType, setContractingServiceType] = useState('');
    const [otherContractingServiceType, setOtherContractingServiceType] = useState(false);
    const [materialInstall, setMaterialInstall] = useState([]);
    const [patioAreaForAddExStr, setPatioAreaForAddExStr] = useState('')
    const [otherPatioAreaForAddExStr, setOtherPatioAreaForAddExStr] = useState(false);

    // Construction design services
    const [designNeed, setDesignNeed] = useState('');
    const [otherDesignNeed, setOtherDesignNeed] = useState(false);
    const [storyConstructionDesign, setStoryConstructionDesign] = useState('');
    const [otherStoryConstructionDesign, setOtherStoryConstructionDesign] = useState(false);
    const [roomStoryForConDesg, setRoomStoryForConDesg] = useState([])
    const [newRoomDesign, setNewRoomDesign] = useState([])

    // New structure construction
    const [constructedNeed, setConstructedNeed] = useState('')
    const [kindOfStructure, setKindOfStructure] = useState('');
    const [otherKindOfStructure, setOtherKindOfStructure] = useState(false);
    const [newStrMaterial, setMaterialNewStr] = useState([]);
    const [patioAreaForNewStr, setPatioAreaForNewStr] = useState('')
    const [otherPatioAreaForNewStr, setOtherPatioAreaForNewStr] = useState(false)

    // Pool consrtuction section
    const [typeOfPoolInstall, setTypeOfPoolInstall] = useState('');
    const [otherTypeOfPoolInstall, setOtherTypeOfPoolInstall] = useState(false);
    const [poolTubLocation, setPoolTubLocation] = useState('')
    const [poolSurfaceMaterial, setPoolSurfaceMaterial] = useState('');
    const [otherPoolSurfaceMaterial, setOtherPoolSurfaceMaterial] = useState(false);
    const [itemForPool, setItemForPool] = useState([])

    // Remodeling secton
    const [remodal, setRemodal] = useState('');
    const [otherRemodal, setOtherRemodal] = useState(false);
    const [basementModal, setBasementModal] = useState([]);
    const [kitchenRemodal, setKitchenRemodal] = useState([]);
    const [garageInstallRemodal, setGarageInstallRemodal] = useState([]);
    const [garageDescription, setGarageDescription] = useState('')
    const [bathroomInstallRemodal, setBathroomInstallRemodal] = useState([]);

    // Structural repair
    const [structuralRepair, setStructuralRepair] = useState([])
    // common for all service
    const [locationType, setLocationType] = useState('')
    const [appointDate, setAppointDate] = useState(new Date());
    const [otherInfo, setOtherInfo] = useState('')

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    const handleContractingService = (value) => {
        if (value === 'other') {
            setOtherContractingService(true);
            setContractingService('')
        } else {
            setOtherContractingService(false);
            setContractingService(value)
        }
    }
    // Additions to an existing structure section Hooks
    const handleAdditionalNeed = (value) => {
        if (value === 'other') {
            setOtherAdditionalNeed(true);
            setAdditionalNeed('')
        } else {
            setOtherAdditionalNeed(false);
            setAdditionalNeed(value)
        }
    }
    const handleBuildingStory = (value) => {
        if (value === 'other') {
            setOtherBuildingStory(true);
            setBuildingStory('')
        } else {
            setOtherBuildingStory(false);
            setBuildingStory(value)
        }
    }
    const handleRoomIncludeInNewStory = (value) => {
        let newRoom = [...roomIncludeInNewStory]
        if (newRoom.includes(value)) {
            let indexValue = newRoom.indexOf(newRoom)
            newRoom.splice(indexValue, 1)
            setRoomIncludeInNewStory(newRoom)
        } else {
            newRoom.push(value)
            setRoomIncludeInNewStory(newRoom)
        }
    }
    const handleContractingServiceType = (value) => {
        if (value === 'other') {
            setOtherContractingServiceType(true);
            setContractingServiceType('')
        } else {
            setOtherContractingServiceType(false);
            setContractingServiceType(value)
        }
    }
    const handleMaterialInstall = (value) => {
        let newMaterial = [...materialInstall]
        if (newMaterial.includes(value)) {
            let indexValue = newMaterial.indexOf(newMaterial)
            newMaterial.splice(indexValue, 1)
            setMaterialInstall(newMaterial)
        } else {
            newMaterial.push(value)
            setMaterialInstall(newMaterial)
        }
    }
    const handlePatioAreaForAddExStr = (value) => {
        if (value === 'other') {
            setOtherPatioAreaForAddExStr(true);
            setPatioAreaForAddExStr('')
        } else {
            setOtherPatioAreaForAddExStr(false);
            setPatioAreaForAddExStr(value)
        }
    }

    //Construction design services hooks
    const handleDesignNeed = (value) => {
        setStoryConstructionDesign('')
        setRoomIncludeInNewStory([])
        if (value === 'other') {
            setOtherDesignNeed(true);
            setDesignNeed('')
        } else {
            setOtherDesignNeed(false);
            setDesignNeed(value)
        }
    }
    const handleStoryForConstructionDesign = (value) => {
        if (value === 'other') {
            setOtherStoryConstructionDesign(true);
            setStoryConstructionDesign('')
        } else {
            setOtherStoryConstructionDesign(false);
            setStoryConstructionDesign(value)
        }
    }
    const handleRoomStoryForConDesg = (value) => {
        let newRoomCd = [...roomStoryForConDesg]
        if (newRoomCd.includes(value)) {
            let indexValue = newRoomCd.indexOf(newRoomCd)
            newRoomCd.splice(indexValue, 1)
            setRoomStoryForConDesg(newRoomCd)
        } else {
            newRoomCd.push(value)
            setRoomStoryForConDesg(newRoomCd)
        }
    }
    const handleNewRoomDesign = (value) => {
        let updatedRoomDesign = [...newRoomDesign]
        if (updatedRoomDesign.includes(value)) {
            let indexValue = updatedRoomDesign.indexOf(updatedRoomDesign)
            updatedRoomDesign.splice(indexValue, 1)
            setNewRoomDesign(updatedRoomDesign)
        } else {
            updatedRoomDesign.push(value)
            setNewRoomDesign(updatedRoomDesign)
        }
    }

    // for new structure hooks
    const handleKindOfStructure = (value) => {
        if (value === 'other') {
            setOtherKindOfStructure(true);
            setKindOfStructure('')
        } else {
            setOtherKindOfStructure(false);
            setKindOfStructure(value)
        }
    }
    const handleMaterialForNewStr = (value) => {
        let newMaterialstr = [...newStrMaterial]
        if (newMaterialstr.includes(value)) {
            let indexValue = newMaterialstr.indexOf(newMaterialstr)
            newMaterialstr.splice(indexValue, 1)
            setMaterialNewStr(newMaterialstr)
        } else {
            newMaterialstr.push(value)
            setMaterialNewStr(newMaterialstr)
        }
    }
    const handlePatioAreaForNewStr = (value) => {
        if (value === 'other') {
            setOtherPatioAreaForNewStr(true);
            setPatioAreaForNewStr('')
        } else {
            setOtherPatioAreaForNewStr(false);
            setPatioAreaForNewStr(value)
        }
    }

    // Pool consrtuction section hooks
    const handleTypeOfPoolInstall = (value) => {
        if (value === 'other') {
            setOtherTypeOfPoolInstall(true);
            setTypeOfPoolInstall('')
        } else {
            setOtherTypeOfPoolInstall(false);
            setTypeOfPoolInstall(value)
        }
    }
    const handlePoolSurfaceMaterial = (value) => {
        if (value === 'other') {
            setOtherPoolSurfaceMaterial(true);
            setPoolSurfaceMaterial('')
        } else {
            setOtherPoolSurfaceMaterial(false);
            setPoolSurfaceMaterial(value)
        }
    }
    const handleItemForPool = (value) => {
        let newItemPool = [...itemForPool]
        if (newItemPool.includes(value)) {
            let indexValue = newItemPool.indexOf(newItemPool)
            newItemPool.splice(indexValue, 1)
            setItemForPool(newItemPool)
        } else {
            newItemPool.push(value)
            setItemForPool(newItemPool)
        }
    }

    // Remodal Hooks
    const handleRemodel = (value) => {
        if (value === 'other') {
            setOtherRemodal(true);
            setRemodal('')
        } else {
            setOtherRemodal(false);
            setRemodal(value)
        }
    }
    const handleBasementModal = (value) => {
        let newBasementModal = [...basementModal]
        if (newBasementModal.includes(value)) {
            let indexValue = newBasementModal.indexOf(newBasementModal)
            newBasementModal.splice(indexValue, 1)
            setBasementModal(newBasementModal)
        } else {
            newBasementModal.push(value)
            setBasementModal(newBasementModal)
        }
    }
    const handleKitchenModal = (value) => {
        let newKitchenRemodal = [...kitchenRemodal]
        if (newKitchenRemodal.includes(value)) {
            let indexValue = newKitchenRemodal.indexOf(newKitchenRemodal)
            newKitchenRemodal.splice(indexValue, 1)
            setKitchenRemodal(newKitchenRemodal)
        } else {
            newKitchenRemodal.push(value)
            setKitchenRemodal(newKitchenRemodal)
        }
    }
    const handleGarageInstallRemodal = (value) => {
        let newGarageModal = [...garageInstallRemodal]
        if (newGarageModal.includes(value)) {
            let indexValue = newGarageModal.indexOf(newGarageModal)
            newGarageModal.splice(indexValue, 1)
            setGarageInstallRemodal(newGarageModal)
        } else {
            newGarageModal.push(value)
            setGarageInstallRemodal(newGarageModal)
        }
    }
    const handleBathroomInstallRemodal = (value) => {
        let newBathroomeModal = [...bathroomInstallRemodal]
        if (newBathroomeModal.includes(value)) {
            let indexValue = newBathroomeModal.indexOf(newBathroomeModal)
            newBathroomeModal.splice(indexValue, 1)
            setBathroomInstallRemodal(newBathroomeModal)
        } else {
            newBathroomeModal.push(value)
            setBathroomInstallRemodal(newBathroomeModal)
        }
    }

    // Structural repair Hooks
    const handleStructuralRepair = (value) => {
        let newRepair = [...structuralRepair]
        if (newRepair.includes(value)) {
            let indexValue = newRepair.indexOf(newRepair)
            newRepair.splice(indexValue, 1)
            setStructuralRepair(newRepair)
        } else {
            newRepair.push(value)
            setStructuralRepair(newRepair)
        }
    }

    //  submit
    const handleSubmit = (e) => {
        e.preventDefault()
        if (appointDate && locationType) {
            let UsDate = new Date(appointDate).toLocaleDateString("en-US")
            let quoteDetails = {
                "Requested Service": "Remodeling (Constructing) Services",
                "What type of contracting service do you need?": contractingService,
                "What kind of location is this?": locationType,
                "When do you require this service?": UsDate,
                "Any other details": otherInfo
            }
            let data = {
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            if (contractingService === 'Additions to an existing structure') {
                if (additionalNeed) {
                    quoteDetails['What type of addition do you need?'] = additionalNeed
                    if (additionalNeed === 'Additional stories') {
                        if (buildingStory) { quoteDetails['How many stories do you need to add to your building?'] = buildingStory }
                        if (roomIncludeInNewStory.length > 0) { quoteDetails['What type of room(s) will be included on the new story?'] = roomIncludeInNewStory }
                    } else if (additionalNeed === 'New rooms') {
                        if (contractingServiceType) { quoteDetails['What type of contracting service (new room) do you need?'] = contractingServiceType }
                    } else if (additionalNeed === 'Patio, porch, or terrace') {
                        if (materialInstall.length > 0) { quoteDetails['What type of material do you need to install?'] = materialInstall }
                        if (patioAreaForAddExStr) { quoteDetails['How big of a patio, porch, or terrace do you need to install?'] = patioAreaForAddExStr }
                    }
                    setServiceData(data)
                    setIsSubmit(true)
                } else {
                    toast.warning('Please Select Type of addition field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }

            } else if (contractingService === 'Construction design services') {
                if (designNeed) {
                    quoteDetails['What do you need to have designed?'] = designNeed
                    if (designNeed === 'Additional stories') {
                        if (storyConstructionDesign) { quoteDetails['How many stories do you need to add to your building?'] = storyConstructionDesign }
                        if (roomStoryForConDesg.length > 0) { quoteDetails['What type of room(s) will be included on the new story?'] = roomStoryForConDesg }
                    } else if (designNeed === 'New Rooms') {
                        if (newRoomDesign.length > 0) { quoteDetails['What type of room(s) will be included on the new story?'] = roomStoryForConDesg }
                    }
                    setServiceData(data)
                    setIsSubmit(true)
                } else {
                    toast.warning('Please Select designed field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }

            } else if (contractingService === 'New structure construction') {
                if (contractingService) {
                    quoteDetails['What do you need to have constructed?'] = contractingService
                    if (constructedNeed === 'Landscape elements') {
                        if (kindOfStructure) {
                            quoteDetails['What kind of structure do you need built?'] = kindOfStructure
                            if (kindOfStructure === 'Patio, porch, or terrace') {
                                if (newStrMaterial.length > 0) { quoteDetails['What type of material do you need to install?'] = newStrMaterial }
                                if (patioAreaForNewStr) { quoteDetails['How big of a patio, porch, or terrace do you need to install?'] = patioAreaForNewStr }
                            }
                        }
                        setServiceData(data)
                        setIsSubmit(true)
                    } else {
                        toast.warning('Please Select structure field!', {
                            autoClose: 700,
                            hideProgressBar: true,
                        })
                    }
                } else {
                    toast.warning('Please Select constructed field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }

            } else if (contractingService === 'Pool Construction') {
                if (typeOfPoolInstall && poolTubLocation) {
                    quoteDetails['What type of pool or hot tub do you need to install?'] = typeOfPoolInstall
                    quoteDetails['Will the pool or hot tub be indoors or outdoors?'] = poolTubLocation
                    if (poolSurfaceMaterial) {
                        quoteDetails['What surface material do you need for your pool or hot tub?'] = poolSurfaceMaterial
                    }
                    if (itemForPool.length > 0) {
                        quoteDetails['Select all the items you need for your pool or hot tub.'] = itemForPool
                    }
                    setServiceData(data)
                    setIsSubmit(true)

                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            } else if (contractingService === 'Remodeling') {
                if (remodal) {
                    quoteDetails['What needs to be remodeled?'] = remodal
                    if (remodal === 'Basement') {
                        if (basementModal.length > 0) { quoteDetails['What features do you want in your basement?'] = basementModal }
                    }
                    if (remodal === 'Kitchen') {
                        if (kitchenRemodal.length > 0) { quoteDetails['Which of the following will you need moved or installed in your kitchen?'] = kitchenRemodal }
                    }
                    if (remodal === 'Garage') {
                        if (garageDescription) { quoteDetails['What best describes this garage remodel?'] = garageDescription }
                        if (garageInstallRemodal.length > 0) { quoteDetails['Which of the following will you need moved or installed in your garage remodel?'] = garageInstallRemodal }
                    }
                    if (remodal === 'Bathroom') {
                        if (bathroomInstallRemodal.length > 0) {
                            quoteDetails['Which of the following will you need moved or installed in your bathroom remodel?'] = garageInstallRemodal
                        }
                    }
                    setServiceData(data)
                    setIsSubmit(true)
                } else {
                    toast.warning('Please Select All Remodal field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }

            } else if (contractingService === 'Structural repair') {
                if (structuralRepair.length > 0) {
                    quoteDetails['What structural elements need repairs?'] = structuralRepair
                    setServiceData(data)
                    setIsSubmit(true)
                } else {
                    toast.warning('Please Select structural elements field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }

            } else {
                setServiceData(data)
                setIsSubmit(true)
            }

        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }

    return (
        <>
            {!isSubmit ? (
                <div id="remodling" className='card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
                    <Helmet>
                        <title>Request Quote For Remodeling</title>
                    </Helmet>
                    <ToastContainer />
                    <div className="section-label fs-3 mb-4">Remodeling (Constructing) Services</div>
                    <div className="mb-3">
                        <label htmlFor="chooseOccasion" className="form-label fs-6 mb-2">What type of contracting service do you need?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForContracting" id="AES" required
                                    onClick={() => handleContractingService('Additions to an existing structure')} />
                                <label className="form-check-label" htmlFor="AES">Additions to an existing structure</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForContracting" id="CDS"
                                    onClick={() => handleContractingService('Construction design services')} />
                                <label className="form-check-label" htmlFor="CDS">Construction design services</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForContracting" id="NSC"
                                    onClick={() => handleContractingService('New structure construction')} />
                                <label className="form-check-label" htmlFor="NSC">New structure construction</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForContracting" id="pool"
                                    onClick={() => handleContractingService('Pool Construction')} />
                                <label className="form-check-label" htmlFor="pool">Pool Construction</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForContracting" id="remodeling"
                                    onClick={() => handleContractingService('Remodeling')} />
                                <label className="form-check-label" htmlFor="remodeling">Remodeling</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForContracting" id="structural_repair"
                                    onClick={() => handleContractingService('Structural repair')} />
                                <label className="form-check-label" htmlFor="structural_repair">Structural repair</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForContracting" id="otherContracting"
                                    onClick={() => handleContractingService('other')} />
                                <label className="form-check-label" htmlFor="otherContracting">Other</label>
                            </div>
                        </div>
                        {
                            otherContractingService ?
                                <input type="text" className="form-control mt-2" id="OtherForcontracting" placeholder='Other...' required
                                    onChange={(e) => setContractingService(e.target.value)} />
                                : ''
                        }

                    </div>

                    <div className="element-wrapper mb-3">
                        {/* Additions to an existing structure section */}
                        {
                            contractingService === 'Additions to an existing structure' ?
                                <div className='AdditionsToAnExistingStructure'>
                                    <div id="AES" className="mt-3">
                                        <div className="form-label fs-6 mb-2">What type of addition do you need?</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForAddition" id="additional_stories" required
                                                    onClick={() => handleAdditionalNeed('Additional stories')} />
                                                <label className="form-check-label" htmlFor="additional_stories">Additional stories</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForAddition" id="balcony"
                                                    onClick={() => handleAdditionalNeed('Balcony')} />
                                                <label className="form-check-label" htmlFor="balcony">Balcony</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForAddition" id="deck"
                                                    onClick={() => handleAdditionalNeed('Deck')} />
                                                <label className="form-check-label" htmlFor="deck">Deck</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForAddition" id="new_rooms"
                                                    onClick={() => handleAdditionalNeed('New rooms')} />
                                                <label className="form-check-label" htmlFor="new_rooms">New rooms</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForAddition" id="patio_p_t"
                                                    onClick={() => handleAdditionalNeed('Patio, porch, or terrace')} />
                                                <label className="form-check-label" htmlFor="patio_p_t">Patio, porch, or terrace</label>
                                            </div>

                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForAddition" id="stairs"
                                                    onClick={() => handleAdditionalNeed('Stairs')} />
                                                <label className="form-check-label" htmlFor="stairs">Stairs</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForAddition" id="OTH"
                                                    onClick={() => handleAdditionalNeed('other')} />
                                                <label className="form-check-label" htmlFor="OTH">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherAdditionalNeed ?
                                                <input type="text" className="form-control mt-2" id="OTH" placeholder='Other...' required
                                                    onChange={(e) => setAdditionalNeed(e.target.value)} />
                                                : ''
                                        }
                                    </div>
                                    {/* stories do need to add to your building if choose Additional stories */}
                                    {
                                        additionalNeed === 'Additional stories' ?
                                            <div className='Additional stories'>
                                                <div id="WYNHD" className="mt-3">
                                                    <div className="form-label fs-6 mb-2">How many stories do you need to add to your building?</div>
                                                    <div className="d-flex flex-wrap g-2">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForBuldingStory" id="one_story" required
                                                                onClick={() => handleBuildingStory('1 story')} />
                                                            <label className="form-check-label" htmlFor="one_story">1 story</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForBuldingStory" id="two_stories"
                                                                onClick={() => handleBuildingStory('2 stories')} />
                                                            <label className="form-check-label" htmlFor="two_stories">2 stories</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForBuldingStory" id="three_stories"
                                                                onClick={() => handleBuildingStory('3 stories')} />
                                                            <label className="form-check-label" htmlFor="three_stories">3 stories</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForBuldingStory" id="four_stories"
                                                                onClick={() => handleBuildingStory('4 stories')} />
                                                            <label className="form-check-label" htmlFor="four_stories">4 stories</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForBuldingStory" id="OTHStory"
                                                                onClick={() => handleBuildingStory('other')} />
                                                            <label className="form-check-label" htmlFor="OTHStory">Other</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        otherBuildingStory ?
                                                            <input type="text" className="form-control mt-2" id="OTHbuldingStory" placeholder='Other stories...' required
                                                                onChange={(e) => setBuildingStory(e.target.value)} />
                                                            : ''
                                                    }

                                                </div>
                                                <div id="WNBR" className="mt-3">
                                                    <div className="form-label fs-6 mb-2">What type of room(s) will be included on the new story? Select all that apply.</div>
                                                    <div className="d-flex flex-wrap g-2">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="bathroom" required
                                                                onClick={() => handleRoomIncludeInNewStory('Bathroom')} />
                                                            <label className="form-check-label" htmlFor="bathroom">Bathroom</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="bedroom"
                                                                onClick={() => handleRoomIncludeInNewStory('Bedroom')} />
                                                            <label className="form-check-label" htmlFor="bedroom">Bedroom</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="common_areas" required
                                                                onClick={() => handleRoomIncludeInNewStory('Common areas')} />
                                                            <label className="form-check-label" htmlFor="common_areas">Common areas</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="kitchen" required
                                                                onClick={() => handleRoomIncludeInNewStory('Kitchen')} />
                                                            <label className="form-check-label" htmlFor="kitchen">Kitchen</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="laundry_room"
                                                                onClick={() => handleRoomIncludeInNewStory('Laundry room')} />
                                                            <label className="form-check-label" htmlFor="laundry_room">Laundry room</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {/* after selection of New rooms  */}
                                    {
                                        additionalNeed === 'New rooms' ?
                                            <div id="WYNHD" className="mt-3">
                                                <div className="form-label fs-6 mb-2">What type of contracting service (new room) do you need?</div>
                                                <div className="d-flex flex-wrap g-2">
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForContractingService" id="bathroom_addition" required
                                                            onClick={() => handleContractingServiceType('Bathroom addition')} />
                                                        <label className="form-check-label" htmlFor="bathroom_addition">Bathroom addition</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForContractingService" id="bedroom_addition"
                                                            onClick={() => handleContractingServiceType('Bedroom addition')} />
                                                        <label className="form-check-label" htmlFor="bedroom_addition">Bedroom addition</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForContractingService" id="common_area_addition"
                                                            onClick={() => handleContractingServiceType('Common area addition')} />
                                                        <label className="form-check-label" htmlFor="common_area_addition">Common area addition</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForContractingService" id="garage_addition"
                                                            onClick={() => handleContractingServiceType('Garage addition')} />
                                                        <label className="form-check-label" htmlFor="garage_addition">Garage addition</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForContractingService" id="kitchen_addition"
                                                            onClick={() => handleContractingServiceType('Kitchen addition')} />
                                                        <label className="form-check-label" htmlFor="kitchen_addition">Kitchen addition</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForContractingService" id="laundry_room_addition"
                                                            onClick={() => handleContractingServiceType('Laundry room addition')} />
                                                        <label className="form-check-label" htmlFor="laundry_room_addition">Laundry room addition</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForContractingService" id="OTHContractingService"
                                                            onClick={() => handleContractingServiceType('other')} />
                                                        <label className="form-check-label" htmlFor="OTHContractingService">Other</label>
                                                    </div>
                                                </div>
                                                {
                                                    otherContractingServiceType ?
                                                        <input type="text" className="form-control mt-2" id="OTHContracting" placeholder='Other contracting service type...' required
                                                            onChange={(e) => setContractingServiceType(e.target.value)} />
                                                        : ''
                                                }

                                            </div>
                                            : ''
                                    }
                                    {/* after selection of Patio, porch, or terrace */}
                                    {
                                        additionalNeed === 'Patio, porch, or terrace' ?
                                            <div className='Patio, porch, or terrace'>
                                                <div id="WNBR" className="mt-3">
                                                    <div className="form-label fs-6 mb-2">What type of material do you need to install?</div>
                                                    <div className="d-flex flex-wrap g-2">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="bathroom" required
                                                                onClick={() => handleMaterialInstall('Asphalt')} />
                                                            <label className="form-check-label" htmlFor="Asphalt">Asphalt</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="Brick"
                                                                onClick={() => handleMaterialInstall('Brick')} />
                                                            <label className="form-check-label" htmlFor="Brick">Brick</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="common_areas" required
                                                                onClick={() => handleMaterialInstall('Concrete')} />
                                                            <label className="form-check-label" htmlFor="common_areas">Concrete</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="kitchen" required
                                                                onClick={() => handleMaterialInstall('Concrete or cinder block')} />
                                                            <label className="form-check-label" htmlFor="Concrete or cinder block">Concrete or cinder block</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="laundry_room"
                                                                onClick={() => handleMaterialInstall('Gravel')} />
                                                            <label className="form-check-label" htmlFor="laundry_room">Gravel</label>
                                                        </div>

                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="laundry_room"
                                                                onClick={() => handleMaterialInstall('Stamped concrete')} />
                                                            <label className="form-check-label" htmlFor="laundry_room">Stamped concrete</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="laundry_room"
                                                                onClick={() => handleMaterialInstall('Stone')} />
                                                            <label className="form-check-label" htmlFor="laundry_room">Stone</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="laundry_room"
                                                                onClick={() => handleMaterialInstall('Textured concrete')} />
                                                            <label className="form-check-label" htmlFor="laundry_room">Textured concrete</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="laundry_room"
                                                                onClick={() => handleMaterialInstall('Not Sure')} />
                                                            <label className="form-check-label" htmlFor="laundry_room">Not Sure</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="patioArea" className="mt-3">
                                                    <div className="form-label fs-6 mb-2">How big of a patio, porch, or terrace do you need to install?</div>
                                                    <div className="d-flex flex-wrap g-2">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="L200PAE" required
                                                                onClick={() => handlePatioAreaForAddExStr('Less than 200 sq ft')} />
                                                            <label className="form-check-label" htmlFor="L200PAE">Less than 200 sq ft</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="2_300PAE"
                                                                onClick={() => handlePatioAreaForAddExStr('200-300 sq ft')} />
                                                            <label className="form-check-label" htmlFor="2_300PAE">200-300 sq ft</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="3_400PAE"
                                                                onClick={() => handlePatioAreaForAddExStr('300-400 sq ft')} />
                                                            <label className="form-check-label" htmlFor="3_400PAE">300-400 sq ft</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="4_500PAE"
                                                                onClick={() => handlePatioAreaForAddExStr('400-500 sq ft')} />
                                                            <label className="form-check-label" htmlFor="4_500PAE">400-500 sq ft</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="5+00PAE"
                                                                onClick={() => handlePatioAreaForAddExStr('500+ sq ft')} />
                                                            <label className="form-check-label" htmlFor="5+00PAE">500+ sq ft</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="OTHPAE"
                                                                onClick={() => handlePatioAreaForAddExStr('other')} />
                                                            <label className="form-check-label" htmlFor="OTHPAE">Other</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        otherPatioAreaForAddExStr ?
                                                            <input type="text" className="form-control mt-2" id="OTHPatioAE" placeholder='Other area...' required
                                                                onChange={(e) => setPatioAreaForAddExStr(e.target.value)} />
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </div>
                                : ""
                        }
                        {/* Construction design services section */}
                        {
                            contractingService === 'Construction design services' ?
                                <div className='Construction design services'>
                                    {/* If what do you need to have designed?  */}
                                    <div id="DesignNeed" className="mt-3">
                                        <div className="form-label fs-6 mb-2">What do you need to have designed?</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="additional_stories1" required
                                                    onClick={() => handleDesignNeed('Additional stories')} />
                                                <label className="form-check-label" htmlFor="additional_stories1">Additional stories</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="balcony1"
                                                    onClick={() => handleDesignNeed('Balcony')} />
                                                <label className="form-check-label" htmlFor="balcony1">Balcony</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="deck"
                                                    onClick={() => handleDesignNeed('Deck')} />
                                                <label className="form-check-label" htmlFor="deck">Deck</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="detached_garage"
                                                    onClick={() => handleDesignNeed('Detached Garage')} />
                                                <label className="form-check-label" htmlFor="detached_garage">Detached Garage</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="landscape_element"
                                                    onClick={() => handleDesignNeed('Landscape element')} />
                                                <label className="form-check-label" htmlFor="landscape_element">Landscape element</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="patio1"
                                                    onClick={() => handleDesignNeed('Patio, porch, or terrace')} />
                                                <label className="form-check-label" htmlFor="patio1">Patio, porch, or terrace</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="stairs"
                                                    onClick={() => handleDesignNeed('Stairs')} />
                                                <label className="form-check-label" htmlFor="stairs">Stairs</label>

                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="new_rooms"
                                                    onClick={() => handleDesignNeed('New Rooms')} />
                                                <label className="form-check-label" htmlFor="new_rooms">New Rooms</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="pool"
                                                    onClick={() => handleDesignNeed('Pool')} />
                                                <label className="form-check-label" htmlFor="pool">Pool</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="SFH"
                                                    onClick={() => handleDesignNeed('Single family home')} />
                                                <label className="form-check-label" htmlFor="SFH">Single family home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="stairs1"
                                                    onClick={() => handleDesignNeed('Stairs')} />
                                                <label className="form-check-label" htmlFor="stairs1">Stairs</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="GHADU1"
                                                    onClick={() => handleDesignNeed('Guest house or accessory dwelling unit')} />
                                                <label className="form-check-label" htmlFor="GHADU1">Guest house or accessory dwelling unit</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="MMH1"
                                                    onClick={() => handleDesignNeed('Manufactured or mobile home')} />
                                                <label className="form-check-label" htmlFor="MMH1">Manufactured or mobile home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="MBH1"
                                                    onClick={() => handleDesignNeed('Modular or prefabricated home')} />
                                                <label className="form-check-label" htmlFor="MBH1">Modular or prefabricated home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDesignNeed" id="OTH1"
                                                    onClick={() => handleDesignNeed('other')} />
                                                <label className="form-check-label" htmlFor="OTH1">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherDesignNeed ?
                                                <input type="text" className="form-control mt-2" id="OTHDesign" placeholder='Other design...' required
                                                    onChange={(e) => setDesignNeed(e.target.value)} />
                                                : ''
                                        }

                                    </div>
                                    {
                                        designNeed === 'Additional stories' ?
                                            <div className='Additional stories'>
                                                <div id="Additional_stories" className="mt-3">
                                                    <div className="form-label fs-6 mb-2">How many stories do you need to add to your building?</div>
                                                    <div className="d-flex flex-wrap g-2">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStoryConDes" id="one_story" required
                                                                onClick={() => handleStoryForConstructionDesign('1 story')} />
                                                            <label className="form-check-label" htmlFor="one_story">1 story</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStoryConDes" id="two_stories"
                                                                onClick={() => handleStoryForConstructionDesign('2 stories')} />
                                                            <label className="form-check-label" htmlFor="two_stories">2 stories</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStoryConDes" id="three_stories"
                                                                onClick={() => handleStoryForConstructionDesign('3 stories')} />
                                                            <label className="form-check-label" htmlFor="three_stories">3 stories</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStoryConDes" id="four_stories"
                                                                onClick={() => handleStoryForConstructionDesign('4 stories')} />
                                                            <label className="form-check-label" htmlFor="four_stories">4 stories</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStoryConDes" id="OTHStory"
                                                                onClick={() => handleStoryForConstructionDesign('other')} />
                                                            <label className="form-check-label" htmlFor="OTHStory">Other</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        otherStoryConstructionDesign ?
                                                            <input type="text" className="form-control mt-2" id="OTHDesign" placeholder='Other stories...' required
                                                                onChange={(e) => setStoryConstructionDesign(e.target.value)} />
                                                            : ''
                                                    }
                                                </div>
                                                <div id="storyConstructionDesign" className="mt-3">
                                                    <div className="form-label fs-6 mb-2">What type of room(s) will be included on the new story? Select all that apply.</div>
                                                    <div className="d-flex flex-wrap g-2">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="bathroom" required
                                                                onClick={() => handleRoomStoryForConDesg('Bathroom')} />
                                                            <label className="form-check-label" htmlFor="bathroom">Bathroom</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="bedroom"
                                                                onClick={() => handleRoomStoryForConDesg('Bedroom')} />
                                                            <label className="form-check-label" htmlFor="bedroom">Bedroom</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="common_areas" required
                                                                onClick={() => handleRoomStoryForConDesg('Common areas')} />
                                                            <label className="form-check-label" htmlFor="common_areas">Common areas</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="kitchen" required
                                                                onClick={() => handleRoomStoryForConDesg('Kitchen')} />
                                                            <label className="form-check-label" htmlFor="kitchen">Kitchen</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="laundry_room"
                                                                onClick={() => handleRoomStoryForConDesg('Laundry room')} />
                                                            <label className="form-check-label" htmlFor="laundry_room">Laundry room</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                    {
                                        designNeed === 'New Rooms' ?
                                            <div id="storyConstructionDesign" className="mt-3">
                                                <div className="form-label fs-6 mb-2">What type of room(s) will be included on the new story? Select all that apply.</div>
                                                <div className="d-flex flex-wrap g-2">
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="bathroom" required
                                                            onClick={() => handleNewRoomDesign('Bathroom')} />
                                                        <label className="form-check-label" htmlFor="bathroom">Bathroom</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="bedroom"
                                                            onClick={() => handleNewRoomDesign('Bedroom')} />
                                                        <label className="form-check-label" htmlFor="bedroom">Bedroom</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="common_areas" required
                                                            onClick={() => handleNewRoomDesign('Common areas')} />
                                                        <label className="form-check-label" htmlFor="common_areas">Common areas</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="kitchen" required
                                                            onClick={() => handleNewRoomDesign('Kitchen')} />
                                                        <label className="form-check-label" htmlFor="kitchen">Kitchen</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="laundry_room"
                                                            onClick={() => handleNewRoomDesign('Laundry room')} />
                                                        <label className="form-check-label" htmlFor="laundry_room">Laundry room</label>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </div>
                                : ''
                        }
                        {/* New structure construction section */}
                        {
                            contractingService === 'New structure construction' ?
                                <div className='New structure construction'>
                                    <div id="New structure construction" className="mt-3">
                                        <div className="form-label fs-6 mb-2">What do you need to have constructed?</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroup4" id="detached_garage1"
                                                    onClick={() => setConstructedNeed('Detached garage')} />
                                                <label className="form-check-label" htmlFor="detached_garage1">Detached garage</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroup4" id="land2"
                                                    onClick={() => setConstructedNeed('Landscape elements')} />
                                                <label className="form-check-label" htmlFor="land2">Landscape elements</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroup4" id="SFH2"
                                                    onClick={() => setConstructedNeed('Single family home')} />
                                                <label className="form-check-label" htmlFor="SFH2">Single family home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroup4" id="GHADU2"
                                                    onClick={() => setConstructedNeed('Guest house or accessory dwelling unit')} />
                                                <label className="form-check-label" htmlFor="GHADU2">Guest house or accessory dwelling unit</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroup4" id="MMH2"
                                                    onClick={() => setConstructedNeed('Manufactured or mobile home')} />
                                                <label className="form-check-label" htmlFor="MMH2">Manufactured or mobile home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroup4" id="MBH2"
                                                    onClick={() => setConstructedNeed('Modular or prefabricated home')} />
                                                <label className="form-check-label" htmlFor="MBH2">Modular or prefabricated home</label>
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        constructedNeed === 'Landscape elements' ?
                                            <div className='LandEle'>
                                                <div id="Landscape elements" className="mt-3">
                                                    <div className="form-label fs-6 mb-2">What kind of structure do you need built?</div>
                                                    <div className="d-flex flex-wrap g-2">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="Arbor_pergola" required
                                                                onClick={() => handleKindOfStructure('Arbor, pergola, or trellis')} />
                                                            <label className="form-check-label" htmlFor="Arbor_pergola">Arbor, pergola, or trellis</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="bridge" required
                                                                onClick={() => handleKindOfStructure('Bridge')} />
                                                            <label className="form-check-label" htmlFor="bridge">Bridge</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="deck" required
                                                                onClick={() => handleKindOfStructure('Deck')} />
                                                            <label className="form-check-label" htmlFor="deck">Deck</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="gazebo_or_ramada" required
                                                                onClick={() => handleKindOfStructure('Gazebo or ramada')} />
                                                            <label className="form-check-label" htmlFor="gazebo_or_ramada">Gazebo or ramada</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="outdoor_fireplace" required
                                                                onClick={() => handleKindOfStructure('Outdoor fireplace')} />
                                                            <label className="form-check-label" htmlFor="outdoor_fireplace">Outdoor fireplace</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="outdoor_kitchen" required
                                                                onClick={() => handleKindOfStructure('Outdoor kitchen')} />
                                                            <label className="form-check-label" htmlFor="outdoor_kitchen">Outdoor kitchen</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="outdoor_theater" required
                                                                onClick={() => handleKindOfStructure('Outdoor theater')} />
                                                            <label className="form-check-label" htmlFor="outdoor_theater">Outdoor theater</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="parking_structure" required
                                                                onClick={() => handleKindOfStructure('Parking structure')} />
                                                            <label className="form-check-label" htmlFor="parking_structure">Parking structure</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="patioPoTe" required
                                                                onClick={() => handleKindOfStructure('Patio, porch, or terrace')} />
                                                            <label className="form-check-label" htmlFor="patioPoTe">Patio, porch, or terrace</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="playground" required
                                                                onClick={() => handleKindOfStructure('Playground')} />
                                                            <label className="form-check-label" htmlFor="playground">Playground</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForStructureType" id="OTHKindStructure"
                                                                onClick={() => handleKindOfStructure('other')} />
                                                            <label className="form-check-label" htmlFor="OTHKindStructure">Other</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        otherKindOfStructure ?
                                                            <input type="text" className="form-control mt-2" id="OTHStructure" placeholder='Other kind of Structure...' required
                                                                onChange={(e) => setKindOfStructure(e.target.value)} />
                                                            : ''
                                                    }
                                                </div>
                                                {
                                                    kindOfStructure === 'Patio, porch, or terrace' ?
                                                        <div className='KindOfStr'>
                                                            <div id="StrMaterial" className="mt-3">
                                                                <div className="form-label fs-6 mb-2">What type of material do you need to install?</div>
                                                                <div className="d-flex flex-wrap g-2">
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="bathroom" required
                                                                            onClick={() => handleMaterialForNewStr('Asphalt')} />
                                                                        <label className="form-check-label" htmlFor="Asphalt">Asphalt</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="Brick"
                                                                            onClick={() => handleMaterialForNewStr('Brick')} />
                                                                        <label className="form-check-label" htmlFor="Brick">Brick</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="common_areas" required
                                                                            onClick={() => handleMaterialForNewStr('Concrete')} />
                                                                        <label className="form-check-label" htmlFor="common_areas">Concrete</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="kitchen" required
                                                                            onClick={() => handleMaterialForNewStr('Concrete or cinder block')} />
                                                                        <label className="form-check-label" htmlFor="Concrete or cinder block">Concrete or cinder block</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="laundry_room"
                                                                            onClick={() => handleMaterialForNewStr('Gravel')} />
                                                                        <label className="form-check-label" htmlFor="laundry_room">Gravel</label>
                                                                    </div>

                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="laundry_room"
                                                                            onClick={() => handleMaterialForNewStr('Stamped concrete')} />
                                                                        <label className="form-check-label" htmlFor="laundry_room">Stamped concrete</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="laundry_room"
                                                                            onClick={() => handleMaterialForNewStr('Stone')} />
                                                                        <label className="form-check-label" htmlFor="laundry_room">Stone</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="laundry_room"
                                                                            onClick={() => handleMaterialForNewStr('Textured concrete')} />
                                                                        <label className="form-check-label" htmlFor="laundry_room">Textured concrete</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="checkbox" name="checkGroupForMaterial" id="laundry_room"
                                                                            onClick={() => handleMaterialForNewStr('Not Sure')} />
                                                                        <label className="form-check-label" htmlFor="laundry_room">Not Sure</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="StrpatioArea" className="mt-3">
                                                                <div className="form-label fs-6 mb-2">How big of a patio, porch, or terrace do you need to install?</div>
                                                                <div className="d-flex flex-wrap g-2">
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="L200PAE" required
                                                                            onClick={() => handlePatioAreaForNewStr('Less than 200 sq ft')} />
                                                                        <label className="form-check-label" htmlFor="L200PAE">Less than 200 sq ft</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="2_300PAE"
                                                                            onClick={() => handlePatioAreaForNewStr('200-300 sq ft')} />
                                                                        <label className="form-check-label" htmlFor="2_300PAE">200-300 sq ft</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="3_400PAE"
                                                                            onClick={() => handlePatioAreaForNewStr('300-400 sq ft')} />
                                                                        <label className="form-check-label" htmlFor="3_400PAE">300-400 sq ft</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="4_500PAE"
                                                                            onClick={() => handlePatioAreaForNewStr('400-500 sq ft')} />
                                                                        <label className="form-check-label" htmlFor="4_500PAE">400-500 sq ft</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="5+00PAE"
                                                                            onClick={() => handlePatioAreaForNewStr('500+ sq ft')} />
                                                                        <label className="form-check-label" htmlFor="5+00PAE">500+ sq ft</label>
                                                                    </div>
                                                                    <div className="form-check me-3">
                                                                        <input className="form-check-input" type="radio" name="radioGroupForPatioAddStr" id="OTHPAE"
                                                                            onClick={() => handlePatioAreaForNewStr('other')} />
                                                                        <label className="form-check-label" htmlFor="OTHPAE">Other</label>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    otherPatioAreaForNewStr ?
                                                                        <input type="text" className="form-control mt-2" id="OTHPatioAE" placeholder='Other area...' required
                                                                            onChange={(e) => setPatioAreaForNewStr(e.target.value)} />
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                            : ''
                                    }
                                </div>
                                : ''
                        }
                        {/* Pool consrtuction section */}
                        {
                            contractingService === 'Pool Construction' ?
                                <div className='forPoolConstruction'>
                                    {/* What type of pool or hot tub do you need to install? */}
                                    <div id="WYNHD" className="mt-3">
                                        <div className="form-label fs-6 mb-2">What type of pool or hot tub do you need to install?</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForTypeOfPool" id="above_ground_tub" required
                                                    onClick={() => handleTypeOfPoolInstall('Above-ground hot tub')} />
                                                <label className="form-check-label" htmlFor="above_ground_tub">Above-ground hot tub</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForTypeOfPool" id="above_ground_pool" required
                                                    onClick={() => handleTypeOfPoolInstall('Above-ground pool')} />
                                                <label className="form-check-label" htmlFor="above_ground_pool">Above-ground pool</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForTypeOfPool" id="above_ground_combo" required
                                                    onClick={() => handleTypeOfPoolInstall('Above-ground pool & spa combo')} />
                                                <label className="form-check-label" htmlFor="above_ground_combo">Above-ground pool & spa combo</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForTypeOfPool" id="in_ground_tub" required
                                                    onClick={() => handleTypeOfPoolInstall('In-ground hot tub')} />
                                                <label className="form-check-label" htmlFor="in_ground_tub">In-ground hot tub</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForTypeOfPool" id="in_ground_pool" required
                                                    onClick={() => handleTypeOfPoolInstall('In- ground pool')} />
                                                <label className="form-check-label" htmlFor="in_ground_pool">In- ground pool</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForTypeOfPool" id="in_ground_combo" required
                                                    onClick={() => handleTypeOfPoolInstall('In-ground pool & spa combo')} />
                                                <label className="form-check-label" htmlFor="in_ground_combo">In-ground pool & spa combo</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForTypeOfPool" id="notSurePoolType" required
                                                    onClick={() => handleTypeOfPoolInstall('Not sure')} />
                                                <label className="form-check-label" htmlFor="notSurePoolType">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForTypeOfPool" id="OTHPoolType"
                                                    onClick={() => handleTypeOfPoolInstall('other')} />
                                                <label className="form-check-label" htmlFor="OTHPoolType">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherTypeOfPoolInstall ?
                                                <input type="text" className="form-control mt-2" id="OTHPool" placeholder='Other type of pool install...' required
                                                    onChange={(e) => setTypeOfPoolInstall(e.target.value)} />
                                                : ''
                                        }
                                    </div>
                                    {/* Will the pool or hot tub be indoors or outdoors? */}
                                    <div id="poolLocation" className="mt-3">
                                        <div className="form-label fs-6 mb-2">Will the pool or hot tub be indoors or outdoors?</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPoolLoction" id="indoorPool" required
                                                    onClick={() => setPoolTubLocation('Indoors')} />
                                                <label className="form-check-label" htmlFor="indoorPool">Indoors</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPoolLoction" id="outdoor_pool" required
                                                    onClick={() => setPoolTubLocation('Outdoors')} />
                                                <label className="form-check-label" htmlFor="outdoor_pool">Outdoors</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* What surface material do you need for your pool or hot tub? */}
                                    <div id="PoolMaterial" className="mt-3">
                                        <div className="form-label fs-6 mb-2">What surface material do you need for your pool or hot tub?</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPoolMaterial" id="concrete" required
                                                    onClick={() => handlePoolSurfaceMaterial('Concrete')} />
                                                <label className="form-check-label" htmlFor="concrete">Concrete</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPoolMaterial" id="fiberglass" required
                                                    onClick={() => handlePoolSurfaceMaterial('Fiberglass')} />
                                                <label className="form-check-label" htmlFor="fiberglass">Fiberglass</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPoolMaterial" id="tile" required
                                                    onClick={() => handlePoolSurfaceMaterial('Tile')} />
                                                <label className="form-check-label" htmlFor="tile">Tile</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPoolMaterial" id="vinyl" required
                                                    onClick={() => handlePoolSurfaceMaterial('Vinyl')} />
                                                <label className="form-check-label" htmlFor="vinyl">Vinyl</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPoolMaterial" id="notSurePoolMaterial" required
                                                    onClick={() => handlePoolSurfaceMaterial('Not Sure')} />
                                                <label className="form-check-label" htmlFor="notSurePoolMaterial">Not Sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPoolMaterial" id="OTHPoolMaterial"
                                                    onClick={() => handlePoolSurfaceMaterial('other')} />
                                                <label className="form-check-label" htmlFor="OTHPoolMaterial">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherPoolSurfaceMaterial ?
                                                <input type="text" className="form-control mt-2" id="OTHPoolSurfaceMaterial" placeholder='Other pool surface material...' required
                                                    onChange={(e) => setPoolSurfaceMaterial(e.target.value)} />
                                                : ''
                                        }
                                    </div>
                                    {/* Select all the items you need for your pool or hot tub. */}
                                    <div id="ItemForPool" className="mt-3">
                                        <div className="form-label fs-6 mb-2">Select all the items you need for your pool or hot tub.</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="cleaning_system" required
                                                    onClick={() => handleItemForPool('Cleaning system')} />
                                                <label className="form-check-label" htmlFor="cleaning_system">Cleaning system</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="Coping"
                                                    onClick={() => handleItemForPool('Coping')} />
                                                <label className="form-check-label" htmlFor="Coping">Coping</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="cover" required
                                                    onClick={() => handleItemForPool('Cover')} />
                                                <label className="form-check-label" htmlFor="cover">Cover</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="diving_board" required
                                                    onClick={() => handleItemForPool('Diving board')} />
                                                <label className="form-check-label" htmlFor="diving_board">Diving board</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="heating_system"
                                                    onClick={() => handleItemForPool('Heating system')} />
                                                <label className="form-check-label" htmlFor="heating_system">Heating system</label>
                                            </div>

                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="Ladder"
                                                    onClick={() => handleItemForPool('Ladder')} />
                                                <label className="form-check-label" htmlFor="Ladder">Ladder</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="Lighting"
                                                    onClick={() => handleItemForPool('Lighting')} />
                                                <label className="form-check-label" htmlFor="Lighting">Lighting</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="Plaster"
                                                    onClick={() => handleItemForPool('Plaster')} />
                                                <label className="form-check-label" htmlFor="Plaster">Plaster</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="pool_deck"
                                                    onClick={() => handleItemForPool('Pool deck')} />
                                                <label className="form-check-label" htmlFor="pool_deck">Pool deck</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="salt_water_system"
                                                    onClick={() => handleItemForPool('Salt water system')} />
                                                <label className="form-check-label" htmlFor="salt_water_system">Salt water system</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="Slide"
                                                    onClick={() => handleItemForPool('Slide')} />
                                                <label className="form-check-label" htmlFor="Slide">Slide</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="Steps"
                                                    onClick={() => handleItemForPool('Steps')} />
                                                <label className="form-check-label" htmlFor="Steps">Steps</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="water_features"
                                                    onClick={() => handleItemForPool('Water features (e.g. fountain, waterfall, spouts)')} />
                                                <label className="form-check-label" htmlFor="water_features">Water features (e.g. fountain, waterfall, spouts)</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForItemPool" id="notSureItem"
                                                    onClick={() => handleItemForPool('Not sure')} />
                                                <label className="form-check-label" htmlFor="notSureItem">Not sure</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""
                        }

                        {/* Remodeling section */}
                        {
                            contractingService === 'Remodeling' ?
                                <div className='Remodeling'>
                                    {/* If What needs to be remodeled? */}
                                    <div id="WNBR" className="mt-3">
                                        <div className="form-label fs-6 mb-2">What needs to be remodeled?</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRemodal" id="basement" required
                                                    onClick={() => handleRemodel('Basement')} />
                                                <label className="form-check-label" htmlFor="basement">Basement</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRemodal" id="kitchen" required
                                                    onClick={() => handleRemodel('Kitchen')} />
                                                <label className="form-check-label" htmlFor="kitchen">Kitchen</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRemodal" id="garage" required
                                                    onClick={() => handleRemodel('Garage')} />
                                                <label className="form-check-label" htmlFor="garage">Garage</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRemodal" id="bathroom" required
                                                    onClick={() => handleRemodel('Bathroom')} />
                                                <label className="form-check-label" htmlFor="bathroom">Bathroom</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRemodal" id="bedroom"
                                                    onClick={() => handleRemodel('Bedroom')} />
                                                <label className="form-check-label" htmlFor="bedroom">Bedroom</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRemodal" id="swimming_pool" required
                                                    onClick={() => handleRemodel('Swimming pool')} />
                                                <label className="form-check-label" htmlFor="swimming_pool">Swimming pool</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRemodal" id="laundry_room"
                                                    onClick={() => handleRemodel('Laundry room')} />
                                                <label className="form-check-label" htmlFor="laundry_room">Laundry room</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRemodal" id="living_room" required
                                                    onClick={() => handleRemodel('Living room')} />
                                                <label className="form-check-label" htmlFor="living_room">Living room</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRemodal" id="single_room"
                                                    onClick={() => handleRemodel('Single room')} />
                                                <label className="form-check-label" htmlFor="single_room">Single room</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRemodal" id="Other"
                                                    onClick={() => handleRemodel('other')} />
                                                <label className="form-check-label" htmlFor="Other">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherRemodal ?
                                                <input type="text" className="form-control mt-2" id="chooseDrink" placeholder='Other need to remodal...' required />
                                                : ''
                                        }

                                    </div>
                                    {
                                        remodal === 'Basement' ?
                                            <div id="BasementModal" className="mt-3">
                                                <div className="form-label fs-6 mb-2">What features do you want in your basement?</div>
                                                <div className="d-flex flex-wrap g-2">
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="bathroom(s)" required
                                                            onClick={() => handleBasementModal('Bathroom(s)')} />
                                                        <label className="form-check-label" htmlFor="bathroom(s)">Bathroom(s)</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="bedroom(s)"
                                                            onClick={() => handleBasementModal('Bedroom(s)')} />
                                                        <label className="form-check-label" htmlFor="bedroom(s)">Bedroom(s)</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="laundry" required
                                                            onClick={() => handleBasementModal('Laundry')} />
                                                        <label className="form-check-label" htmlFor="laundry">Laundry</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="storage" required
                                                            onClick={() => handleBasementModal('Storage')} />
                                                        <label className="form-check-label" htmlFor="storage">Storage</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="stairway"
                                                            onClick={() => handleBasementModal('Stairway')} />
                                                        <label className="form-check-label" htmlFor="stairway">Stairway</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="office"
                                                            onClick={() => handleBasementModal('Office')} />
                                                        <label className="form-check-label" htmlFor="office">Office</label>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                    {
                                        remodal === 'Kitchen' ?
                                            <div id="KitchenModal" className="mt-3">
                                                <div className="form-label fs-6 mb-2">Which of the following will you need moved or installed in your kitchen?</div>
                                                <div className="d-flex flex-wrap g-2">
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="countertops" required
                                                            onClick={() => handleKitchenModal('Countertops')} />
                                                        <label className="form-check-label" htmlFor="countertops">Countertops</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="cabinets"
                                                            onClick={() => handleKitchenModal('Cabinets')} />
                                                        <label className="form-check-label" htmlFor="cabinets">Cabinets</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="sinks" required
                                                            onClick={() => handleKitchenModal('Sinks')} />
                                                        <label className="form-check-label" htmlFor="sinks">Sinks</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="flooring" required
                                                            onClick={() => handleKitchenModal('Flooring')} />
                                                        <label className="form-check-label" htmlFor="flooring">Flooring</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="appliances"
                                                            onClick={() => handleKitchenModal('Appliances')} />
                                                        <label className="form-check-label" htmlFor="appliances">Appliances</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="electrical"
                                                            onClick={() => handleKitchenModal('Electrical')} />
                                                        <label className="form-check-label" htmlFor="electrical">Electrical</label>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                    {
                                        remodal === 'Garage' ?
                                            <div>
                                                <div id="GarageModal2" className="mt-3">
                                                    <div className="form-label fs-6 mb-2">What best describes this garage remodel?</div>
                                                    <div className="d-flex flex-wrap g-2">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForPoolMaterial" id="completely_remodel_garage" required
                                                                onClick={() => setGarageDescription('Completely remodel garage')} />
                                                            <label className="form-check-label" htmlFor="completely_remodel_garage">Completely remodel garage</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForPoolMaterial" id="organize_garage_space" required
                                                                onClick={() => setGarageDescription('Organize garage space')} />
                                                            <label className="form-check-label" htmlFor="organize_garage_space">Organize garage space</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForPoolMaterial" id="enlarge_existing_garage" required
                                                                onClick={() => setGarageDescription('Enlarge existing garage')} />
                                                            <label className="form-check-label" htmlFor="enlarge_existing_garage">Enlarge existing garage</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="radioGroupForPoolMaterial" id="build_a_new_garage" required
                                                                onClick={() => setGarageDescription('Build a new garage')} />
                                                            <label className="form-check-label" htmlFor="build_a_new_garage">Build a new garage</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="GarageModal" className="mt-3">
                                                    <div className="form-label fs-6 mb-2">Which of the following will you need moved or installed in your garage remodel?</div>
                                                    <div className="d-flex flex-wrap g-2">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="storage_cabinets_shelving" required
                                                                onClick={() => handleGarageInstallRemodal('Storage cabinets/shelving')} />
                                                            <label className="form-check-label" htmlFor="storage_cabinets_shelving">Storage cabinets/shelving</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="garage_doors"
                                                                onClick={() => handleGarageInstallRemodal('Garage doors')} />
                                                            <label className="form-check-label" htmlFor="garage_doors">Garage doors</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="insulation" required
                                                                onClick={() => handleGarageInstallRemodal('Insulation')} />
                                                            <label className="form-check-label" htmlFor="insulation">Insulation</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="flooring" required
                                                                onClick={() => handleGarageInstallRemodal('Flooring')} />
                                                            <label className="form-check-label" htmlFor="flooring">Flooring</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="electrical"
                                                                onClick={() => handleGarageInstallRemodal('Electrical')} />
                                                            <label className="form-check-label" htmlFor="electrical">Electrical</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="interior_walls_or_drywall"
                                                                onClick={() => handleGarageInstallRemodal('Interior walls or drywall')} />
                                                            <label className="form-check-label" htmlFor="interior_walls_or_drywall">Interior walls or drywall</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                    {
                                        remodal === 'Bathroom' ?
                                            <div id="BathroomModal" className="mt-3">
                                                <div className="form-label fs-6 mb-2">Which of the following will you need moved or installed in your bathroom remodel?</div>
                                                <div className="d-flex flex-wrap g-2">
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="shower_bath" required
                                                            onClick={() => handleBathroomInstallRemodal('Shower/bath')} />
                                                        <label className="form-check-label" htmlFor="shower_bath">Shower/bath</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="toilet_bathroom"
                                                            onClick={() => handleBathroomInstallRemodal('Toilet')} />
                                                        <label className="form-check-label" htmlFor="toilet_bathroom">Toilet</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="cabinets_vanity" required
                                                            onClick={() => handleBathroomInstallRemodal('Cabinets/vanity')} />
                                                        <label className="form-check-label" htmlFor="cabinets_vanity">Cabinets/vanity</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="flooring_bathroom" required
                                                            onClick={() => handleBathroomInstallRemodal('Flooring')} />
                                                        <label className="form-check-label" htmlFor="flooring_bathroom">Flooring</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="sink_bathroom"
                                                            onClick={() => handleBathroomInstallRemodal('Sinks')} />
                                                        <label className="form-check-label" htmlFor="sink_bathroom">Sinks</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="checkbox" name="checkGroupForRoomNewStory" id="countertops_bathroom"
                                                            onClick={() => handleBathroomInstallRemodal('Countertops')} />
                                                        <label className="form-check-label" htmlFor="countertops_bathroom">Countertops</label>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </div>
                                : ""
                        }
                        {/* Structural Repair section*/}
                        {
                            contractingService === 'Structural repair' ?
                                <div className=''>
                                    {/* If What structural elements need repairs? select all that apply. */}
                                    <div id="WSENR" className="mt-3">
                                        <div className="form-label fs-6 mb-2">What structural elements need repairs? select all that apply.</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForRepair" id="Beams" required
                                                    onClick={() => handleStructuralRepair('Beams')} />
                                                <label className="form-check-label" htmlFor="Beams">Beams</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForRepair" id="foundations"
                                                    onClick={() => handleStructuralRepair('Foundations')} />
                                                <label className="form-check-label" htmlFor="foundations">Foundations</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForRepair" id="lintels" required
                                                    onClick={() => handleStructuralRepair('Lintels')} />
                                                <label className="form-check-label" htmlFor="lintels">Lintels</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForRepair" id="frames"
                                                    onClick={() => handleStructuralRepair('Frames')} />
                                                <label className="form-check-label" htmlFor="frames">Frames</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForRepair" id="Joists" required
                                                    onClick={() => handleStructuralRepair('Joists')} />
                                                <label className="form-check-label" htmlFor="Joists">Joists</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForRepair" id="post"
                                                    onClick={() => handleStructuralRepair('Post')} />
                                                <label className="form-check-label" htmlFor="post">Post</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForRepair" id="roofs_frame" required
                                                    onClick={() => handleStructuralRepair('Roofs frame')} />
                                                <label className="form-check-label" htmlFor="roofs_frame">Roofs frame</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForRepair" id="walls"
                                                    onClick={() => handleStructuralRepair('Walls')} />
                                                <label className="form-check-label" htmlFor="walls">Walls</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="checkbox" name="checkGroupForRepair" id="NS"
                                                    onClick={() => handleStructuralRepair('Not sure')} />
                                                <label className="form-check-label" htmlFor="NS">Not sure</label>
                                            </div>
                                            {/* <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="Other" />
                                        <label className="form-check-label" htmlFor="Other">Other</label>
                                    </div> */}
                                        </div>
                                        {/* <input type="text" className="form-control mt-2" id="chooseDrink" placeholder='Other...' required /> */}
                                    </div>
                                </div>
                                : ''
                        }
                    </div>

                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">What kind of location is this?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForLocation" id="location_Home" required
                                    onClick={() => setLocationType('Home')} />
                                <label className="form-check-label" htmlFor="location_Home">Home</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForLocation" id="location_business"
                                    onClick={() => setLocationType('Business')} />
                                <label className="form-check-label" htmlFor="location_business">Business</label>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">When do you require this service?</label>
                        <div className='date-picker-wrapper'>
                            <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                                onChange={(value) => setAppointDate(value)} minDate={new Date()}
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlhtmlFor="otherInfo" className="form-label fs-6 mb-2">Are there any other details you d like to share?</label>
                        <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                            onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                    </div>
                    <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com </div>
                    <div className="btn-wrapper">
                        <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Next</button>
                    </div>
                    {props.loading ?
                        <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                            style={{ zIndex: 1030 }}>
                            <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                        </div>
                        : ''
                    }
                </div>
            ) :
                (<UserInfoForQuoteService state={serviceData} />)
            }
        </>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(Remodeling))