import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { ToastContainer, toast, } from 'react-toastify';
import { Rating } from 'react-simple-star-rating'

const CouponrankingRating = (props) => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [rating, setRating] = useState(0)
    const [ratingComment, setRatingComment] = useState('')

    const handleSubmitReview = (e) => {
        e.preventDefault()
        if (rating > 0 && ratingComment !== '') {
            if (ratingComment.length > 30) {
                let creatorId = userInfo._id
                let data = {
                    rating: rating,
                    ratingFor: 'couponranking',
                    comment: ratingComment,
                    creator_id: creatorId,
                }
                props.makeRating(data, toast)
            } else {
                toast.warning("Please comment more than 30 words.", { autoClose: 1000, hideProgressBar: true })
            }

        } else {
            toast.error("Please rate and comment to submit.", { autoClose: 1000, hideProgressBar: true, })
        }

    }

    return (
        <>
            <Helmet>
                <title>Rate & Review Couponranking</title>
            </Helmet>
            <div className='requestQuote-page section-bg-image pt-4 pb-5'>
                <ToastContainer />
                <div className="container">
                    <div className="form-wrapper mx-auto" style={{ maxWidth: '900px' }}>
                        <div className='card border-1 shadow p-3 p-lg-5'>
                            <div className='fs-18 fw-medium mb-2'>Rate Couponranking</div>
                            <div className="review-wrapper mb-2">
                                <div className="star-wrapper d-flex gap-2 fs-5 mb-1">
                                    <Rating emptySymbol="bi bi-star" fullSymbol="bi bi-star-fill"
                                        initialValue={rating}
                                        value={rating}
                                        onClick={(value) => setRating(value)}
                                    />
                                </div>
                                <p className='m-0 text-muted'>Write your review for Couponranking <small className='fw-medium text-capitalize'></small></p>
                            </div>
                            <div className="review-message">
                                <textarea className="form-control small" id="dealMessage" rows="5" placeholder='Enter message here...' required
                                    onChange={(event) => setRatingComment(event.target.value)}
                                ></textarea>
                                <div className='invalid-feedback'></div>
                            </div>
                            <button className='w-100 btn btn-primary py-2 mt-3' onClick={(e) => handleSubmitReview(e)}>Submit Review</button>
                        </div>
                    </div>
                </div >
            </div>
            {/* loading section */}
            {props.loading ?
                <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                    style={{ zIndex: 1030 }}>
                    <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                </div>
                : ''}
        </>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        makeRating: (data, toast) => dispatch(actions.makeRating(data, toast)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(CouponrankingRating))
