import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';

const VerifyOtp = (props) => {
    const navigate = useNavigate()
    // const [email, setEmail] = useState("");
    const [otp, setOtp] = useState(new Array(4).fill(''));
    let registerEmail = localStorage.getItem('registerUserEmail')


    const handleChange = (event, index) => {
        const newOtp = [...otp];
        const { maxLength, value, name } = event.target;
        newOtp[index] = value;
        setOtp(newOtp);
        const [fieldName, fieldIndex] = name.split("-");
        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < 4) {
                const nextSibling = document.querySelector(`input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`);
                if (nextSibling !== null) { nextSibling.focus(); }
            }
        }
    };

    // const generateOtp = () => {
    //     if (!email) {
    //         alert("Please enter your email");
    //         return;
    //     }
    //     document.getElementById("emailVerification").style.display = "none";
    //     document.getElementById("otpVerification").style.display = "block";
    // }

    // const editNumber = () => {
    //     document.getElementById("otpVerification").style.display = "none";
    //     document.getElementById("emailVerification").style.display = "block";
    // };

    // const resetPassword = () => {
    //     document.getElementById("otpVerification").style.display = "none";
    //     document.getElementById("resetPassword").style.display = "block";
    // };

    const handleSubmit = async(e) => {
        e.preventDefault();
        let otpStr = otp.join('')
        let data = {
            email: registerEmail,
            otp: otpStr
        }
       await props.verifyUser(data,navigate)
    }

    return (
        <div className='auth-page d-flex align-items-center py-5'>
            {/* title head */}
            <Helmet>
                <title>CouponRanking | Verify User</title>
            </Helmet>
            <div className='container'>
                <div className='border border-1 rounded-3 overflow-hidden shadow mx-auto' style={{ maxWidth: '1000px' }}>
                    <div className='row g-0'>
                        <div className='col-md-5'>
                            <div className='left-section section-bg-overlay d-flex flex-column justify-content-center bg-primary bg-opacity-50 text-white h-100 px-4 py-5'>
                                <div className='cntnt-wrapper position-relative z-1'>
                                    <Link to={'/'} className='d-block logo-title text-uppercase small fw-bold mb-3'>
                                        <img className='img-fluid rounded-circle' src={require('../assets/logo.png')} alt='Logo' style={{ width: '100px' }} />
                                    </Link>
                                    <div className='heading-title fs-2 fw-bold lh-sm mb-3'>Start Your<br /> Journey With Us.</div>
                                    <div className='heading-subtitle mb-3'>Make Your Money Go Further with the Latest Deals and Coupons</div>
                                    <div className='img-wrapper text-center d-none d-md-block'>
                                        <img className='img-fluid rounded-circle' src={require('../assets/hero-banner1.webp')} alt='Logo' style={{ width: '300px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className="right-section d-flex align-items-center bg-white h-100 px-3 px-sm-5 py-5">
                                <form className='w-100' >
                                    <div id="otpVerification" className='otp-verification text-center'>
                                        {/* Start: Alert message */}
                                        {props.error ?
                                            <div className="d-flex align-items-center border border-danger text-danger rounded-1 shadow-sm px-3 py-2 mb-3" role="alert">
                                                <i className="bi bi-exclamation-triangle-fill fs-18"></i>
                                                <div className='ms-2 lh-sm'>
                                                    {props.error}
                                                </div>
                                            </div> : ''
                                        }
                                        {props.success ?
                                            <div className="d-flex align-items-center border border-success text-success rounded-1 shadow-sm px-3 py-2 mb-3" role="alert">
                                                <i className="bi bi-exclamation-triangle-fill fs-18"></i>
                                                <div className='ms-2 lh-sm'>
                                                    {props.success}
                                                </div>
                                            </div> : ''
                                        }
                                        {/* End: Alert message */}
                                        <div className='mb-4'>
                                            <img src={require('../assets/ForgotOtp.png')} className='img-fluid mb-3' alt='Mobile Number Verify' />
                                            <div className='fs-5 fw-bold mb-1'>OTP Verification</div>
                                            <div className='small text-muted'>Enter OTP sent to your email <span className='fw-semibold'>{registerEmail ? registerEmail : ''}</span><i className="bi bi-pencil-fill ms-2 fs-6 text-danger"></i></div>
                                        </div>

                                        <div className='enter-otp input-cover d-flex justify-content-center gap-2 mb-4'>
                                            {otp.map((digit, index) => (
                                                <input autoComplete="off"
                                                    key={index}
                                                    type="text"
                                                    maxLength={1}
                                                    value={digit}
                                                    name={`ssn-${index + 1}`}
                                                    className="form-control shadow-sm py-2"
                                                    onChange={(event) => handleChange(event, index)} required
                                                />
                                            ))}
                                        </div>
                                        <div className='mb-4 text-muted'>Don't receive OTP?<span className='text-danger text-uppercase fw-medium ms-2' style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                             onClick={()=>props.resendOtp({email:registerEmail})}
                                        >Resend</span></div>
                                        <button className='btn btn-danger w-100 d-flex align-items-center justify-content-center' onClick={(e) => handleSubmit(e)}>Verify & Continue
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        verifyUser: (data,navigate) => dispatch(actions.verifyOtp(data,navigate)),
        resendOtp:(data)=> dispatch(actions.resendOtp(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(VerifyOtp))