import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet";

export const DirectionMap = () => {
    let lat = localStorage.getItem("latitude")
    let long = localStorage.getItem("longitude")
    const { state } = useLocation()
    useEffect(()=>{
        // for loading page from top after re-render from any section
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    })
    const directionsUrl = `https://www.google.com/maps/embed/v1/directions?key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q&origin=${lat},${long}&destination=${state.destLat},${state.desLong}`;
    return (
        <div className='container my-4 text-center'>
             {/* title head */}
             <Helmet>
                <title>Direction-{state.businessName}-{state.address}</title>
            </Helmet>
            <h1 className="section-label fs-3 mb-3 pb-2">Direction to Business Location</h1>
            <iframe
                style={{height:"80vh",width:"100%",
                 border: '1px solid #ccc', borderRadius:'5px',overflow:'hidden'}}
                src={directionsUrl}
                allowFullScreen
            ></iframe>
        </div>
    )
}
