import React from 'react'
import { connect } from 'react-redux'
// import { useLocation } from 'react-router-dom';
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Elements,
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
let stripePromise = loadStripe("pk_live_HCDXsOAEmsRJMJkM1ZMnTi6H");

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (elements == null) {
            return;
        }
        let card = await elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
        let { token, error } = await stripe.createToken(card);
        if (error) {
            toast.error(error.message, { autoClose: 1000 })
        } else {
            let data = {
                tokenId: token.id
            }
            await props.props.updateCardDetails(data)
        }
    };

    return (
        <form onSubmit={handleSubmit} className='stripe-checkout-form'>
            {props.props.error ?
                <div className="d-flex align-items-center border border-danger text-danger rounded-1 shadow-sm px-3 py-2 mb-3" role="alert">
                    <i className="bi bi-exclamation-triangle-fill fs-18"></i>
                    <div className='ms-2 lh-sm'>
                        {props.props.error}
                    </div>
                </div> : ''
            }
            <div className='mb-3'>
                <label className='form-label'>Card Number</label>
                <CardNumberElement options={{ showIcon: true }} />
            </div>

            <div className='row gx-3'>
                <div className='col-6 mb-3'>
                    <label className='form-label'>Expiry Date</label>
                    <CardExpiryElement />
                </div>

                <div className='col-6 mb-3'>
                    <label className='form-label'>CVC</label>
                    <CardCvcElement options={{ CardCvcIcons: true }} className='cvc-icon' />
                </div>
            </div>

            <div className='btn-wrapper text-center pt-2'>
                <button className='w-100 btn btn-primary fs-18' data-bs-dismiss="modal" aria-label="Close" type="submit" disabled={!stripe || !elements}>
                    Submit
                </button>
            </div>
        </form>
    );
};


const EditCard = (props) => {
    
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm props={{ updateCardDetails: props.updateCardDetails, error: props.error }} />
        </Elements>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCardDetails: (data) => dispatch(actions.updateCardDetails(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(EditCard))
