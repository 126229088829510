import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  searchSuggestion:[],
  searchedBusinessList:[],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_START_BUSSINESS_ACTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.SEARCH_BUSSINESS_ACTION_FAIL:
      return {
        ...state,
        loading: null,
        error: action.message,
        success: null,
      }
      case actionTypes.SEARCH_SUGGESTION:
      return {
        ...state,
        loading: null,
        searchSuggestion: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.SEARCH_BUSINESS_LIST:
      return {
        ...state,
        loading: null,
        searchedBusinessList: action.listing,
        error: null,
        success: action.message,
      }
    default:
      return state;
  }
};

export default reducer;
