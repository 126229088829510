import React from 'react';
import { useState } from 'react';
// import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect, useMemo } from 'react';
import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import searchIcon from '../assets/search.svg';
import ReactLoading from 'react-loading';
const columns = [
    {
        name: 'Sr.No',
        selector: row => row.SrNo,
        width: '75px'
    },
    {
        name: 'Customer Name',
        selector: row => row.customerName,
    },
    {
        name: 'Customer Email',
        selector: row => row.customerEmail,
    },
    {
        name: 'Promo Code',
        selector: row => row.promoCode,
    },
    {
        name: 'Reward Stars',
        selector: row => row.rewardStars,
    },
    {
        name: ' Expiry Date',
        selector: row => row.expiryDate,
    },

    {
        name: 'Action',
        selector: row => row.action,
        width: '250px'
    },
];

const CouponRoyaltiRewards = (props) => {
    const businessId = JSON.parse(localStorage.getItem('userInfo'))._id;
    const { getRewardAndCouponUserList, rewardCustomerList, updateEarnedStar,
        success, loading, redeemRewardAndCoupon } = props;
    const [pending, setPending] = useState(true)
    const [data, setData] = useState([])
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    useEffect(() => {
        getRewardAndCouponUserList({ businessId: businessId })
    }, [businessId, getRewardAndCouponUserList])
    useEffect(() => {
        const updateCustomelist = () => {
            updateData()
        }
        updateCustomelist()
    }, [rewardCustomerList])
    useEffect(() => {
        toast.success(success, {
            autoClose: 500,
            hideProgressBar: true,
        })
    }, [success])
    let updateData = async () => {
        let SrNo = 1
        let dataList = []
        rewardCustomerList.map((item, index) => {
            dataList.push(
                {
                    SrNo: SrNo++,
                    customerName: item.userName,
                    customerEmail: item.userEmail,
                    promoCode: item.coupon,
                    rewardStars: item.rewardStar,
                    expiryDate: new Date(item.expiryDate).toLocaleDateString('en-us'),
                    action: <div key={index} className='action-btns d-flex gap-2'>
                        {item.type === 'reward' && item.rewardStar < 5 ? <button className="bg-success" onClick={() => updateReward(item)}><i class="bi bi-check2"></i> Earned</button> : ''}
                        {item.type === 'coupon' ? <button className="bg-primary" onClick={() => handleRedeemRewardAndCoupon(item)}><i class="bi bi-x"></i> Redeemed</button> :
                            item.rewardStar === 5 ? <button className="bg-primary" onClick={() => handleRedeemRewardAndCoupon(item)}><i class="bi bi-x"></i> Redeemed</button> : ''}
                        {/* <button className="bg-danger"><i class="bi bi-trash3"></i></button> */}
                    </div>
                }
            )
        })
        setPending(false)
        setData(dataList)
        // return await dataList
    }
    const filteredItems = data.filter(
        item => item.customerName.toLowerCase().includes(filterText.toLowerCase()) ||
            item.customerEmail.toLowerCase().includes(filterText.toLowerCase()
            )
    );
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    // update earned star
    const updateReward = (item) => {
        let rewardId = item._id;
        updateEarnedStar({ rewardId: rewardId })
    }
    const handleRedeemRewardAndCoupon = (item) => {
        let rewardId = item._id;
        redeemRewardAndCoupon({ rewardId: rewardId })
    }
    // console.log(props.success, 'hello success')

    return (
        <div className='royalty-rewards-page py-4'>
            <ToastContainer />
            <div className='container'>
                <div className='table-responsive npm-custom-table'>
                    <DataTable
                        pagination
                        responsive
                        striped
                        columns={columns}
                        data={filteredItems}
                        subHeader={true}
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                        progressPending={pending}
                    />
                </div>
            </div>
            {loading?
            <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                style={{ zIndex: 1030 }}>
                <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
            </div>
            :''}
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.rewardAndCoupon.loading,
        error: state.rewardAndCoupon.error,
        success: state.rewardAndCoupon.success,
        rewardCustomerList: state.rewardAndCoupon.rewardAndCouponUserList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRewardAndCouponUserList: (data) => dispatch(actions.getRewardAndCouponUserList(data)),
        updateEarnedStar: (data) => dispatch(actions.updateEarnedStar(data)),
        redeemRewardAndCoupon: (data) => dispatch(actions.redeemRewardAndCoupon(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(CouponRoyaltiRewards))



const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between gap-2 w-100">
        <div className='section-label fs-4 mb-3 mb-md-0'>Coupon & Royalty Rewards (Programe)</div>
        <div className='text-end p-1'>
            <form className="search-form" role="search">
                <input id="search" className='form-control ms-auto px-3' type="search" placeholder="Search customer..." value={filterText} onChange={onFilter} />
                <img src={searchIcon} alt='Search Icon' className='position-absolute top-50 end-0 translate-middle' />
            </form>
        </div>
    </div>
);