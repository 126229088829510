import { useState } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = (props) => {
    const { ForgotPassword, verifyForgetPasswordOtp, resetPassword,resendForgotPasswordOtp } = props
    const [email, setEmail] = useState("");
    const [section, setSection] = useState({
        emailSection: true,
        verifyOtpSection: false,
        resetPassword: false
    });
    const [otp, setOtp] = useState(new Array(4).fill(''));
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    //handle otp change
    const handleChange = (event, index) => {
        const newOtp = [...otp];
        const { maxLength, value, name } = event.target;
        newOtp[index] = value;
        setOtp(newOtp);
        const [fieldName, fieldIndex] = name.split("-");
        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < 4) {
                const nextSibling = document.querySelector(`input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`);
                if (nextSibling !== null) { nextSibling.focus(); }
            }
        }
    };
    // submit email for generate otp
    const generateOtp = async (e) => {
        e.preventDefault()
        if (!email) {
            toast.warning("Please enter your email", { autoClose: 500, hideProgressBar: true });
            return;
        } else {
            let data = {
                email: email
            }
            await ForgotPassword(data, setSection, toast)
        }
    }

    // handle verify otp for process reset password
    const verifyOtp = async (e) => {
        e.preventDefault();
        let otpStr = otp.join('')
        let data = {
            email: email,
            otp: otpStr
        }
        await verifyForgetPasswordOtp(data, setSection, toast)
    }

    // handle reset password
    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (!password || !confirmPassword) {
            toast.warning('Please enter password and confirm password', {
                autoClose: 1000,
                hideProgressBar: true
            })
        } else {
            if (password !== confirmPassword) {
                toast.warning('Password doesnot match with confirm password', {
                    autoClose: 1000,
                    hideProgressBar: true
                })
            } else {
                let data = {
                    email: email,
                    password: password
                }
                await resetPassword(data, toast)
            }
        }
    }

    // handle resend otp
    const handleResendOtp = async(e) => {
        e.preventDefault()
        let data = { email: email }
        await resendForgotPasswordOtp(data,toast)
    }

    return (
        <div className='auth-page d-flex align-items-center py-5'>
            <ToastContainer />
            {/* title head */}
            <Helmet>
                <title>Forgotten Password | Can't login</title>
            </Helmet>
            <div className='container'>
                <div className='border border-1 rounded-3 overflow-hidden shadow mx-auto' style={{ maxWidth: '1000px' }}>
                    <div className='row g-0'>
                        <div className='col-md-5'>
                            <div className='left-section section-bg-overlay d-flex flex-column justify-content-center bg-primary bg-opacity-50 text-white h-100 px-4 py-5'>
                                <div className='cntnt-wrapper position-relative z-1'>
                                    <Link to={'/'} className='d-block logo-title text-uppercase small fw-bold mb-3'>
                                        <img className='img-fluid rounded-circle' src={require('../assets/logo.png')} alt='Logo' style={{ width: '100px' }} />
                                    </Link>
                                    <div className='heading-title fs-2 fw-bold lh-sm mb-3'>Start Your<br /> Journey With Us.</div>
                                    <div className='heading-subtitle mb-3'>Make Your Money Go Further with the Latest Deals and Coupons</div>
                                    <div className='img-wrapper text-center d-none d-md-block'>
                                        <img className='img-fluid rounded-circle' src={require('../assets/hero-banner.jpg')} alt='Logo' style={{ width: '300px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className="right-section d-flex align-items-center bg-white h-100 px-3 px-sm-5 py-5">
                                <form className='w-100'>
                                    {
                                        section.emailSection ?
                                            <div id="emailVerification" className='email-verification'>
                                                <div className='text-center mb-4 pb-2'>
                                                    <img src={require('../assets/mobile.png')} className='img-fluid mb-3' alt='Email Verification' />
                                                    <div className='fs-5 fw-bold mb-1'>Forgot password?</div>
                                                    <div className='mb-2'>Enter the email associated with your account to reset your password.</div>
                                                    <div className='small text-muted'>We will send you the <span className='fw-semibold'>4 digit</span> verification code</div>
                                                </div>

                                                <div className="mb-4">
                                                    <label htmlFor="email" className="form-label">Email address</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text" id="email"><i className="bi bi-envelope"></i></span>
                                                        <input type="email" className="form-control" id="email" placeholder='Enter your email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                                                    </div>
                                                </div>

                                                <button className='btn btn-danger w-100 d-flex align-items-center justify-content-center' onClick={(e) => generateOtp(e)}>Generate OTP</button>
                                            </div> : ''
                                    }

                                    {
                                        email !== '' && section.verifyOtpSection ?
                                            <div id="otpVerification" className='otp-verification text-center'>
                                                <div className='mb-4'>
                                                    <img src={require('../assets/ForgotOtp.png')} className='img-fluid mb-3' alt='Mobile Number Verify' />
                                                    <div className='fs-5 fw-bold mb-1'>OTP Verification</div>
                                                    {/* <div className='small text-muted'>Enter OTP sent to <span className='fw-semibold'>+{email}</span><i className="bi bi-pencil-fill ms-2 fs-6 text-danger" onClick={editNumber}></i></div> */}
                                                </div>

                                                <div className='enter-otp input-cover d-flex justify-content-center gap-2 mb-4'>
                                                    {otp.map((digit, index) => (
                                                        <input autoComplete="off"
                                                            key={index}
                                                            type="text"
                                                            maxLength={1}
                                                            value={digit}
                                                            name={`ssn-${index + 1}`}
                                                            className="form-control shadow-sm py-2"
                                                            onChange={(event) => handleChange(event, index)} required
                                                        />
                                                    ))}
                                                </div>
                                                <div className='mb-4 text-muted'>Don't receive OTP?<span className='text-danger text-uppercase fw-medium ms-2'
                                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                    onClick={(e) => handleResendOtp(e)}
                                                >Resend</span></div>
                                                <button className='btn btn-danger w-100 d-flex align-items-center justify-content-center' onClick={(e) => verifyOtp(e)}>Verify & Continue
                                                </button>
                                            </div>
                                            : ''
                                    }

                                    {
                                        email !== '' && section.resetPassword ?
                                            <div id="resetPassword" className='reset-password'>
                                                <div className='text-center mb-4 pb-2'>
                                                    <img src={require('../assets/mobile.png')} className='img-fluid mb-3' alt='Email Verification' />
                                                    <div className='fs-5 fw-semibold mb-1'>Create new password?</div>
                                                    <div className='text-muted'>Your new password must be different from previous used password</div>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                    <input type="password" className="form-control" id="password" placeholder='Enter password' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)} />
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="c-password" className="form-label">Confirm Password</label>
                                                    <input type="password" className="form-control" id="c-password" placeholder='Confirm password' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)} />
                                                </div>
                                                <button className='btn btn-danger w-100 d-flex align-items-center justify-content-center' onClick={(e) => handleResetPassword(e)}>Reset Password</button>
                                            </div> : ''
                                    }

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ForgotPassword: (data, setSection, toast) => dispatch(actions.ForgotPassword(data, setSection, toast)),
        verifyForgetPasswordOtp: (data, setSection, toast) => dispatch(actions.verifyForgetPasswordOtp(data, setSection, toast)),
        resetPassword: (data, toast) => dispatch(actions.resetPassword(data, toast)),
        resendForgotPasswordOtp: (data, toast) => dispatch(actions.resendForgotPasswordOtp(data, toast)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(ForgotPassword))